<template>
  <transition name="fade">
    <div v-if="show" class="modal-overlay" @click="$emit('close')">
      <div class="modal-content" @click.stop>
        <div v-if="icon === 'success'" class="icon success">
          <img src="assets/img/business-4/icon/LOGOGRAM.webp" alt="Success" class="success-image">
        </div>
        <div v-else-if="icon === 'error'" class="icon error"></div>
        <h2>{{ title }}</h2>
        <p>{{ message }}</p>
        <button @click="$emit('close')">OK</button>
        <a v-if="footer" href="#" class="footer-link">{{ footer }}</a>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String,
    message: String,
    icon: String,
    footer: String
  },
  emits: ['close']
}
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
  max-width: 90%;
  width: 300px;
}

.icon {
  width: 110px;
  height:110px;
  border-radius: 50%;
  margin: 0 auto 20px;
  position: relative;
  overflow: hidden;
}


.success-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.icon.error {
  background-color: #ff0000;
}

.icon.error::before,
.icon.error::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 30px;
  background-color: white;
}

.icon.error::before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.icon.error::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

h2 {
  margin-top: 0;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #3085d6;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.footer-link {
  display: block;
  margin-top: 10px;
  color: #3085d6;
  text-decoration: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>