<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img :src="require('@/assets/dist/img/AdminLTELogo.png')" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Sample Ctp</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">Sampel Ctp</li>
                            </ol>
                        </div>
                    </div>
                </div><!-- /.container-fluid -->
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header row">
                                    <h3 class="card-title">Data Artikel</h3>
                                    <div class="col-12 d-flex justify-content-end">
                                        <div>
                                        <label for="startDate">Start Date:</label>
                                        <input type="date" id="startDate" v-model="startDate">

                                        <label for="endDate">End Date:</label>
                                        <input type="date" id="endDate" v-model="endDate">
                                    </div>
                                    <button @click="downloadPdf" class="btn btn-success">Download PDF</button>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                  <div class="table-responsive">
                                    <table id="Filter" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">No</th>
                                                <th scope="col">Nama</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Country</th>
                                                <th scope="col">Phone</th>
                                                <th scope="col">Company</th>
                                                <th scope="col">Job Title</th>
                                                <th scope="col">Address</th>
                                                <th scope="col">kategori</th>
                                                <th scope="col">Tanggal</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(post, index) in posts" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ post.nama }}</td>
                                                <td>{{ post.email }}</td>
                                                <td>{{ post.negara }}</td>
                                                <td>{{ post.no_hp }}</td>
                                                <td>{{ post.company }}</td>
                                                <td>{{ post.jabatan }}</td>
                                                <td>{{ post.alamat }}</td>
                                                <td>{{ post.nama_kategori}}</td>
                                                <td>{{ post.tanggal }}</td>
                                                <td class="text-center">
                                                    <!-- <router-link
                                                        :to="{ name: 'EditProduk', params: { id_sampel: post.id_sampel } }"
                                                        class="btn btn-warning mr-2"
                                                        style="color: #ffffff;">EDIT</router-link> -->
                                                    <button @click.prevent="confirmDelete(post.id_sampel)"
                                                        class="btn btn-sm btn-danger">DELETE</button>
                                                            <br>
                                                            <br>
                                                        <button @click.prevent="downloadPdfById(post.id_sampel)"
                                                        class="btn btn-sm btn-primary">Download PDF</button>
                                                    </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                </div>
                                <!-- /.card-body -->
                            </div>
                            <!-- /.card -->
                        </div>
                        <!-- /.col -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.container-fluid -->
            </section>
            <!-- /.content -->
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>

    <!-- ./wrapper -->
</template>
  
<script>
import axios from 'axios';
import { onMounted, ref, nextTick } from 'vue'; // Tambahkan nextTick di sini
import jsPDF from 'jspdf';
import $ from 'jquery';
import 'jspdf-autotable';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";

export default {
  name: "TampilanCtp",
  components: {
    FooterDs,
    NavbarDs,
    SidebarDs,
  },
  setup() {
    const posts = ref([]);
    const startDate = ref(new Date()); // Initialize with the current date
    const endDate = ref(new Date()); // Initialize with the current date

    const getDataPosts = () => {
  let url = 'https://puracigarettesolutions.com:3000/api/filter/TampilanFilter';

  axios.get(url)
    .then(response => {
      // Apply the formatDate function to each post's tanggal property
      const formattedPosts = response.data.data.map(post => {
        return {
          ...post,
          tanggal: formatDate(post.tanggal)
        };
      });

      posts.value = formattedPosts;
      
      nextTick(() => {
        $('#Filter').DataTable({
          responsive: true,
          scrollX: true,
        });
      });
    })
    .catch(error => {
      console.log(error);
    });
};

    const confirmDelete = (id_sampel) => {
      if (confirm("Are you sure you want to delete this post?")) {
        deletePost(id_sampel);
      }
    };

    const deletePost = (id_sampel) => {
      axios.delete(`https://puracigarettesolutions.com:3000/api/filter/TampilanFilter/${id_sampel}`)
        .then(() => {
          getDataPosts();
        }).catch(error => {
          console.error("Error deleting post:", error.response.data);
        });
    };


    
    const downloadPdf = () => {
  const url = 'https://puracigarettesolutions.com:3000/api/filter/DownFilter';

  // Ensure that startDate and endDate are converted to Date objects
  const start = new Date(startDate.value);
  const end = new Date(endDate.value);

  let exportUrl = url;
  if (start && end) {
    const formattedStartDate = start.toISOString().split('T')[0];
    const formattedEndDate = end.toISOString().split('T')[0];
    exportUrl += `?start_date=${formattedStartDate}&end_date=${formattedEndDate}`;
  }

      axios.get(exportUrl)
        .then(response => {
            const doc = new jsPDF({
          orientation: 'landscape',
          // You can also set the format and other options
        });
        const tableColumnNames = ["No", "Nama", "Email","Country", "Phone","Company", "Job Title", "Alamat", "Nama kategori","Tanggal"];
        const tableRows = [];

          response.data.data.forEach((item, index) => {
            const formattedTanggal = new Date(item.tanggal).toISOString().split('T')[0];

            const tableRow = [
            index + 1,
              item.nama,
              item.email,
              item.country,
              item.no_hp,
              item.company,
              item.jabatan,
              item.alamat,
              item.nama_kategori,
              formattedTanggal
            ];
            tableRows.push(tableRow);
          });

          doc.autoTable(tableColumnNames, tableRows, { startY: 20 });
          doc.save('ctp.pdf');
        })
        .catch(error => {
          console.error('Error downloading PDF:', error);
        });
    };


    const downloadPdfById = (id_sampel) => {
  if (isNaN(id_sampel) || id_sampel <= 0) {
    console.error('Error: Invalid id_sampel');
    return;
  }
  const exportUrl = `https://puracigarettesolutions.com:3000/api/filter/DownloadFilter/${id_sampel}`;

  axios.get(exportUrl, { responseType: 'blob' })
    .then(response => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ctp_report_${id_sampel}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch(error => {
      console.error('Error downloading PDF:', error);
    });
};

const originalDate = new Date("Tue Jan 02 2024 15:00:25 GMT+0700 (Waktu Indonesia Barat)");
const year = originalDate.getFullYear();
const month = ('0' + (originalDate.getMonth() + 1)).slice(-2); // Month is zero-based
const day = ('0' + originalDate.getDate()).slice(-2);

const formattedDate = `${year}-${month}-${day}`;
console.log(formattedDate); // Output: "2024-01-02"

const formatDate = (date) => {
      if (!date) return '';
      const d = new Date(date);
      const day = ('0' + d.getDate()).slice(-2);
      const month = ('0' + (d.getMonth() + 1)).slice(-2);
      const year = d.getFullYear();
      return `${day}-${month}-${year}`;
    };


onMounted(() => {
  let today = new Date();
  let formattedDate = formatDate(today);
  startDate.value = formattedDate;
  endDate.value = formattedDate;
  getDataPosts();
});


    return {
    posts,
    confirmDelete,
    startDate,
    endDate,
    downloadPdf,
    downloadPdfById,
    formatDate,
    };
  },
};
</script>



  
<style>
/* CSS */
.image-container {
    width: 50px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-img-style {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

input[type="date"] {
    /* Style for your date pickers */
    border: 1px solid #ddd;
    padding: 5px;
    border-radius: 4px;
    margin: 5px;
}

</style>
  