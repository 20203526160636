<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <div class="content-wrapper">
            <section class="content">
                <div class="container-fluid">
                    <div class="card card-primary card-outline">
                        <div class="card-header">
                            <div class="col-6">
                                <h5>Ulang Tahun</h5>
                            </div>
                            <div class="text-right">
                                <router-link to="/UlangTahun" class="btn btn-primary" style="color: #ffffff;">
                                    <i class="fas fa-angle-left" style="color: #ffffff;"></i> Kembali
                                </router-link>
                            </div>
                        </div>
                        <form @submit.prevent="update" class="card-body">
                            <!-- Title Input -->
                            <div class="form-group">
                                <label for="postTitle">Nama:</label>
                                <input type="text" id="nama" v-model="post.nama" class="form-control"
                                    placeholder="Enter Post Title" required>
                            </div>

                            <!-- CKEditor Integration -->
                            <div class="form-group">
                                <label for="articleContent">Kalimat Ucapan:</label>
                                <ckeditor :editor="editor" v-model="post.ucapan" :config="editorConfig"></ckeditor>
                            </div>

                            <!-- Submit Button -->
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>

<script>
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { reactive, onMounted } from 'vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router'; // Removed unused useRoute import


export default {
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
        ckeditor: CKEditor.component
    },
    setup() {
        const post = reactive({
            id_ultah: '',
            nama: '',
            ucapan: '',
        });

        const router = useRouter();
        const route = useRoute();


        const editorConfig = {
            toolbar: ['heading', '|', 'bold', 'italic', '|', 'undo', 'redo'], // Customize toolbar as needed
            // Other CKEditor configurations
        };

        onMounted(async () => {
            if (!route.params.id_ultah) {
                console.error('No Artikel ID provided in route parameters.');
                return;
            }

            try {
                const response = await axios.get(`https://puracigarettesolutions.com:3000/api/ultahcigarette/UlangTahun/${route.params.id_ultah}`);
                console.log('Response from server:', response.data);

                if (response.data.success && response.data.data && typeof response.data.data === 'object') {
                    const { id_ultah, nama, ucapan } = response.data.data;
                    post.id_ultah = id_ultah || '';
                    post.nama = nama || '';
                    post.ucapan = ucapan || '';

                } else {
                    console.error('Invalid or missing data in server response.');
                }
            } catch (error) {
                console.error('Error fetching article data:', error);
            }
        });

        const update = async () => {
            try {
                const payload = {
                    nama: post.nama,
                    ucapan: post.ucapan
                };

                const response = await axios.put(`https://puracigarettesolutions.com:3000/api/ultahcigarette/EditUltah/${post.id_ultah}`, payload);

                if (response.status === 200) {
                    router.push('/UlangTahun');
                    console.log('Ucapan berhasil diperbarui');
                } else {
                    throw new Error('Gagal memperbarui Ucapan');
                }
            } catch (error) {
                console.error('Error updating article:', error);
                // Handle errors or validation if necessary
            }
        };


        return {
            post,
            editor: ClassicEditor,
            editorConfig,
            update,
        };
    },
};
</script>

