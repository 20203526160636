<template>
    <div class="wrapper">
        <NavbarDs />
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>
            <SidebarDs />
        </aside>

        <div class="content-wrapper">
            <section class="content">
                <div class="container-fluid">
                    <div class="card card-primary card-outline">
                        <div class="card-header">
                            <h5 class="col-6">Produk Baru</h5>
                            <div class="text-right">
                                <router-link to="/TampilanProduk" class="btn btn-primary">
                                    <i class="fas fa-angle-left"> </i> Kembali
                                </router-link>
                            </div>
                        </div>
                        <form @submit.prevent="store" class="card-body">
                            <div class="form-group">
                                <label for="judul_id">Title ID:</label>
                                <input type="text" id="judul_id" v-model="post.judul_id" class="form-control" required>
                            </div>
                            <div class="form-group">
                                <label for="judul_en">Title EN:</label>
                                <input type="text" id="judul_en" v-model="post.judul_en" class="form-control" required>
                            </div>
                            <div class="form-group">
                                <label for="id_kategori">Kategori:</label>
                                <select id="id_kategori" v-model="post.id_kategori" class="form-control">
                                    <option v-for="kategori in kategori" :key="kategori.id_kategori" :value="kategori.id_kategori">
                                        {{ kategori.nama_kategori }}
                                    </option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="keterangan_id">Keterangan ID:</label>
                                <ckeditor :editor="editor" v-model="post.keterangan_id" :config="editorConfig"></ckeditor>
                            </div>
                            <div class="form-group">
                                <label for="keterangan_en">Keterangan EN:</label>
                                <ckeditor :editor="editor" v-model="post.keterangan_en" :config="editorConfig"></ckeditor>
                            </div>
                            <div class="form-group" v-for="field in additionalFields" :key="field.name">
                                <label :for="field.ref">{{ field.label }}</label>
                                <select :id="field.ref" :ref="field.ref" multiple class="form-control">
                                    <option v-for="item in post[field.name]" :value="item" :key="item">{{ item }}</option>
                                </select>
                            </div>
                            <div class="form-group">
                                <label for="gambar1">Gambar:</label>
                                <input type="file" id="gambar1" class="form-control-file" @change="handleImageUpload('gambar1', $event)">
                                <img v-if="imagePreview1" :src="imagePreview1" alt="Preview Image 1" class="img-fluid mt-2">
                            </div>
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <FooterDs />
        <aside class="control-sidebar control-sidebar-dark"></aside>
    </div>
</template>

<script>
import jQuery from 'jquery';
import FooterDs from "../components/FooterDs.vue"
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from 'axios';

export default {
    name: "CombinedComponent",
    components: {
        FooterDs, NavbarDs, SidebarDs, ckeditor: CKEditor.component
    },
    data() {
        return {
            post: {
                judul_en: '', judul_id: '', keterangan_en: '', keterangan_id: '',
                deskripsi_en: '', deskripsi_id: '', id_kategori: '',
                gambar1: null,
                // Initialize new properties
                printed: [], flavor: [], sweetener: [], finishing: [], size: [],
                materials: [], features: [], length: [], filter: [], diameter: [], special: []
            },
            imagePreview1: '',
            imagePreview2: '',
            imagePreview3: '',
            kategori: [],
            keyword: [],
            editor: ClassicEditor,
            editorConfig: {},
            isSubmitting: false,
            additionalFields: [
                { name: 'printed', label: 'Printed', ref: 'printed' },
                { name: 'flavor', label: 'Flavor', ref: 'flavor' },
                { name: 'sweetener', label: 'Sweetener', ref: 'sweetener' },
                { name: 'finishing', label: 'Finishing', ref: 'finishing' },
                { name: 'size', label: 'Size', ref: 'size' },
                { name: 'materials', label: 'Materials', ref: 'materials' },
                { name: 'features', label: 'Added Features', ref: 'features' },
                { name: 'length', label: 'Length', ref: 'length' },
                { name: 'filter', label: 'Filter Tips', ref: 'filter' },
                { name: 'diameter', label: 'Diameter Size', ref: 'diameter' },
                { name: 'special', label: 'Special Features', ref: 'special' },
                // Add more fields as needed
            ]
        };
    },
    mounted() {
        this.fetchKategori();
        this.initializeSelect2();
    },
    methods: {
        initializeSelect2() {
            const vm = this;
            this.additionalFields.forEach(field => {
                jQuery(this.$refs[field.ref]).select2({
                    tags: true,
                    tokenSeparators: [',', '\n'],
                    theme: 'classic'
                }).on('change', function () {
                    const data = jQuery(this).select2('data');
                    vm.post[field.name] = data.map(item => item.text);
                });
            });
        },
        async fetchKategori() {
            try {
                const response = await axios.get('https://puracigarettesolutions.com:3000/api/kategori/TampilanKategori');
                this.kategori = response.data.data;
            } catch (error) {
                console.error('Error fetching categories:', error);
            }
        },
        async store() {
    const formData = new FormData();
    Object.keys(this.post).forEach(key => {
        if (key.startsWith('gambar')) {
            if (this.post[key] !== null) formData.append(key, this.post[key]);
        } else if (Array.isArray(this.post[key])) {
            formData.append(key, this.post[key].join(',')); // Join array elements with commas
        } else {
            formData.append(key, this.post[key]);
        }
    });

    try {
        const response = await axios.post('https://puracigarettesolutions.com:3000/api/produk/CreateProduk', formData);
        console.log('Response:', response);
        this.$router.push({ name: 'TampilanProduk' });
    } catch (error) {
        console.error('Error:', error);
    }
},
        handleImageUpload(field, event) {
            const files = event.target.files;
            if (files.length > 0) {
                this.post[field] = files[0];
                if (field === 'gambar1') {
                    this.imagePreview1 = URL.createObjectURL(files[0]);
                }
            }
        }
    }
};
</script>



  
<style></style>
  

