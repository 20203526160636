<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MICS</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Data Kategori</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">Data Kategori</li>
                            </ol>
                        </div>
                    </div>
                </div><!-- /.container-fluid -->
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header row">
                                    <h3 class="card-title">Data Users</h3>
                                    <div class="col-12 d-flex justify-content-end">
                                        <router-link to="/REgisterDs" class="btn btn-primary">
                                            <i class="fas fa-plus" style="color: #ffffff;"> Tambah Users</i>
                                        </router-link>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <table id="users" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">No</th>
                                                <th scope="col">Username</th>
                                                <th scope="col">Password</th>
                                                <th scope="col">OPTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(post, index) in posts" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ post.username }}</td>
                                                <td>{{ post.decodedPassword}}</td>
                                                <td class="text-center">
                                
                                                    <button @click.prevent="confirmDelete(post.id_users)"
                                                        class="btn btn-sm btn-danger">DELETE</button>
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                                <!-- /.card-body -->
                            </div>
                            <!-- /.card -->
                        </div>
                        <!-- /.col -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.container-fluid -->
            </section>
            <!-- /.content -->
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>
  
<script>
import axios from 'axios';
import { onMounted, onUnmounted, ref } from 'vue';
import { nextTick } from 'vue';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";

export default {
  name: "LoginDs",
  components: {
    FooterDs,
    NavbarDs,
    SidebarDs,
  },
  setup() {
    const posts = ref([]);
    
    const getDataPosts = () => {
  axios.get('https://puracigarettesolutions.com/api/users/TampilanUsers')
    .then(response => {
      // Convert Buffer data to readable string for each user
      response.data.data.forEach(post => {
        if (post.username && post.username.type === 'Buffer') {
          const usernameArray = new Uint8Array(post.username.data);
          const decodedUsername = new TextDecoder().decode(usernameArray);
          post.username = decodedUsername;
        }

        if (post.password && post.password.type === 'Buffer') {
          const passwordArray = new Uint8Array(post.password.data);
          const decodedPassword = new TextDecoder().decode(passwordArray);
          post.password = decodedPassword;
        }
      });

      posts.value = response.data.data;

      // Initialize DataTables after data is fetched
      nextTick(() => {
        $('#users').DataTable({});
      });
    })
    .catch(error => {
      console.log(error);
    });
};



    const confirmDelete = (id_users) => {
      if (confirm("Are you sure you want to delete this post?")) {
        deletePost(id_users);
      }
    };

    const deletePost = (id_users) => {
      axios.delete(`https://puracigarettesolutions.com:3000/api/users/TampilanUsers/${id_users}`)
        .then(() => {
          getDataPosts();
        })
        .catch(error => {
          console.error("Error deleting post:", error.response.data);
        });
    };

    onMounted(getDataPosts);

    // Destroy DataTable instance when the component is unmounted
    onUnmounted(() => {
      const dataTable = $('#users').DataTable();
      if (dataTable) {
        dataTable.destroy();
      }
    });

    return {
      posts,
      confirmDelete
    };
  },
};
</script>
  
<style></style>
  