import { createStore } from 'vuex';

export default createStore({
    state: {
        isLoggedIn: false,
        userRole: null, // Menambahkan state userRole
    },
    mutations: {
        SET_LOGGED_IN(state, payload) {
            state.isLoggedIn = payload;
        },
        SET_USER_ROLE(state, role) {
            state.userRole = role;
        }
    },
    actions: {
        login({ commit }, userData) {
            // Simulasi login sukses
            commit('SET_LOGGED_IN', true);
            commit('SET_USER_ROLE', userData.id_role); // userData adalah respons dari proses login
        },
        logout({ commit }) {
            // Simulasi logout sukses
            commit('SET_LOGGED_IN', false);
            commit('SET_USER_ROLE', null); // Atur userRole menjadi null saat logout
        }
    },
    getters: {
        isLoggedIn: state => state.isLoggedIn,
        userRole: state => state.userRole,
        loggedInUserRole: state => state.userRole, // Getter untuk userRole
    }
});
