import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import store from './store';
import router from './router';
import 'aos/dist/aos.css';
import VueLazyload from 'vue-lazyload';
import { createI18n } from 'vue-i18n';

// Import translation files
import en from './locales/en.json';
import id from './locales/id.json';

// Configure Vue I18n
const i18n = createI18n({
  legacy: false,
  locale: 'en', // default language
  fallbackLocale: 'en',
  messages: { en, id },
});



const app = createApp(App);

app.config.globalProperties.$axios = axios;
app.use(router);
app.use(store);
app.use(i18n);
app.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  listenEvents: ['scroll']
});
app.mount('#app');
