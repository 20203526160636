<template>
  <div class="wrapper">

      <NavbarDs />
      <!-- /.navbar -->

      <!-- Main Sidebar Container -->
      <aside class="main-sidebar sidebar-dark-primary elevation-4">
          <!-- Brand Logo -->
          <a href="index3.html" class="brand-link">
              <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                  class="brand-image img-circle elevation-3" style="opacity: .8">
              <span class="brand-text font-weight-light">PURA MISC</span>
          </a>

          <SidebarDs />
          <!-- /.sidebar -->
      </aside>

      <div class="content-wrapper">
          <section class="content">
              <div class="container-fluid">
                  <div class="card card-primary card-outline">
                      <div class="card-header">
                          <div class="col-6">
                              <h5>Tulisan Baru</h5>
                          </div>
                          <div class="text-right">
                              <router-link to="/TampilanProduk" class="btn btn-primary" style="color: #ffffff;">
                                  <i class="fas fa-angle-left" style="color: #ffffff;"></i> Kembali
                              </router-link>
                          </div>
                      </div>
                      <form @submit.prevent="update" class="card-body">
                          <!-- Title Input -->
                          <div class="form-group">
                              <label for="postTitle">Title IN:</label>
                              <input type="text" id="judul_id" v-model="post.judul_id" class="form-control"
                                  placeholder="Enter Post Title" required>
                          </div>

                          <div class="form-group">
                              <label for="postTitle">Title EN:</label>
                              <input type="text" id="judul_en" v-model="post.judul_en" class="form-control"
                                  placeholder="Enter Post Title" required>
                          </div>

                          <div class="form-group">
                          <label for="id_kategori">Category:</label>
                          <select v-model="post.id_kategori" class="form-control" required>
                            <option v-for="kategoriItem in kategori" :key="kategoriItem.id_kategori" :value="kategoriItem.id_kategori">
                              {{ kategoriItem.nama_kategori }}
                            </option>
                          </select>
                        </div>

                          <!-- CKEditor Integration -->
                          <div class="form-group">
                              <label for="articleContent">Keterangan IN:</label>
                              <ckeditor :editor="editor" v-model="post.keterangan_id" :config="editorConfig"></ckeditor>
                          </div>

                           <!-- CKEditor Integration -->
                           <div class="form-group">
                              <label for="articleContent">Keterangan EN</label>
                              <ckeditor :editor="editor" v-model="post.keterangan_en" :config="editorConfig"></ckeditor>
                          </div>

                          <div class="form-group" v-for="field in additionalFields" :key="field.name">
                              <label :for="field.ref">{{ field.label }}</label>
                              <select :id="field.ref" :ref="field.ref" multiple class="form-control" v-model="post[field.name]">
                                <option v-for="item in field.options" :value="item" :key="item">{{ item }}</option>
                              </select>
                            </div>

                          <div class="form-group">
                              <label for="imageUpload">Image:</label>
                              <input type="file" @change="handleFileUpload" accept="image/*" />
                              <div class="image-container">
                                  <img :src="getFileUrl(post.gambar1)" alt="Deskripsi Gambar" class="custom-img-style">
                              </div>
                              <img v-if="post.gambar1" :src="post.gambar1" height="50" width="50" alt="Image Preview" />
                          </div>

                          <!-- Submit Button -->
                          <div class="form-group">
                              <button type="submit" class="btn btn-primary">Submit</button>
                          </div>
                      </form>
                  </div>
              </div>
          </section>
      </div>
      <!-- /.content-wrapper -->
      <!--Footer-->
      <FooterDs />

      <!-- Control Sidebar -->
      <aside class="control-sidebar control-sidebar-dark">
          <!-- Control sidebar content goes here -->
      </aside>
      <!-- /.control-sidebar -->
  </div>
  <!-- ./wrapper -->
</template>

<script>
  import jQuery from 'jquery';
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { reactive, ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router'; // Removed unused useRoute import
import 'select2/dist/css/select2.css';
import 'select2';




export default {
  components: {
      FooterDs,
      NavbarDs,
      SidebarDs,
      ckeditor: CKEditor.component
  },
  setup() {
      const post = reactive({
          id_produk: '',
          judul_id: '',
          judul_en: '',
          keterangan_id: '',
          keterangan_en: '',
          id_kategori: '',
          printed: [],
          flavor: [],
          sweetener: [],
          finishing: [],
          size: [],
          materials: [],
          features: [],
          length: [],
          filter: [],
          diameter: [],
          special: [],
          gambar1: null,
      });

      const imageFile = ref(null);
      const router = useRouter();
      const kategori = reactive([]);
      const route = useRoute();


      const editorConfig = {
          toolbar: ['heading', '|', 'bold', 'italic', '|', 'undo', 'redo'], // Customize toolbar as needed
          // Other CKEditor configurations
      };


      const additionalFields = reactive([
  { name: 'printed', label: 'Printed', ref: 'printed', options: [] },
  { name: 'flavor', label: 'Flavor', ref: 'flavor', options: [] },
  { name: 'sweetener', label: 'Sweetener', ref: 'sweetener', options: [] },
  { name: 'finishing', label: 'Finishing', ref: 'finishing', options: [] },
  { name: 'size', label: 'Size', ref: 'size', options: [] },
  { name: 'materials', label: 'Materials', ref: 'materials', options: [] },
  { name: 'features', label: 'Added Features', ref: 'features', options: [] },
  { name: 'length', label: 'Length', ref: 'length', options: [] },
  { name: 'filter', label: 'Filter Tips', ref: 'filter', options: [] },
  { name: 'diameter', label: 'Diameter Size', ref: 'diameter', options: [] },
  { name: 'special', label: 'Special Features', ref: 'special', options: [] },
]);

const fetchKategori = async () => {
        try {
          const response = await axios.get('https://puracigarettesolutions.com:3000/api/kategori/TampilanKategori');
          kategori.splice(0, kategori.length, ...response.data.data);
        } catch (error) {
          console.error('Error fetching categories:', error);
        }
      };

      const initializeSelect2 = () => {
  additionalFields.forEach(field => {
    const $select = jQuery(`#${field.ref}`);
    const options = post[field.name] || [];

    $select.select2({
      data: options.map(option => ({ id: option, text: option })),
      multiple: true,
      tags: true,
      tokenSeparators: [',', '\n'],
    }).on('change', function () {
      post[field.name] = $select.val() || [];
    });

    $select.val(options).trigger('change');
  });
};

onMounted(async () => {
      await fetchKategori();
      initializeSelect2();
    

          if (!route.params.id_produk) {
              console.error('No Artikel ID provided in route parameters.');
              return;
          }

          try {
              const response = await axios.get(`https://puracigarettesolutions.com:3000/api/produk/TampilanProduk/${route.params.id_produk}`);
              console.log('Response from server:', response.data);

              if (response.data.success && response.data.data && typeof response.data.data === 'object') {
                  const { id_produk, judul_id, judul_en, id_kategori, keterangan_id, keterangan_en, flavor, printed, sweetener, size, materials, features, filter, diameter, special, gambar1 } = response.data.data;
                  post.id_produk = id_produk || '';
                  post.judul_id = judul_id || '';
                  post.judul_en = judul_en || '';
                  post.id_kategori = id_kategori || '';
                  post.keterangan_id = keterangan_id || '';
                  post.keterangan_en = keterangan_en || '';
                  post.printed = printed || '';
                  post.flavor = flavor || '';
                  post.sweetener = sweetener || '';
                  post.size = size || '';
                  post.materials = materials || '';
                  post.features = features || '';
                  post.length = length || '';
                  post.filter = filter || '';
                  post.diameter = diameter || '';
                  post.special =special || '';
                  post.gambar1 = gambar1 || null;
                 
              } else {
                  console.error('Invalid or missing data in server response.');
              }
          } catch (error) {
              console.error('Error fetching article data:', error);
          }
      });

      const handleFileUpload = (event) => {
          const file = event.target.files[0];
          if (file) {
              const imageUrl = URL.createObjectURL(file);
              post.gambar1 = imageUrl; // Mengganti this.post.previewImage menjadi post.gambar
              imageFile.value = file;
          }
      };

      const getFileUrl = (filename) => {
          const baseUrl = 'https://puracigarettesolutions.com:3000/api/produk/file';
          return `${baseUrl}/${filename}`;
      };

      const update = async () => {
          try {
              const formData = new FormData();
              formData.append('judul_id', post.judul_id);
              formData.append('judul_en', post.judul_en);
              formData.append('keterangan_id', post.keterangan_id);
              formData.append('keterangan_en', post.keterangan_en);
              formData.append('id_kategori', post.id_kategori);
              formData.append('printed', post.printed);
              formData.append('flavor', post.flavor);
              formData.append('sweetener', post.sweetener);
              formData.append('size', post.size);
              formData.append('materials', post.materials);
              formData.append('fitur', post.filter);
              formData.append('features', post.features);
              formData.append('length', post.length);
              formData.append('diameter', post.diameter);
              formData.append('special', post.special);
              if (imageFile.value) {
                  formData.append('gambar1', imageFile.value);
              }

              const config = {
                  headers: {
                      'Content-Type': 'multipart/form-data'
                  }
              };

              const response = await axios.put(`https://puracigarettesolutions.com:3000/api/produk/EditProduk/${post.id_produk}`, formData, config);

              if (response.status === 200) {
                  router.push('/TampilanProduk');
                  console.log('Produk berhasil diperbarui');
              } else {
                  throw new Error('Gagal memperbarui Produk');
              }
          } catch (error) {
              console.error('Error updating Product:', error);
              // Handle errors or validation if necessary
          }
      };

      return {
          post,
          imageFile,
          editor: ClassicEditor,
          editorConfig,
          additionalFields,
          handleFileUpload,
          kategori,
          getFileUrl,
          update,
      };
  },
};
</script>


<style>
/* CSS */
.image-container {
  width: 150px;
  height: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-img-style {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>
