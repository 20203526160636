<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Ulang Tahun</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">DataTables</li>
                            </ol>
                        </div>
                    </div>
                </div><!-- /.container-fluid -->
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header row">
                                    <h3 class="card-title">Ulang Tahun</h3>
                                    <div class="col-12 d-flex justify-content-end">
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table id="ultah" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">No</th>
                                                <th scope="col">Nama</th>
                                                <th scope="col">Kalimat Ucapan</th>
                                                <th scope="col">OPTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(post, index) in posts" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ post.nama }}</td>
                                                <td>{{ post.ucapan }}</td>
                                                <td class="text-center">
                                                    <router-link
                                                        :to="{ name: 'EditUltah', params: { id_ultah: post.id_ultah } }"
                                                        class="btn btn-warning mr-2"
                                                        style="color: #ffffff;">EDIT</router-link>
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>
                                        </div>
                                   
                                </div>
                                <!-- /.card-body -->
                            </div>
                            <!-- /.card -->
                        </div>
                        <!-- /.col -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.container-fluid -->
            </section>
            <!-- /.content -->
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>
  
<script>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { nextTick } from 'vue';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";

export default {
    name: "LoginDs",
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
    },
    setup() {
        const posts = ref([]);

        const getDataPosts = () => {
            axios.get('https://puracigarettesolutions.com:3000/api/ultahcigarette/UlangTahun')
                .then(response => {
                    posts.value = response.data.data;
                    // Initialize DataTables after data is fetched
                    nextTick(() => {
                        $('#ultah').DataTable();
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        };

        onMounted(getDataPosts);

        return {
            posts,
        };
    },
};
</script>
  
<style>
/* CSS */
.table-responsive {
    overflow-x: auto;
}

.table td, .table th {
    white-space: nowrap;
}

@media (max-width: 768px) {
    .custom-img-style {
        /* Adjust image size for small screens */
        width: 30px;
        height: 30px;
    }
}
</style>
  