<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <div class="content-wrapper">
            <section class="content">
                <div class="container-fluid">
                    <div class="card card-primary card-outline">
                        <div class="card-header">
                            <div class="col-6">
                                <h5>Tulisan Baru</h5>
                            </div>
                            <div class="text-right">
                                <router-link to="/TampilanKategori" class="btn btn-primary" style="color: #ffffff;">
                                    <i class="fas fa-angle-left" style="color: #ffffff;"> </i> Kembali
                                </router-link>
                            </div>
                        </div>
                        <form @submit.prevent="update" class="card-body">
                            <!-- Title Input -->
                            <div class="form-group">
                                <label for="postTitle">Nama Kategori:</label>
                                <input type="text" id="nama_kategoeri" v-model="post.nama_kategori" class="form-control"
                                    placeholder="Enter Post Title" required>
                            </div>

                            <!-- Submit Button -->
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>
  
<script>
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import { reactive, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';

export default {
    name: "EditKategori",
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
    },
    setup() {
        const post = reactive({
            id_kategori: '',
            nama_kategori: '',
        });

        const validation = ref({ errors: {} });

        const router = useRouter();
        const route = useRoute();
        onMounted(async () => {
            if (!route.params.id_kategori) {
                console.error('No category ID provided in route parameters.');
                return;
            }

            try {
                const response = await axios.get(`https://puracigarettesolutions.com:3000/api/kategori/TampilanKategori/${route.params.id_kategori}`);
                console.log('Response from server:', response.data);

                if (response.data.success && response.data.data && typeof response.data.data === 'object') {
                    post.id_kategori = response.data.data.id_kategori || ''; // Assign values or default to empty string
                    post.nama_kategori = response.data.data.nama_kategori || '';
                } else {
                    console.error('Invalid or missing data in server response.');
                    // Set default values in case of no data or invalid response
                    post.id_kategori = '';
                    post.nama_kategori = '';
                }
            } catch (error) {
                console.error('Error fetching category data:', error);
                if (error.response) {
                    // Handle specific HTTP error statuses if needed
                }
                // Set default values in case of error
                post.id_kategori = '';
                post.nama_kategori = '';
            }
        });



        const update = async () => {
            try {
                await axios.put(`https://puracigarettesolutions.com:3000/api/kategori/EditKategori/${post.id_kategori}`, {
                    nama_kategori: post.nama_kategori,
                });
                router.push('/TampilanKategori');
                console.log('Category updated successfully!');
            } catch (error) {
                validation.value.errors = error.response.data;
            }
        };

        return {
            post,
            validation,
            update,
        };
    },
};
</script>

  
<style></style>
  

