<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Data Produk</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">Data Produk</li>
                            </ol>
                        </div>
                    </div>
                </div><!-- /.container-fluid -->
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header row">
                                    <h3 class="card-title">Data Produk</h3>
                                    <div class="col-12 d-flex justify-content-end">
                                        <router-link to="/TambahProduk" class="btn btn-primary">
                                            <i class="fas fa-plus" style="color: #ffffff;"> Tambah Produk</i>
                                        </router-link>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table id="produk" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">No</th>
                                                <th scope="col">Nama Produk IN</th>
                                                <th scope="col">Nama Produk EN</th>
                                                <th scope="col">kategori</th>
                                                <th scope="col">Gambar List Porduk</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(post, index) in posts" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ post.judul_en }}</td>
                                                <td>{{ post.judul_id }}</td>
                                                <td>{{ post.nama_kategori }}</td>
                                                <td>
                                                    <div class="image-container">
                                                        <img :src="getFileUrl(post.gambar1)" alt="Deskripsi Gambar"
                                                            class="custom-img-style">
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <router-link
                                                        :to="{ name: 'EditProduk', params: { id_produk: post.id_produk } }"
                                                        class="btn btn-warning mr-2"
                                                        style="color: #ffffff;">EDIT</router-link>
                                                    <button @click.prevent="confirmDelete(post.id_produk)"
                                                        class="btn btn-sm btn-danger">DELETE</button>
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>
                                        </div>
                                 
                                </div>
                                <!-- /.card-body -->
                            </div>
                            <!-- /.card -->
                        </div>
                        <!-- /.col -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.container-fluid -->
            </section>
            <!-- /.content -->
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>
  
<script>
import axios from 'axios';
import { onMounted, ref, watch, nextTick } from 'vue';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";

export default {
    name: "TampilanProduk",
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
    },
    setup() {
        const posts = ref([]);

        const getDataPosts = () => {
            axios.get('https://puracigarettesolutions.com:3000/api/produk/TampilanProduk')
                .then(response => {
                    posts.value = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const initDataTable = () => {
            nextTick(() => {
                const table = $.fn.dataTable.isDataTable('#produk');
                if (table) {
                    $('#produk').DataTable().destroy();
                }
                $('#produk').DataTable({
                    responsive: true,
                    scrollX: true
                });
            });
        };

        const getFileUrl = (filename) => {
            const baseUrl = 'https://puracigarettesolutions.com:3000/api/produk/file';
            return `${baseUrl}/${filename}`;
        };

        const confirmDelete = (id_produk) => {
            if (confirm("Are you sure you want to delete this product?")) {
                deletePost(id_produk);
            }
        };

        const deletePost = (id_produk) => {
    // Ensure id_produk is a valid number or string
    if (!id_produk) {
        console.error("Invalid product ID");
        return; // Exit the function if no valid ID is provided
    }

    // Perform the delete operation
    axios.delete(`https://puracigarettesolutions.com:3000/api/produk/TampilanProduk/${id_produk}`)
        .then(() => {
            console.log("Product deleted successfully");
            getDataPosts(); // Refresh data after deleting
        })
        .catch(error => {
            // Log detailed error information and show user-friendly message if possible
            console.error("Error deleting product:", error.response ? error.response.data : "No response received from server");
            // Optionally update the UI to show an error message
            alert("Failed to delete product. Please try again.");
        });
};


        // Watch the posts array for changes and re-initialize DataTables
        watch(posts, (newPosts) => {
            if (newPosts.length) {
                initDataTable();
            }
        }, { immediate: true });

        onMounted(getDataPosts);

        return {
            posts,
            getFileUrl,
            confirmDelete
        };
    },
};
</script>

  
<style>
/* CSS */
.image-container {
    width: 50px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-img-style {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

/* CSS */
.table-responsive {
    overflow-x: auto;
}

.table td, .table th {
    white-space: nowrap;
}

@media (max-width: 768px) {
    .custom-img-style {
        /* Adjust image size for small screens */
        width: 30px;
        height: 30px;
    }
}
</style>
  