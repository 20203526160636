<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Tampilan Artikel</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">Tampilan Artikel</li>
                            </ol>
                        </div>
                    </div>
                </div><!-- /.container-fluid -->
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header row">
                                    <h3 class="card-title">Data Artikel</h3>
                                    <div class="col-12 d-flex justify-content-end">
                                        <router-link to="/TambahArtikel" class="btn btn-primary">
                                            <i class="fas fa-plus" style="color: #ffffff;"> Tambah Artikel</i>
                                        </router-link>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table id="example2" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">No</th>
                                                <th scope="col">Judul EN</th>
                                                <th scope="col">Judul ID</th>
                                                <th scope="col">kategori</th>
                                                <th scope="col">Tanggal</th>
                                                <th scope="col">Deskripsi EN</th>
                                                <th scope="col">Deskripsi ID</th>
                                                <th scope="col">Keterangan EN</th>
                                                <th scope="col">Keterangan ID</th>
                                                <th scope="col">Pembuat</th>
                                                <th scope="col">Referensi 1</th>
                                                <th scope="col">Referensi 2</th>
                                                <th scope="col">Referensi 3</th>
                                                <th scope="col">Referensi 4</th>
                                                <th scope="col">Gambar Untuk Home</th>
                                                <th scope="col">Gambar Untuk List Produk</th>
                                                <th scope="col">Gambar Untuk Detail Produk</th>
                                                <th scope="col">OPTIONS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(post, index) in posts" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ post.judul_en }}</td>
                                                <td>{{ post.judul_id }}</td>
                                                <td>{{ post.nama_kategori }}</td>
                                                <td>{{ post.tanggal }}</td>
                                                <td>{{ post.deskripsi_en }}</td>
                                                <td>{{ post.deskripsi_id }}</td>
                                                <td>{{ post.keterangan_en }}</td>
                                                <td>{{ post.keterangan_id }}</td>
                                                <td>{{ post.pembuat }}</td>
                                                <td>{{ post.link_ref1 }}</td>
                                                <td>{{ post.link_ref2 }}</td>
                                                <td>{{ post.link_ref3 }}</td>
                                                <td>{{ post.link_ref4 }}</td>
                                                <td>
                                                    <div class="image-container">
                                                        <img :src="getFileUrl(post.gambar1)" alt="Deskripsi Gambar"
                                                            class="custom-img-style">
                                                    </div>

                                                </td>
                                                 <td>
                                                    <div class="image-container">
                                                        <img :src="getFileUrl(post.gambar2)" alt="Deskripsi Gambar"
                                                            class="custom-img-style">
                                                    </div>

                                                </td>
                                                <td>
                                                    <div class="image-container">
                                                        <img :src="getFileUrl(post.gambar3)" alt="Deskripsi Gambar"
                                                            class="custom-img-style">
                                                    </div>

                                                </td>
                                                <td class="text-center">
                                                    <router-link
                                                        :to="{ name: 'EditArtikel', params: { id_artikel: post.id_artikel } }"
                                                        class="btn btn-warning mr-2"
                                                        style="color: #ffffff;">EDIT</router-link>
                                                    <button @click.prevent="confirmDelete(post.id_artikel)"
                                                        class="btn btn-sm btn-danger">DELETE</button>
                                                </td>

                                            </tr>

                                        </tbody>
                                    </table>
                                
                                    </div>
                                
                                </div>
                                <!-- /.card-body -->
                            </div>
                            <!-- /.card -->
                        </div>
                        <!-- /.col -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.container-fluid -->
            </section>
            <!-- /.content -->
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>
  
<script>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { nextTick } from 'vue';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import moment from 'moment';

export default {
    name: "TampilanArtikel",
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
    },
    setup() {
        const posts = ref([]);

        const getDataPosts = () => {
            axios.get('https://puracigarettesolutions.com:3000/api/artikel/TampilanArtikel')
                .then(response => {
                    posts.value = response.data.data.map(post => ({
                        ...post,
                        tanggal: moment(post.tanggal).format('YYYY-MM-DD') // Memformat tanggal di sini
                    }));
                    nextTick(() => {
                        $('#example2').DataTable({
                            responsive: true,
                             scrollX: true
                        });
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const getFileUrl = (filename) => {
            const baseUrl = 'https://puracigarettesolutions.com:3000/api/artikel/file';
            return `${baseUrl}/${filename}`;
        };


        const confirmDelete = (id_artikel) => {
            if (confirm("Are you sure you want to delete this post?")) {
                deletePost(id_artikel);
            }
        };

        const deletePost = (id_artikel) => {
            axios.delete(`https://puracigarettesolutions.com:3000/api/artikel/TampilanArtikel/${id_artikel}`)
                .then(() => {
                    getDataPosts();
                }).catch(error => {
                    console.error("Error deleting post:", error.response.data);
                });
        };

        onMounted(getDataPosts);

        return {
            posts,
            getFileUrl,
            confirmDelete
        };
    },
};
</script>
  
<style>
/* CSS */
.image-container {
    width: 50px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-img-style {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}


/* CSS */
.table-responsive {
    overflow-x: auto;
}

.table td, .table th {
    white-space: nowrap;
}

@media (max-width: 768px) {
    .custom-img-style {
        /* Adjust image size for small screens */
        width: 30px;
        height: 30px;
    }
}


</style>
  