<!-- ScrollToFooterButton.vue -->
<template>
    <a href="#" class="apbc-scroll-footer" v-show="isVisible" @click.prevent="scrollToFooter">
      <i class="fas fa-angle-double-down"></i>
    </a>
  </template>
  
  <script>
  import { ref, onMounted, onUnmounted } from 'vue';
  
  export default {
    name: 'ScrollToFooterButton',
    setup() {
      const isVisible = ref(false);
  
      const toggleVisibility = () => {
        const productsGrid = document.querySelector('.products-grid');
        if (productsGrid) {
          const scrollPosition = window.pageYOffset;
          const productsGridRect = productsGrid.getBoundingClientRect();
          const productsGridTop = productsGridRect.top + scrollPosition;
          const productsGridMiddle = productsGridTop + (productsGridRect.height / 2);
          
          // Show button if scrolled more than 300px and not reached middle of products grid
          isVisible.value = scrollPosition > 300 && scrollPosition < productsGridMiddle;
        }
      };
  
      const scrollToFooter = () => {
        const footer = document.querySelector('.apbc-footer-section');
        if (footer) {
          footer.scrollIntoView({ behavior: 'smooth' });
        }
      };
  
      onMounted(() => {
        window.addEventListener('scroll', toggleVisibility);
        window.addEventListener('resize', toggleVisibility);
        // Initial check
        toggleVisibility();
      });
  
      onUnmounted(() => {
        window.removeEventListener('scroll', toggleVisibility);
        window.removeEventListener('resize', toggleVisibility);
      });
  
      return {
        isVisible,
        scrollToFooter
      };
    }
  };
  </script>
  
  <style scoped>
  .apbc-scroll-footer {
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 50px;
    height: 50px;
    background-color: #ffc107;
    text-align: center;
    line-height: 50px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 25px;
    z-index: 100;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .apbc-scroll-footer:hover {
    background-color: #000;
    color: #ffc107;
  }
  </style>