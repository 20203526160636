<template>
  <div v-cloak>
    <HeaderCom />
    <section class="saasio-breadcurmb-section-detailblog">
      <div class="container">
        <div class="breadcurmb-title-detailblog">
        </div>
      </div>
    </section>

    <div class="filter-blog-section" v-if="article">
      <div class="filter-blog-label">
        <article class="center-text" v-html="currentLanguage === 'en' ? article.judul_en : article.judul_id"></article>
      </div>
      <div class="event-links" v-if="article.keyword">
        <span v-for="(keyword, i) in article.keyword.split(',')" :key="i">
          <a href="#" class="event-link">{{ keyword }}</a>
        </span>
      </div>
    </div>

    <section id="news-feed" class="news-feed-section" v-if="article">
      <div class="container">
        <div class="blog-feed-content">
          <div class="row">
            <div class="col-md-12">
              <div class="saasio-blog-details-content">
                <!-- Description Section -->
                <div class="blog-details-text dia-headline" v-if="article.deskripsi_en || article.deskripsi_id">
                  <article class="justify-text" v-html="currentLanguage === 'en' ? article.deskripsi_en : article.deskripsi_id"></article>
                </div>

                <!-- Main Image -->
                <div class="gambarnews" v-if="article.gambar3">
                  <img :src="getFileUrl(article.gambar3)" alt="Article Image">
                </div>

                <!-- Content Sections -->
                <div class="blog-details-text dia-headline" v-if="article.keterangan_en || article.keterangan_id">
                  <article class="justify-text" v-html="currentLanguage === 'en' ? article.keterangan_en : article.keterangan_id"></article>
                </div>

                <div class="blog-details-text dia-headline" v-if="article.keterangan_en2 || article.keterangan_id2">
    <div class="faq-content">
      <article 
        class="justify-text" 
        v-html="processContent(currentLanguage === 'en' ? article.keterangan_en2 : article.keterangan_id2)"
      ></article>
    </div>
  </div>

                <!-- References Section -->
                <div class="ref-details-text" v-if="hasReferences">
                  <h5>References</h5>
                  <div v-if="article.link_ref1">
                    <a :href="article.link_ref1" target="_blank">{{ article.link_ref1 }}</a>
                  </div>
                  <div v-if="article.link_ref2">
                    <a :href="article.link_ref2" target="_blank">{{ article.link_ref2 }}</a>
                  </div>
                  <div v-if="article.link_ref3">
                    <a :href="article.link_ref3" target="_blank">{{ article.link_ref3 }}</a>
                  </div>
                  <div v-if="article.link_ref4">
                    <a :href="article.link_ref4" target="_blank">{{ article.link_ref4 }}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Related Articles Section -->
    <section class="blog-tambahan" v-if="relatedArticles.length">
  <div class="interesting">
    <h2>{{ $t('detailblog.title') }}</h2>
  </div>
  <div class="container-blog">
    <div class="card-blog" v-for="post in relatedArticles" :key="post.id_artikel">
      <div class="blog-img">
        <img :src="getFileUrl(post.gambar1)" alt="Blog Image" class="card-image-blog">
        <div class="card-blog-content">
          <article class="center-text" v-html="currentLanguage === 'en' ? post.judul_en : post.judul_id"></article>
          <router-link 
            :to="`/DetailBlog/${createSlug(currentLanguage === 'en' ? post.judul_en : post.judul_id)}`"
          >
            <button class="read-more-button-blog" @click="scrollToTop">Read More</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</section>
  </div>
</template>

<script>
import axios from 'axios';
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import HeaderCom from '@/components/HeaderCom.vue';
import { metaMixin } from '@/mixins/metaMixin';

const BASE_URL = 'https://puracigarettesolutions.com:3000/api/artikel';

export default {
  name: 'DetailBlog',
  mixins: [metaMixin],
  components: {
    HeaderCom,
  },
  setup() {
    const article = ref(null);
    const relatedArticles = ref([]);
    const route = useRoute();
    const router = useRouter();
    const { locale } = useI18n();
    const loading = ref(false);
    const error = ref(null);

    const currentLanguage = computed(() => locale.value);

    const createSlug = (title) => {
  if (!title) return '';

  // Step 1: Clean HTML and get main title
  let cleanTitle = title
    .replace(/<[^>]*>/g, '')         // Remove HTML tags
    .replace(/&nbsp;/g, ' ')         // Replace &nbsp; with space
    .trim();                         // Remove leading/trailing spaces

  // Step 2: Get only the first part of the title (before any separator)
  cleanTitle = cleanTitle.split(/[:\n]/)[0].trim();
  
  // Step 3: Take only first few significant words (4-5 words)
  const words = cleanTitle.split(/\s+/).slice(0, 5);
  cleanTitle = words.join(' ');

  // Step 4: Create consistent slug format
  return cleanTitle
    .toLowerCase()
    .replace(/[^a-z0-9\s-]/g, '')   // Remove special characters
    .replace(/\s+/g, '-')           // Replace spaces with hyphens
    .replace(/-+/g, '-')            // Replace multiple hyphens with single
    .replace(/^-+|-+$/g, '');       // Remove leading/trailing hyphens
};

    const processContent = (content) => {
      if (!content) return '';
      if (content.includes('<blockquote>')) {
        return `<div class="faq-container">
                  <h5 class="faq-title">FAQs</h5>
                  ${content.replace(/<blockquote>/g, '<blockquote>')}
                </div>`;
      }
      return content;
    };

    const getFileUrl = (filename) => {
      if (!filename) return '';
      const baseUrl = `${BASE_URL}/file`;
      const encodedFilename = encodeURIComponent(filename.split('/').pop());
      return `${baseUrl}/${encodedFilename}`;
    };

     const fetchArticle = async () => {
  const currentSlug = route.params.slug;
  if (!currentSlug) return;

  loading.value = true;
  error.value = null;

  try {
    const response = await axios.get(`${BASE_URL}/TampilanArtikel`);
    
    if (response.data?.success && response.data?.data) {
      const articles = response.data.data;
      
      // Find matching article
      const matchingArticle = articles.find(art => {
        const slugEn = createSlug(art.judul_en);
        const slugId = createSlug(art.judul_id);
        return slugEn === currentSlug || slugId === currentSlug;
      });

      if (matchingArticle) {
        article.value = matchingArticle;
        // Call fetchRelatedArticles after setting the current article
        await fetchRelatedArticles(matchingArticle.id_artikel);
      } else {
        console.error('Article not found for slug:', currentSlug);
        error.value = 'Article not found';
      }
    }
  } catch (err) {
    console.error('Error fetching article:', err);
    error.value = 'Failed to load article';
  } finally {
    loading.value = false;
  }
};

const fetchRelatedArticles = async (currentArticleId) => {
  if (!currentArticleId) return;
  
  try {
    const response = await axios.get(`${BASE_URL}/TampilanArtikel`);
    if (response.data?.success && response.data?.data) {
      // Filter out current article and get only 3 related articles
      relatedArticles.value = response.data.data
        .filter(post => post.id_artikel !== currentArticleId)
        .slice(0, 3)
        .map(post => ({
          ...post,
          slug: createSlug(currentLanguage.value === 'en' ? post.judul_en : post.judul_id)
        }));
    }
  } catch (err) {
    console.error('Error fetching related articles:', err);
    relatedArticles.value = [];
  }
};

    const hasReferences = computed(() => {
      return article.value && (
        article.value.link_ref1 ||
        article.value.link_ref2 ||
        article.value.link_ref3 ||
        article.value.link_ref4
      );
    });

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    // Language change handler
    watch(currentLanguage, () => {
  if (article.value) {
    const newSlug = createSlug(
      currentLanguage.value === 'en' ? 
      article.value.judul_en : 
      article.value.judul_id
    );
    router.replace(`/DetailBlog/${newSlug}`);
  }
});
  
      watch(locale, (newLocale) => {
      if (article.value) {
        const newSlug = createSlug(
          newLocale === 'en' ? article.value.judul_en : article.value.judul_id
        );
        // Update URL without query parameters
        router.replace({
          name: 'DetailBlog',
          params: { slug: newSlug }
        });
      }
    });

    // Route change handler
    watch(() => route.params.slug, (newSlug) => {
  if (newSlug) {
    fetchArticle();
    scrollToTop();
  }
});

    // Article change handler
    watch(article, (newArticle) => {
      if (newArticle) {
        const context = {
          $i18n: { locale: currentLanguage.value },
          article: newArticle,
          createSlug
        };
        metaMixin.methods.updateMetaForDetailBlog.call(context);
      }
    }, { immediate: true });

    onMounted(() => {
      fetchArticle();
    });

    return {
      article,
      relatedArticles,
      loading,
      error,
      getFileUrl,
      hasReferences,
      scrollToTop,
      currentLanguage,
      createSlug,
      processContent
    };
  }
};
</script>

<!-- Keep your existing styles -->

<style>
@import url('https://fonts.googleapis.com/css?family=Oswald:700&display=swap');
@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700|Roboto:100,500,300,400,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


[v-cloak] {
display: none;
}

.justify-text {
  text-align: justify;
}

.justify-text a{
  color: #DFA419;
}


.faq-content {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

/* Golden container for FAQs */
.faq-container {
  background: #C17F02;
  padding: 30px;
  margin: 20px 40px;
  border-radius: 8px;
}

/* FAQ Title styling - now inside container */
.faq-container .faq-title {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  color: #FFFFFF;
  margin: 0 0 0 30px;
  padding: 0;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 15px;
}

/* Blockquote styling */
.blog-details-text .faq-container blockquote {
  margin: 0 0 20px;

}

/* Remove margin from last blockquote */
.blog-details-text .faq-container blockquote:last-child {
  margin-bottom: 0;
}

/* Question styling */
.blog-details-text .faq-container blockquote p:first-child {
  font-weight: 600;
  color: #FFFFFF;
  font-size: 16px;
  padding: 0;
  line-height: 1.4;
}

/* Answer styling */
.blog-details-text .faq-container blockquote p:last-child {
  color: #FFFFFF;
  font-size: 14px;
  line-height: 1.6;
  padding: 0;
  opacity: 0.9;
}

/* Spacing between FAQ items */
.blog-details-text .faq-container blockquote + blockquote {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}

/* Text content before FAQs */
.blog-details-text p:not(.faq-container p) {
  font-size: 16px;
  line-height: 1.6;
  color: #000000;
}

/* Responsive styles */
@media (max-width: 1075px) {
  .faq-container {
    margin: 15px 20px;
    padding: 25px;
  }

  .faq-container .faq-title {
    font-size: 18px;
    margin-bottom: 20px;
  }

  .blog-details-text .faq-container blockquote p:first-child {
    font-size: 14px;
  }

  .blog-details-text .faq-container blockquote p:last-child {
    font-size: 13px;
  }
}

@media (max-width: 765px) {
  .faq-container {
    margin: 12px 15px;
    padding: 20px;
  }

  .faq-container .faq-title {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .blog-details-text .faq-container blockquote p:first-child {
    font-size: 13px;
  }

  .blog-details-text .faq-container blockquote p:last-child {
    font-size: 12px;
  }

  .blog-details-text .faq-container blockquote + blockquote {
    margin-top: 20px;
    padding-top: 20px;
  }
}


.filter-blog-section {
  margin: -70px 200px -50px; /* Adjust the margins as needed */
  background: linear-gradient(to right, #fff, #fff); /* The gradient background */
  padding: 20px 0; /* Increased padding top for moving text down */
  position: relative; /* For pseudo-element positioning */
}

.filter-blog-label {
  font-size: 36px;
  font-weight: bold; /* Bold text */
  font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
  text-align: center; /* Center aligns all text within the blog details */
max-width: 3000px; /* Sets the maximum width of the text block */
margin-bottom: 10px;
color: #000000;
}

.filter-blog-label h2 {
  color: #000000;
  margin-top: 25px;
font-size: 65px;
font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
font-weight: 2000px;
}

.filter-blog-label h3 {
  color: #000000;
font-size: 48px;
font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
font-weight: 2000px;
padding-bottom: 25px;
}



.container-blog {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 100px; /* Corrected typo "margin-button" to "margin-bottom" */
}

.card-blog {
  margin: 10px;
  width: 300px; /* Set a specific width */
  height: 500px; /* Set a specific height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  position: relative; /* Needed for absolute positioning of content */
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
}

.blog-img {
  width: 100%; /* Ensures the blog image container fills the card */
  height: 110%; /* Ensures the blog image container fills the card */
  position: relative;
  overflow: hidden; /* Ensures no spill outside the boundary */
}

.card-image-blog {
  width: 100%;
  height: 100%; /* Adjusted to ensure the image covers the whole area */
  object-fit: cover; /* Ensures the image covers the area without distorting */
  position: absolute; /* Ensures the image is positioned absolutely within .blog-img */
}

.card-blog-content {
  position: absolute;
  bottom: 50px; /* Positions the content at the bottom */
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background for readability */
  color: white;
  padding: 20px; /* Padding inside the content area */
  text-align: center; /* Centers the text */
  font-size: 14px; /* Adjusted default font size for smaller text */
}

.card-blog-content h2 {
  font-size: 18px; /* Smaller font size for headlines */
  margin-bottom: 10px; /* Space below the headline */
}

.card-blog-content h3 {
  font-size: 18px; /* Smaller font size for headlines */
  margin-bottom: 10px; /* Space below the headline */
}

.card-blog-content p {
  font-size: 12px; /* Smaller font size for paragraph text */
}

button {
  background-color: #FFCA2C; /* Gold color */
  color: #0D0D0D; /* Dark grey text */
  border: none;
  padding: 1px 5px; /* Adjusted padding to match the example */
  border-radius: 20px; /* Rounded corners */
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 9px; /* Adjusted font size */
  outline: none; /* Removes the outline */
  position: relative; /* Needed for pseudo-elements */
  overflow: hidden; /* Ensures no pseudo-elements spill out */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

button::before {
  content: '>'; /* The arrow symbol */
  font-size: 20px; /* Larger font size for the arrow */
  color: #0D0D0D; /* Dark grey arrow */
  position: absolute;
  right: -30px; /* Starts outside the button */
  top: 50%; /* Centers vertically */
  transform: translateY(-50%); /* Aligns arrow to the middle of the button */
  transition: right 0.3s; /* Smooth transition for hover effects */
}

button:hover {
  background-color: #E5A00D; /* Slightly darker shade for hover state */
}

.interesting h2 {
  margin-left: 300px;
  color:#000000;
  font-size: 18px; /* Sets the font size for the section */
  font-weight: bold; /* Makes the font bold */
}

.gambarnews {
  width: 900px;
  margin-top: 50px;
  text-align: center;
  align-items: center;
  margin-left: 150px;
}

.event-links {
  text-align: center;
  align-items: center;
  color: #DFA419;
  margin-bottom: 10PX;

}

.event-link {
  color: #DFA419; /* White text for links */
  text-decoration: none; /* No underline */
  padding: 3px 10px; /* Padding around links */
  text-align: center; /* Center the text */
  align-items: center;
  font-size: 12px;
  margin: 0 10px; /* Space between links */
  border: 2px solid #DFA419;
  background-color: #ffffff; /* Darker background for links */
  border-radius: 20px; /* Rounded corners */
  transition: background-color 0.3s; /* Smooth transition for hover */
}

.event-link.active, .event-link:hover {
 color: #FFCA2C;
}

.ref-details-text {
 margin-left: 200px;
} 

.ref-details-text h5{
  font-size: 30px;
  font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
  font-weight: bold;
  color: #000000;
} 

.ref-details-text a{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #000000;
} 

.ref-details-text:hover a{
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #FFCA2E;;
} 


.read-more-button-blog {
      margin-bottom: 0px;
      padding: 5px 30px;
      font-size: 9px;
}

.blog-details-text p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #000000
}

.blog-details-text li {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #000000
}

.blog-details-text h4 {
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-size: 35px;
  color: #DFA419;
}

.blog-details-text h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 25px;
  color: #000000;
}
  
  

@media (max-width: 1075px) {
  .filter-blog-section {
      padding: 20px 0;
      margin: -70px 17px -50px; /* Adjust the margins as needed */
  }
  
  .filter-blog-label h2 {
      font-size: 25px;
  }
  
  .container-blog {
      display: flex;
      justify-content: space-around; /* Better spacing on tablets */
  }
  
  .card-blog {
      width: 270px; /* Fixed width on tablets and above */
      height: 400px; /* Fixed height */
  }

  .blog-details-text article {
  padding: 10px 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}
  
.blog-details-text p {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}
  
  .interesting h2 {
      text-align: left;
      margin-left: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color: #000000;
      font-size: 16px;
  }
  
  .gambarnews {
      width: 750px;
      margin-left: 50px;
  }


.card-blog-content h2 {
  font-size: 12px;
  margin-bottom: 16px;
}

.card-blog-content h3 {
  font-size: 12px;
  margin-bottom: 16px;
}

.card-blog-content {
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  padding: 0px;
  text-align: center;
  font-size: 14px;
}

.read-more-button-blog {
      margin-bottom: 0px;
      padding: 5px 30px;
      font-size: 9px;

}

.ref-details-text {
 margin-left: 0px;
} 

.ref-details-text h5{
  font-size: 25px;
  font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
  font-weight: bold;
  color: #000000;
} 

.ref-details-text a{
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #000000;
} 

.ref-details-text:hover a{
  color: #FFCA2E;;
} 



}



@media (max-width: 765px) {

  .saasio-breadcurmb-section-detailblog {
  padding: 100px 0px -1px;
}

  .filter-blog-section {
      padding: 20px 0;
      margin: -70px 17px -50px; /* Adjust the margins as needed */
  }
  
  .filter-blog-label h2 {
      text-align: center;
      font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
      font-weight: bold;
      color: #000000;
      font-size: 20px;
  }
  
  .filter-blog-label h3 {
      text-align: center;
      font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
      font-weight: bold;
      color: #000000;
      font-size: 35px;
  }
  
  .container-blog {
      display: flex;
      justify-content: space-around;
  }

  .card-blog {
      width: 150px; /* Fixed width on tablets and above */
      height: 220px;
  }

  .blog-details-text article {
  padding: 20px 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}

.blog-details-text p {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}
  

  .interesting h2 {
     margin-left: 14px;
      font-size: 14px;
  }
  
  .gambarnews {
    width: 700px;
    margin-left: -20px
  }


.card-blog-content h2 {
  font-size: 10px;
  margin-bottom: 5px;
}

.card-blog-content h3 {
  font-size: 9px;
  margin-bottom: 10px;
}

.card-blog-content {
  position: absolute;
      bottom: 10px;
      left: 0;
      right: 0;
      padding: 0px;
      text-align: center;
      font-size: 10px;
}

.read-more-button-blog {
  margin-bottom: 10px;
      padding: 0px 15px;
      font-size: 10px;

}

.ref-details-text {
 margin-left: 0px;
} 

.ref-details-text h5{
  font-size: 20px;
  font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
  font-weight: bold;
  color: #000000;
} 

.ref-details-text a{
  font-size: 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #000000;
} 

.ref-details-text:hover a{
  color: #FFCA2E;;
} 

.event-links {
  display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      padding: 10px 0;
      align-content: center;
      justify-content: center;
}

  .event-link {
      padding: 4px 2px;
      font-size: 8px;
      margin: 5px;
      border-radius: 25px;
  }

  .blog-tambahan {
      margin-bottom: 150px;
  }
}

.blog-details-text {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Text content styles */
.blog-details-text p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #000000;
  margin: 0 40px;  /* Menjorok ke dalam 40px */
  text-align: justify;
}

/* Image styles inside content */
.blog-details-text img {
  width: calc(100% + 80px); /* Lebih lebar 80px dari konten teks */
  max-width: none;
  margin-left: -40px; /* Geser ke kiri untuk mengimbangi margin teks */
  margin-right: -40px;
  height: auto;
  display: block;
}

/* Figure styles for images with captions */
.blog-details-text figure {
  width: calc(100% + 80px); /* Sama dengan lebar gambar */
  margin: 20px -40px; /* Margin negatif untuk extend keluar */
  padding: 0;
}

.blog-details-text figure img {
  width: 100%;
  margin: 0; /* Reset margin karena sudah dihandle oleh figure */
}

.blog-details-text figcaption {
  text-align: center;
  padding: 10px;
  color: #666;
  font-size: 14px;
}

/* List styles */
.blog-details-text ul,
.blog-details-text ol {
  margin: 0 60px; /* Menjorok lebih dalam dari teks biasa */
}

.blog-details-text li {
  margin-bottom: 8px;
}

/* Heading styles */
.blog-details-text h3 {
  margin: 0 40px; /* Sama dengan indent teks */
}

.blog-details-text h4 {
  margin: 0 40px; /* Sama dengan indent teks */
}

/* Responsive adjustments */
@media (max-width: 1075px) {
  .blog-details-text img,
  .blog-details-text figure {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }

  .blog-details-text p,
  .blog-details-text h3,
  .blog-details-text h4 {
    margin: 0 20px;
    font-size: 12px;
  }

  .blog-details-text ul,
  .blog-details-text ol {
    margin: 0 40px;
  }
}

@media (max-width: 765px) {
  .blog-details-text img,
  .blog-details-text figure {
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;
  }

  .blog-details-text p,
  .blog-details-text h3,
  .blog-details-text h4 {
    margin: 0 10px;
    font-size: 12px;
  }

  .blog-details-text ul,
  .blog-details-text ol {
    margin: 0 30px;
  }
}
</style>
