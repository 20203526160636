<template>
  <HeaderCom />

  
  <div class="breadcurmb-title-sample">
              <h1>{{ $t('Sampel.subtitle') }}</h1>
                <p>{{ $t('Sampel.texttitle') }}</p>
            </div>

  <section id="timeline">
    <article v-for="(item, index) in timelineItems" :key="index">
      <div class="inner">
        <span class="date">
          <span class="month">{{ index + 1 }}</span>
        </span>
        <p>{{ $t(`Sampel.timeline${index + 1}`) }}</p>
      </div>
    </article>
  </section>

  <section id="sa-contact-inner" class="sa-contact-inner-section inner-page-padding">
    <div class="container">
      <div class="saas_two_section_title saas2-headline text-left">
        <h2>{{ $t('Sampel.title') }}</h2>
      </div>
      <div class="sa-contact-inner-form">
        <form @submit.prevent="submitForm">
          <div class="row">
            <div class="col-md-6">
              <label for="name">Name*</label>
              <input type="text" id="name" v-model="formData.name" required placeholder="Enter your name">
            </div>
            <div class="col-md-6">
              <label for="email">Email Address*</label>
              <input type="email" id="email" v-model="formData.email" required placeholder="Enter your email address">
            </div>
            <div class="col-md-6">
              <label for="country">Country*</label>
              <input
                type="text"
                id="country"
                v-model="searchTerm"
                :placeholder="formData.country || 'Select your country'"
                @input="searchCountries"
                class="country-input"
              />
              <ul v-if="filteredCountries.length" class="country-list">
                <li
                  v-for="country in filteredCountries"
                  :key="country.name"
                  :class="{ 'selected-country': country.name === formData.country }"
                  @click="selectCountry(country.name)"
                >
                  {{ country.name }}
                </li>
              </ul>
            </div>
            <div class="col-md-6">
              <label for="phone">Phone*</label>
              <input type="tel" id="phone" v-model="formData.phone" required placeholder="Enter your phone number">
            </div>
            <div class="col-md-6">
              <label for="company">Company*</label>
              <input type="text" id="company" v-model="formData.company" required placeholder="Enter your company">
            </div>
            <div class="col-md-6">
              <label for="jobTitle">Job Title*</label>
              <select id="jobTitle" v-model="formData.jobTitle" required @change="handleJobTitleChange">
                <option value="" disabled selected>Select your job title</option>
                <option v-for="title in jobTitles" :key="title" :value="title">{{ title }}</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div class="col-md-6" v-if="formData.jobTitle === 'Other'">
              <label for="customJobTitle">Custom Job Title*</label>
              <input type="text" id="customJobTitle" v-model="formData.customJobTitle" required placeholder="Enter your custom job title">
            </div>
            <div class="col-md-6">
              <label for="address">Address (Sample Recipient)*</label>
              <textarea id="address" v-model="formData.address" required placeholder="Enter your address"></textarea>
            </div>
          </div>

          <div class="button-group">
            <h4><strong>Please choose the product category (1 or More):</strong></h4>
            <br>
            <button
              v-for="category in categories"
              :key="category.id"
              type="button"
              class="form-button"
              :class="{'active': category.selected}"
              @click="toggleCategory(category.id)"
            >
              {{ category.name }}
            </button>
          </div>

          <div v-if="selectedCategories.length > 0" class="selected-categories">
            <div v-for="category in selectedCategories" :key="category.id" class="category-image">
              <img :src="category.imageSrc" :alt="category.name" />
            </div>
          </div>

          <div class="col-md-12">
            <label for="note">Note</label>
            <textarea id="note" v-model="formData.note" placeholder="Add your note"></textarea>
          </div>

          <div class="additional-info">
            <ul>
              <li><strong>•</strong> After submitting your request, our team will contact you soon to confirm <strong>the sample recipient details, address, and provide the estimated lead time (STD & ETA).</strong></li>
              <li><strong>• Please respond promptly to avoid delays.</strong> Late responses may result in longer lead times for receiving your sample.</li>
              <li><strong>•</strong> Standard sample lead time is approximately <strong>14 days (STD).</strong> Customized samples may vary depending on the complexity of the request.</li>
            </ul>
          </div>

          <div ref="recaptchaContainer"></div>

          <div class="save-inner-btn text-left">
            <button type="submit" :disabled="isSubmitting">
              {{ isSubmitting ? 'Submitting...' : $t('Sampel.submit') + ' >' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </section>

  <CustomAlert
    :show="showAlert"
    :title="alertTitle"
    :message="alertMessage"
    :icon="alertIcon"
    :footer="alertFooter"
    @close="closeAlert"
  />

  <FooterCom />
</template>

<script>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import axios from 'axios';
import HeaderCom from '@/components/HeaderCom';
import FooterCom from '@/components/FooterCom';
import CustomAlert from '@/components/CustomAlert.vue';
import countriesData from '@/data/countries.json';

export default {
  components: {
    HeaderCom,
    FooterCom,
    CustomAlert
  },
  setup() {
    const timelineItems = ref([1, 2, 3, 4]);
    const formData = ref({
      name: '',
      email: '',
      country: '',
      phone: '',
      company: '',
      jobTitle: '',
      customJobTitle: '',
      address: '',
      note: '',
      recaptchaToken: '',
    });
    const searchTerm = ref('');
    const isSubmitting = ref(false);
    const recaptchaContainer = ref(null);
    const recaptchaInstance = ref(null);
    const recaptchaWidgetId = ref(null);

    const showAlert = ref(false);
    const alertTitle = ref('');
    const alertMessage = ref('');
    const alertIcon = ref('');
    const alertFooter = ref('');

    const categories = ref([
      { id: 'cp', name: 'CIGARETTE PAPER', selected: false, imageSrc: 'assets/img/business-4/sampel/cp.webp' },
      { id: 'ctp', name: 'TIPPING PAPER', selected: false, imageSrc: 'assets/img/business-4/sampel/ctp.webp' },
      { id: 'etiket', name: 'CIGARETTE PACK', selected: false, imageSrc: 'assets/img/business-4/sampel/etiket.webp' },
      { id: 'fp', name: 'FILTER TIPS', selected: false, imageSrc: 'assets/img/business-4/sampel/filter.webp' },
      { id: 'pr', name: 'PRE-ROLLED CONES', selected: false, imageSrc: 'assets/img/business-4/sampel/cones.webp' },
    ]);

    const jobTitles = [
      'Founder/Owner',
      'CEO/Director/Manager',
      'Purchasing/Procurement',
      'Quality Control',
      'Product Development/R&D',
      'Sales/Marketing',
      'Supply Chain/Logistics'
    ];

    const filteredCountries = computed(() => {
      if (!searchTerm.value) return [];
      return countriesData.filter(country => 
        country.name.toLowerCase().includes(searchTerm.value.toLowerCase())
      );
    });

    const selectedCategories = computed(() => {
      return categories.value.filter(cat => cat.selected);
    });

    const toggleCategory = (id) => {
      const category = categories.value.find(cat => cat.id === id);
      if (category) {
        category.selected = !category.selected;
      }
    };

    const handleJobTitleChange = () => {
      if (formData.value.jobTitle !== 'Other') {
        formData.value.customJobTitle = '';
      }
    };

    const selectCountry = (countryName) => {
      formData.value.country = countryName;
      searchTerm.value = '';
    };

    const initRecaptcha = () => {
      if (window.grecaptcha && window.grecaptcha.render && !recaptchaWidgetId.value) {
        recaptchaWidgetId.value = window.grecaptcha.render(recaptchaContainer.value, {
          sitekey: '6LfyiR8qAAAAAE1pz6YwVbWzRDw1byHQru7v8EAN',
          callback: onRecaptchaVerified
        });
        recaptchaInstance.value = window.grecaptcha;
      }
    };

    const loadRecaptchaScript = () => {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
        script.async = true;
        script.defer = true;
        script.onload = resolve;
        document.head.appendChild(script);
      });
    };

    const onRecaptchaVerified = (token) => {
      formData.value.recaptchaToken = token;
    };

    const resetRecaptcha = () => {
      if (recaptchaInstance.value && recaptchaWidgetId.value !== null) {
        recaptchaInstance.value.reset(recaptchaWidgetId.value);
        formData.value.recaptchaToken = '';
      }
    };

    const submitForm = async () => {
      if (selectedCategories.value.length === 0) {
        showAlert.value = true;
        alertTitle.value = 'Oops...';
        alertMessage.value = 'Please select at least one category.';
        alertIcon.value = 'error';
        return;
      }

      if (!formData.value.recaptchaToken) {
        showAlert.value = true;
        alertTitle.value = 'Oops...';
        alertMessage.value = 'Please complete the reCAPTCHA verification.';
        alertIcon.value = 'error';
        return;
      }

      isSubmitting.value = true;

      try {
        const response = await axios.post('https://puracigarettesolutions.com:3000/api/emailctp/send-email', {
          ...formData.value,
          id_kategori: selectedCategories.value.map(cat => cat.id).join(','),
          selected_categories: selectedCategories.value.map(cat => cat.name)
        });

        if (response.status === 200) {
          showAlert.value = true;
          alertTitle.value = 'Thank You!';
          alertMessage.value = 'Your request has been submitted successfully!';
          alertIcon.value = 'success';
          resetForm();
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        showAlert.value = true;
        alertTitle.value = 'Oops...';
        alertMessage.value = 'Something went wrong while submitting the form!';
        alertIcon.value = 'error';
        alertFooter.value = 'Why do I have this issue?';
      } finally {
        isSubmitting.value = false;
        resetRecaptcha();
      }
    };

    const resetForm = () => {
      Object.keys(formData.value).forEach(key => {
        formData.value[key] = '';
      });
      categories.value.forEach(cat => cat.selected = false);
      searchTerm.value = '';
    };

    const closeAlert = () => {
      showAlert.value = false;
    };

    onMounted(async () => {
      if (!window.grecaptcha) {
        await loadRecaptchaScript();
      }
      window.grecaptcha.ready(initRecaptcha);
    });

    onBeforeUnmount(() => {
      if (recaptchaContainer.value) {
        recaptchaContainer.value.innerHTML = '';
      }
      recaptchaWidgetId.value = null;
      recaptchaInstance.value = null;
    });

    return {
      timelineItems,
      formData,
      searchTerm,
      categories,
      jobTitles,
      isSubmitting,
      showAlert,
      alertTitle,
      alertMessage,
      alertIcon,
      alertFooter,
      selectedCategories,
      filteredCountries,
      recaptchaContainer,
      toggleCategory,
      selectCountry,
      handleJobTitleChange,
      submitForm,
      closeAlert,
    };
  },
};
</script>


<style>

@import url('https://fonts.googleapis.com/css?family=Oswald:700&display=swap');


/* .breadcurmb-title-sample p {
    position: relative;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 120px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000000;
} */


.saas_two_section_title h2 {
  font-size: 20px; /* Tingkatkan ukuran font untuk visibilitas */
    font-weight: bold;
    font-family: 'Oswald', sans-serif;
    color: #000000; /* Memastikan teks terlihat pada latar belakang gelap */
    margin-bottom: 5px; /* Ruang antara teks dan tombol */
}

.ctp_custom {
  color: #6c757d;
}

.button-group {
  margin-bottom: 20px;
  margin-top: 5px;
}


.form-button {
    background-color: #ffc107;
    color: rgb(255, 255, 255);
    border: 2px solid #ffc107; 
    margin: 0 10px;
    border-radius: 20px; /* Membuat tombol lebih oval */
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    font-size: 16px;
    font-weight: bold;
}

.form-button.active {
    background-color: #dcb83d;
    color: rgb(255, 255, 255);
}

.form-button:not(.active):hover {
    background-color: #ffc107;
}

.button-group {
  margin-bottom: 5px;
}

.form-container {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.data-message {
    margin-top: 10px; /* Space above the message */
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2); /* Default background */
    color: white; /* Default text color */
}

.data-success-message {
    background-color: rgba(0, 128, 0, 0.85);
    color: white;
}

.data-error-message {
    background-color: rgba(255, 0, 0, 0.85); /* Red background for error */
    color: white;
}

.align-center {
    text-align: center;
    margin-top: 50px;
    margin-left: 150px;
    font-family: 'Montserrat', sans-serif;
}

.apbc-team-single .img-wrapper img {
    width: auto; /* Adjust width as necessary, could be in percentage or pixels */
    height: auto; /* Adjust height as necessary */
    max-width: 500px; /* Example maximum width */
    max-height: 500px; /* Example maximum height */
    display: block; /* Ensures the image is block level */
    margin: 0 auto; /* Centers the image if it's smaller than the container */
    
}

.apbc-team-single .img-wrapper img:hover {
  transform: scale(1.1); /* Membesarkan gambar saat hover */
    transition: transform 0.3s ease-in-out; /* Membuat transisi menjadi halus */
    box-shadow: 0 8px 12px rgba(0,0,0,0.2); /* Menambahkan bayangan untuk efek yang lebih menonjol */
}

.checkbox {
  transform: scale(0.5); /* Adjusting size */
  float: center; /* Positioning to the left */
  margin-right: 5px; /* Adding margin to the right */
}

/* Updated CSS targeting checkboxes directly */
input[type="checkbox"] {
  transform: scale(1.5); /* Increase the scale value as needed */
  float: center; /* Positioning to the left */
  margin-right: 5px; /* Adding margin to the right */
  /* Additional styles (if needed) */
}

input[type="checkbox"] {
  width: 15px; /* Set a specific width */
  height: 15px; /* Set a specific height */
  float: center; /* Positioning to the left */
  margin-right: 5px; /* Adding margin to the right */
  /* Additional styles (if needed) */
}


.checkbox-label-group {
  display: flex;
  margin-top: 15px;
  align-items: center;
  color: black;
}

.checkbox-label-group label {
  margin-left: 5px; /* Adjust as needed */
  /* Additional label styling if needed */
  color: black;
}

.pillbox-container {
  display: flex;
  flex-wrap: wrap;
}

.pill {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 15px; /* Rounded corners for pill shape */
  margin-right: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  user-select: none; /* Prevent text selection */
}

.pill.selected {
  background-color: #dcb83d;
  color: white;
  border-color: #dcb83d;
}

.form-container label {
    font-family: 'Montserrat', sans-serif;
}

.image-gallery {
  display: flex;
  position: relative;
  justify-content: space-around; /* Menyusun gambar dalam satu baris */
  flex-wrap: wrap; /* Izinkan wrap jika lebar layar terlalu sempit */
  margin: 10px 0; /* Tambahkan margin atas dan bawah */
  margin-bottom: 50px;
}

.image-ctp {
  display: flex;
  position: relative;
  justify-content: space-around; /* Menyusun gambar dalam satu baris */
  flex-wrap: wrap; /* Izinkan wrap jika lebar layar terlalu sempit */
  margin: 5px 0; /* Tambahkan margin atas dan bawah */
  margin-bottom: 20px;
}


.image-ctpi {
  flex-basis: 35%; /* Setiap gambar mengambil ~22% dari lebar container */
}


.image-item {
  flex-basis: 40%; /* Setiap gambar mengambil ~22% dari lebar container */
  border: 2px solid #dcb83d; /* Border biru */
  margin-bottom: 25px;
  padding: 10px; /* Padding di dalam border */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Bayangan ringan */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Transisi halus untuk transform dan shadow */
  overflow: hidden; /* Mencegah konten keluar dari batas saat membesar */
}

.image-item img {
  width: 100%; /* Lebar gambar menyesuaikan dengan container */
  height: auto; /* Tinggi gambar otomatis sesuai rasio aspek */
  transition: transform 0.3s ease; /* Transisi halus untuk transform */
}


.image-item:hover {
  transform: scale(1.05); /* Membesarkan gambar saat hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Membuat shadow lebih besar saat hover */
}

.image-item:hover img {
  transform: scale(1.1); /* Membesarkan gambar saat hover */
}

.image-label {
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  color: #333; /* Adjust the color as needed */
  font-weight: bold;
}

.show-modal-label {
  cursor: pointer;
  margin-left: 10px;
  color: #007bff; /* Warna biru, sesuaikan dengan tema Anda */
  font-weight: bold;
}

.checkbox-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px; /* Menyesuaikan berdasarkan kebutuhan */
}

.checkbox-group {
  flex-basis: 48%; /* Atau sesuaikan persentase untuk kontrol lebih pada lebar */
  /* Menambahkan margin/padding sesuai kebutuhan */
}

.checkbox-label-group label {
  margin-left: 8px; /* Atur sesuai kebutuhan */
}

input[type="checkbox"] {
  margin-right: 8px; /* Atur sesuai kebutuhan */
}


/* Styles for mobile screens */
@media screen and (max-width: 767px) {
    .image-item {
      flex-basis: 100%; /* Satu item per baris pada layar kecil */
      margin-top: 20px;
    }

    .button-group {
      flex-direction: column; /* Tombol dalam satu kolom pada layar kecil */
      margin-left: 70px;
    }

    .button-group h4{
    margin-right: 25px;
}

    .form-button {
      margin: 10px 0; /* Berikan margin pada tombol */
    }

    .apbc-team-single .img-wrapper img {
      max-width: 100%; /* Gambar mengisi lebar container pada layar kecil */
    }

    .align-center {
      text-align: center; /* Tengahkan teks pada layar kecil */
    }

    .checkbox-label-group {
      margin-left: 0; /* Setel margin menjadi 0 pada layar kecil */
    }

    input[type="checkbox"] {
      margin: 0; /* Setel margin menjadi 0 pada layar kecil */
    }

    .image-gallery {
      margin: 10px 0; /* Tambahkan margin pada galeri gambar pada layar kecil */
    }

    .cta-text {
      margin-top: 20px; /* Berikan ruang di atas teks panggilan aksi */
    }

    .image-ctpi img {
      width: 100%; /* Gambar akan mengisi lebar 100% dari parent container */
    }
  }

  .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  position: relative;
  max-width: 500px; /* Adjust based on your content size */
}

.close-button {
  position: absolute;
  top: 50px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}

section#timeline {
  margin-top: 250px;
  display: flex;
  overflow-x: auto;
  padding: 5px 20px;
  position: relative;
  
  /* Add these properties to center the section */
  max-width: 1200px; /* or any suitable max-width */
  margin-left: auto;
  margin-right: auto;
}

section#timeline:before {
  content: '';
    display: block;
    position: absolute;
    top: 32%;
    left: 120px;
    width: 73%;
    height: 3px;
    background: #FFCA2E;
    z-index: 0;
}

section#timeline article {
  position: relative;
  margin-right: 0px; /* Space between articles */
  margin-top: 100px;
  min-width: 200px; /* Minimum width for each article */
  z-index: 1;
}

section#timeline article:last-child {
  margin-right: 0; /* Remove margin for the last article */

}

section#timeline article div.inner {
  padding: 0 10px;
  
}

section#timeline article div.inner span.date {
  display: block;
  width: 30px;
  height: 30px;
  padding: 5px 0;
  position: absolute;
  top: -65px;
  left: 50%;
  margin: 0 0 0 -32px;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  background: #FFCA2E;
  color: rgb(255, 255, 255);
  border: 2px solid #FFCA2E;
  box-shadow: 0 0 0 7px #FFCA2E;
}
section#timeline article div.inner span.date span {
  display: block;
  text-align: center;
}

section#timeline article div.inner span.date span.month {
  font-size: 25px;
    margin-top: -10px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

section#timeline article div.inner p {
  padding: 15px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  font-size: 12px;
  color: #000000;
  border: 3px solid #FFCA2E;
  border-radius: 10px 10px;
}

@media (min-width: 1921px) {

  section#timeline:before {
    content: "";
    display: block;
    position: absolute;
    top: 34%;
    left: 120px;
    width: 67%;
    height: 3px;
    background: #ffca2e;
    z-index: 0;
}

  
}


@media (max-width: 1224px) and (min-width: 992px) {
  .breadcurmb-title-sample h1{
    position: absolute;
        width: 80%;
        top: 90px;
        color: #000000;
        bottom: 70%;
        text-align: center;
        font-family: 'Oswald', sans-serif;
        font-weight: bold;
        font-size: 35px;
}

.breadcurmb-title-sample p {
    position: relative;
    width: 50%;
    top: 150px;
    bottom: 80%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000000;
}


  section#timeline {
  margin-top: 140px;
  display: flex; /* Align articles horizontally */
  overflow-x: auto; /* Allow horizontal scrolling */
  padding: 5px 20px;
  position: relative;
}

section#timeline:before {
        content: '';
        display: block;
        position: absolute;
        top: 35%;
        left: 80px;
        width: 77%;
        height: 3px;
        background: #FFCA2E;
        z-index: 0;
    }

section#timeline article {
  position: relative;
  margin-right: 0px; /* Space between articles */
  margin-top: 100px;
  min-width: 160px; /* Minimum width for each article */
  z-index: 1;
}

section#timeline article:last-child {
  margin-right: 0; /* Remove margin for the last article */

}

section#timeline article div.inner {
  padding: 0 10px;
  
}

section#timeline article div.inner span.date {
  display: block;
  width: 30px;
  height: 30px;
  padding: 5px 0;
  position: absolute;
  top: -55px;
  left: 50%;
  margin: 0 0 0 -32px;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  background: #FFCA2E;
  color: rgb(255, 255, 255);
  border: 2px solid #FFCA2E;
  box-shadow: 0 0 0 7px #FFCA2E;
}
section#timeline article div.inner span.date span {
  display: block;
  text-align: center;
}

section#timeline article div.inner span.date span.month {
  font-size: 25px;
    margin-top: -10px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

section#timeline article div.inner p {
  padding: 15px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  font-size: 12px;
  color: #000000;
  border: 3px solid #FFCA2E;
  border-radius: 10px 10px;
}
  
}

@media (max-width: 991px) and (min-width: 840px) {
  .breadcurmb-title-sample h1{
    position: absolute;
        width: 80%;
        top: 90px;
        color: #000000;
        bottom: 70%;
        text-align: center;
        font-family: 'Oswald', sans-serif;
        font-weight: bold;
        font-size: 35px;
}

.breadcurmb-title-sample p {
    position: relative;
    width: 50%;
    top: 1550px;
    bottom: 80%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000000;
}


  section#timeline {
    margin-top: 150px;
  display: flex; /* Align articles horizontally */
  overflow-x: auto; /* Allow horizontal scrolling */
  padding: 5px 20px;
  position: relative;
}

section#timeline:before {
        content: '';
        display: block;
        position: absolute;
        top: 34%;
        left: 80px;
        width: 75%;
        height: 3px;
        background: #FFCA2E;
        z-index: 0;
    }

section#timeline article {
  position: relative;
  margin-right: 0px; /* Space between articles */
  margin-top: 100px;
  min-width: 160px; /* Minimum width for each article */
  z-index: 1;
}

section#timeline article:last-child {
  margin-right: 0; /* Remove margin for the last article */

}

section#timeline article div.inner {
  padding: 0 10px;
  
}

section#timeline article div.inner span.date {
  display: block;
  width: 30px;
  height: 30px;
  padding: 5px 0;
  position: absolute;
  top: -55px;
  left: 50%;
  margin: 0 0 0 -32px;
  border-radius: 100%;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  background: #FFCA2E;
  color: rgb(255, 255, 255);
  border: 2px solid #FFCA2E;
  box-shadow: 0 0 0 7px #FFCA2E;
}
section#timeline article div.inner span.date span {
  display: block;
  text-align: center;
}

section#timeline article div.inner span.date span.month {
  font-size: 25px;
    margin-top: -10px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
}

section#timeline article div.inner p {
  padding: 15px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  margin: 0;
  font-size: 12px;
  color: #000000;
  border: 3px solid #FFCA2E;
  border-radius: 10px 10px;
}
  
}

@media (max-width: 839px) and (min-width: 769px) {
  .breadcurmb-title-sample h1{
    position: absolute;
    width: 60%;
    top: 95px;
    left: 30px;
    color: #000000;
    bottom: 70%;
    text-align: center;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    font-size: 35px;
}

  .breadcurmb-title-sample p {
    position: relative;
    width: 65%;
    top: 160px;
    bottom: 80%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000000;
}
  section#timeline {
    margin-top: 100px;
        flex-direction: column; /* Mengubah orientasi menjadi vertikal */
        overflow-x: hidden; /* Menghilangkan kemungkinan scroll horizontal */
        align-items: center; /* Menengahkan artikel secara vertikal */
    }

    section#timeline article div.inner {
      padding: 30px 190px;
    }

    section#timeline:before {
      top: 96px;
        left: 20%;
        width: 2px;
        height: 75%;
        background: #FFCA2E;
    }

    section#timeline article {
        margin-top: 50px; /* Memberikan jarak antar artikel */
        min-width: 100%; /* Artikel mengisi penuh lebar kontainer */
    }

    section#timeline article div.inner span.date {
      position: absolute;
      top: 42px;
      left: calc(50% - 255px);
        width: 30px;
        height: 30px;
        margin: 0;
    }

    section#timeline article div.inner p {
    padding: 15px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-size: 12px;
    color: #000000;
    border: 3px solid #FFCA2E;
    border-radius: 10px 10px;
}

}

.country-input {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #000000;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1);
  transition: border-color 0.3s ease-in-out;
}

.country-input:focus {
  border-color: #007bff;
  color: #000000;
  box-shadow: inset 0 1px 3px rgba(0,0,0,0.1), 0 0 8px rgba(0, 123, 255, 0.6);
}

.country-list {
  list-style: none;
  padding-left: 0;
  margin-top: 2px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  position: absolute;
  width: 100%;
  z-index: 1000;
}

.country-list li {
  padding: 8px 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.country-list li:hover, .country-list li.selected-country {
  background-color: #f8f9fa;
}

.selected-country {
  font-weight: bold;
  color: black;
}


@media (max-width: 768px)  {
  
  section#timeline {
    margin-top: 150px;
        flex-direction: column; /* Mengubah orientasi menjadi vertikal */
        overflow-x: hidden; /* Menghilangkan kemungkinan scroll horizontal */
        align-items: center; /* Menengahkan artikel secara vertikal */
    }

    .breadcurmb-title-sample h1 {
        width: 85%;
        top: 100px;
        font-size: 20px;
        bottom: 70%;
    }

    .breadcurmb-title-sample p {
    position: relative;
    width: 75%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 150px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000000;
}

    section#timeline article div.inner {
      padding: 30px 180px;
    }

    section#timeline:before {
        top: 96px;
        left: 19%;
        width: 2px;
        height: 78%;
        background: #FFCA2E; /* Mengubah warna garis untuk visibilitas yang lebih baik */
    }

    section#timeline article {
        margin-top: 50px; /* Memberikan jarak antar artikel */
        min-width: 100%; /* Artikel mengisi penuh lebar kontainer */
    }

    section#timeline article div.inner span.date {
        position: absolute;
        top: 48px;
        left: calc(50% - 250px);
        width: 30px;
        height: 30px;
        margin: 0;
    }


    section#timeline article div.inner p {
        padding: 15px; /* Memberikan padding yang lebih untuk memudahkan baca */
        text-align: center; /* Teks disetel agar berada di tengah */
    }

}

/* Tablet and mobile phone adjustments */
@media (max-width: 550px)  {
    section#timeline {
      margin-top: 90px;
        flex-direction: column; /* Mengubah orientasi menjadi vertikal */
        overflow-x: hidden; /* Menghilangkan kemungkinan scroll horizontal */
        align-items: center; /* Menengahkan artikel secara vertikal */
    }

    section#timeline article div.inner {
      padding: 30px 75px;
    }

    section#timeline:before {
        top: 96px;
        left: 13%;
        width: 2px;
        height: 78%;
        background: #FFCA2E; /* Mengubah warna garis untuk visibilitas yang lebih baik */
    }

    section#timeline article {
        margin-top: 50px; /* Memberikan jarak antar artikel */
        min-width: 100%; /* Artikel mengisi penuh lebar kontainer */
    }

    section#timeline article div.inner span.date {
        position: absolute;
        top: 48px;
        left: calc(50% - 230px);
        width: 30px;
        height: 30px;
        margin: 0;
    }


    section#timeline article div.inner p {
        padding: 15px; /* Memberikan padding yang lebih untuk memudahkan baca */
        text-align: center; /* Teks disetel agar berada di tengah */
    }
}

@media (max-width: 765px) and (min-width: 660px)  {
    section#timeline {
      margin-top: 90px;
        flex-direction: column; /* Mengubah orientasi menjadi vertikal */
        overflow-x: hidden; /* Menghilangkan kemungkinan scroll horizontal */
        align-items: center; /* Menengahkan artikel secara vertikal */
    }

    section#timeline article div.inner {
      padding: 30px 210px;
    }

    section#timeline:before {
      top: 100px;
      left: 17%;
        width: 2px;
        height: 80%;
        background: #FFCA2E; /* Mengubah warna garis untuk visibilitas yang lebih baik */
    }

    section#timeline article {
        margin-top: 50px; /* Memberikan jarak antar artikel */
        min-width: 100%; /* Artikel mengisi penuh lebar kontainer */
    }

    section#timeline article div.inner span.date {
        position: absolute;
        top: 48px;
        left: calc(50% - 230px);
        width: 30px;
        height: 30px;
        margin: 0;
    }

    section#timeline article div.inner p {
    padding: 15px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-size: 12px;
    color: #000000;
    border: 3px solid #FFCA2E;
    border-radius: 10px 10px;
}
}

@media (max-width: 659px) and (min-width: 541px)  {
    section#timeline {
      margin-top: 90px;
        flex-direction: column; /* Mengubah orientasi menjadi vertikal */
        overflow-x: hidden; /* Menghilangkan kemungkinan scroll horizontal */
        align-items: center; /* Menengahkan artikel secara vertikal */
    }

    section#timeline article div.inner {
      padding: 30px 130px;
    }

    section#timeline:before {
      top: 100px;
      left: 17%;
        width: 2px;
        height: 80%;
        background: #FFCA2E; /* Mengubah warna garis untuk visibilitas yang lebih baik */
    }

    section#timeline article {
        margin-top: 50px; /* Memberikan jarak antar artikel */
        min-width: 100%; /* Artikel mengisi penuh lebar kontainer */
    }

    section#timeline article div.inner span.date {
      position: absolute;
      top: 48px;
      left: calc(50% - 210px);
        width: 30px;
        height: 30px;
        margin: 0;
    }

    section#timeline article div.inner p {
    padding: 15px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-size: 12px;
    color: #000000;
    border: 3px solid #FFCA2E;
    border-radius: 10px 10px;
}
}

@media (max-width: 540px) and (min-width: 481px)  {
    section#timeline {
      margin-top: 110px;
        flex-direction: column; /* Mengubah orientasi menjadi vertikal */
        overflow-x: hidden; /* Menghilangkan kemungkinan scroll horizontal */
        align-items: center; /* Menengahkan artikel secara vertikal */
    }

    .breadcurmb-title-sample p {
        position: relative;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 150px;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        color: #000000;
    }

    .breadcurmb-title-sample h1 {
        width: 85%;
        top: 140px;
        font-size: 20px;
        bottom: 80%;
    }

    section#timeline article div.inner {
      padding: 30px 130px;
    }

    section#timeline:before {
      top: 100px;
      left: 14%;
        width: 2px;
        height: 75%;
        background: #FFCA2E; /* Mengubah warna garis untuk visibilitas yang lebih baik */
    }

    section#timeline article {
        margin-top: 50px; /* Memberikan jarak antar artikel */
        min-width: 100%; /* Artikel mengisi penuh lebar kontainer */
    }

    section#timeline article div.inner span.date {
      position: absolute;
      top: 48px;
      left: calc(50% - 210px);
        width: 30px;
        height: 30px;
        margin: 0;
    }

    section#timeline article div.inner p {
    padding: 15px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin: 0;
    font-size: 12px;
    color: #000000;
    border: 3px solid #FFCA2E;
    border-radius: 10px 10px;
}
}

/* Adjustments for very small mobile phones */
@media (max-width: 480px) and (min-width: 381px)  {
    section#timeline article div.inner p {
        font-size: 12px; /* Mengatur ulang ukuran font untuk visibilitas yang lebih baik */
    }

    .breadcurmb-title-sample h1 {
        width: 85%;
        top: 90px;
        font-size: 20px;
        bottom: 80%;
    }

    .breadcurmb-title-sample p {
        position: relative;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 130px;
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        color: #000000;
    }

    section#timeline:before {
        top: 96px;
        left: 13%;
        width: 2px;
        height: 78%;
        background: #FFCA2E;
    }

    section#timeline {
      margin-top: 60px;
        flex-direction: column;
        overflow-x: hidden;
        align-items: center;
    }

    .inner-page-padding {
    padding: 0px 0px;
}

section#timeline article div.inner span.date {
        position: absolute;
        top: 48px;
        left: calc(50% - 167px);
        width: 30px;
        height: 30px;
        margin: 0;
    }
}

@media (max-width: 380px) {
    section#timeline article div.inner span.date {
        position: absolute;
        top: 48px;
        left: calc(50% - 155px);
        width: 30px;
        height: 30px;
        margin: 0;
    }

    .breadcurmb-title-sample p {
        width: 90%;
        top: 140px;
        font-size: 10px;
        bottom: 80%;
    }
}

@media (max-width: 360px) {
    section#timeline:before {
        top: 96px;
        left: 14%;
        width: 2px;
        height: 77%;
        background: #FFCA2E;
    }

    section#timeline article div.inner span.date {
        position: absolute;
        top: 48px;
        left: calc(50% - 145px);
        width: 30px;
        height: 30px;
        margin: 0;
    }
}



@media (max-width: 768px) {
    .container {
        width: 95%;
    }

    .form-button, .country-input, input[type="text"], input[type="email"], select, textarea {
        width: 100%; /* Make input elements full width */
    }

    .button-group {
        flex-direction: column;
    }

    .form-button {
        margin-bottom: 10px; /* Space between buttons */
    }

    .image-item, .image-ctpi {
        flex-basis: 100%; /* Full width images */
    }

    .modal-content {
        width: 90%; /* Smaller modal on tablet */
    }
}

/* Phones */
@media (max-width: 480px) {
    .container {
        width: 100%;
    }

    .form-button {
        font-size: 14px; /* Smaller text in buttons */
    }

    .country-list {
        display: none; /* Optionally hide country list dropdown in very small screens */
    }

    .image-gallery, .image-ctpi {
        flex-direction: column;
    }
}

.selected-categories {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.category-image {
  width: 200px;
  height: 150px;
  border: 2px solid #ffffff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.category-image img {
  width: 250px;
  height: 150px;
  object-fit: contain;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .category-image {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 480px) {
  .category-image {
    width: 200px;
    height: 200px;
  }
}

.additional-info ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.additional-info li {
  margin-bottom: 10px;
  font-size: 14px;
  color: #000000;
}

@media (max-width: 768px) {
  .country-list {
    max-height: 150px;
  }

  .country-input,
  .country-list li {
    font-size: 16px; /* Prevents zoom on iOS */
    padding: 12px 10px; /* Larger touch target */
  }
}
</style>
