<template>
    <section id="patent" class="apbc-team-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <div class="apbc-title-area text-center wow fadeInUp">
                        <div class="apbc-headline">
                            <h4>{{ $t('patentPage.title') }}</h4>
                            <div class="gold-bar-certif"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="patents-back" ref="patentsRef">
  <div class="our-patents-container">
    <h2 class="section-title"></h2>
    <swiper
    :modules="swiperModules"
    :slides-per-view="slidesPerView"
    :space-between="20"
    :pagination="{ clickable: true }"
    class="patents-swiper"
    @swiper="onSwiper"
  >
    <swiper-slide v-for="(patent, index) in patents" :key="index">
      <div 
        class="patent-card" 
        :class="{ 'highlighted': patent.title === highlightedPatent }"
        @click="navigateToProduct(patent)"
      >
        <div class="patent-content">
          <div class="patent-icon">
            <img src="/assets/img/business-4/icon/clarity.webp" alt="Patent Icon">
          </div>
          <h4 class="patent-title">{{ patent.title }}</h4>
          <p class="patent-id">
            Status: 
            <span :class="getStatusClass(patent.id)">{{ patent.id }}</span>
          </p>
        </div>
        <div class="read-more-wrapper">
          <a href="#" class="read-more-link" @click.prevent="readMore(patent)">Read more</a>
        </div>
      </div>
    </swiper-slide>
  </swiper>
  </div>
</div>


  </template>
  
  <script>
import { ref, onMounted,  onUnmounted, watch, nextTick, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
  
  export default {
    name: 'PatentSection',
    components: {
      Swiper,
      SwiperSlide
    },

    setup() {

        const route = useRoute();
    const router = useRouter();
    const patentsRef = ref(null);
    const highlightedPatent = ref(null);


     
        const patents = ref([
  {
    title: 'Penghambat Nyala Api Pada Kertas Rokok Hingga Padam Sendiri',
    id: 'On Progres',
    productRoute: 'DetailProduk/53'
  },
  {
    title: 'Lapis Ganda Kertas Tipping Dengan Kertas Rokok Untuk Sigaret Kretek Tangan (SKT)',
    id: 'On Progres',
    productRoute: 'DetailProduk/20'
  },
  {
    title: 'Metode Pembuatan Butiran Tembakau Untuk Rokok Elektronik',
    id: 'On Progres',
      productRoute: { 
          name: 'ProdukPage', 
          query: { 
            filter: 'all',
          }
        },
  },
  {
    title: 'Filter Pendingin Rokok Eletronik Dengan Lapisan Micro Cool',
    id: 'On Progres',
    productRoute: { 
          name: 'ProdukPage', 
          query: { 
            filter: 'all',
          }
        },
  },
  {
    title: 'Perforasi Laser CO2 Untuk Kemasan Yang Rapat Dengan atau Tanpa Cetakan',
    id: 'On Progres',
    productRoute: { 
          name: 'ProdukPage', 
          query: { 
            filter: 'FILTER TIPS',
          }
        },
  },
  {
    title: 'Penghalang Tembakau Eksternal Pada Bagian Ujung Hisap Produk Rokok',
    id: 'On Progres',
    productRoute: { 
          name: 'ProdukPage', 
          query: { 
            filter: 'all',
          }
        },
  },

  {
    title: 'Pembungkus Rokok Dari Bahan Alami Untuk Rokok Sigaret Kretek Tangan (SKT)',
    id: 'On Progres',
    productRoute: { 
          name: 'ProdukPage', 
          query: { 
            filter: 'all',
          }
        },
  },

  {
    title: 'Penghalang Tembakau Dari Bahan Alami Dengan Pembungkus Kertas Pada Ujung Hisap Rokok',
    id: 'On Progres',
    productRoute: { 
          name: 'ProdukPage', 
          query: { 
            filter: 'CIGARETTE PAPER',
          }
        },
  },

  {
    title: 'Pengolahan Talas Dengan Sistem Ozonasi Sebagai Bahan Dasar Rokok',
    id: 'On Progres',
    productRoute: {
       name: 'ProdukPage', query: {
         filter: 'all',
 } },
  },

  {
    title: 'Kemasan Rokok Satuan Berbentuk Kubus',
    id: 'On Progres',
    productRoute: { name: 'ProdukPage', query: {
       filter: 'CIGARETTE PACK',
       } },
  },

  {
    title: 'Kantong Irisan Tembakau tiap Linting Pada Rokok Sigaret Kretek Tangan (SKT) Dengan Bahan Metalize',
    id: 'On Progres',
    productRoute: { 
          name: 'ProdukPage', 
          query: { 
            filter: 'all',
           
          }
        },
  },

  {
    title: 'Kertas Rokok dan Kertas Tembakau Dengan Aroma dan Motif',
    id: 'On Progres',
    productRoute: { 
          name: 'ProdukPage', 
          query: { 
            filter: 'CIGARETTE PAPER',
        
          }
        },
  },

  {
    title: 'Penghalang Tembakau Pada Ujung Hisap Rokok SKT Dengan Tambahan Microencapsulasi',
    id: 'On Progres',
    productRoute: { 
          name: 'ProdukPage', 
          query: { 
            filter: 'all',
         
          }
        },
  },

  {
    title: 'Penghalang Tembakau Eksternal Pada Produk Rokok SKT Dengan Tambahan Microencapsulasi',
    id: 'On Progres',
    productRoute: { 
          name: 'ProdukPage', 
          query: { 
            filter: 'all',
       
          }
        },
  },

  {
    title: 'Kertas Tipping Rokok Dengan Rasa, Aroma dan Morif',
    id: 'On Progres',
    productRoute: { 
          name: 'ProdukPage', 
          query: { 
            filter: 'TIPPING PAPER',
          }
        },
  },
  // Add more patents as needed
]);


const windowWidth = ref(window.innerWidth);
    const swiperInstance = ref(null);

    const slidesPerView = computed(() => {
      if (windowWidth.value <= 560) {
        return 2;
      } else if (windowWidth.value <= 991) {
        return 3;
      } else {
        return 4;
      }
    });

    const updateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
      if (swiperInstance.value) {
        swiperInstance.value.params.slidesPerView = slidesPerView.value;
        swiperInstance.value.update();
      }
    };

    const onSwiper = (swiper) => {
      swiperInstance.value = swiper;
      // Ensure slidesPerView is set correctly after Swiper initialization
      updateWindowWidth();
    };

  
const getStatusClass = (status) => {
      return status.toLowerCase() === 'on progres' ? 'status-pending' : 'status-success';
    };

    const readMore = (patent) => {
      console.log(`Read more about patent: ${patent.title}`);
      // Implement your read more functionality here
    };

    const navigateToProduct = async (patent) => {
  if (patent.productRoute) {
    try {
      if (typeof patent.productRoute === 'string') {
        // Handle direct product links
        const [routeName, productId] = patent.productRoute.split('/');
        if (routeName === 'DetailProduk' && productId) {
          await router.push({ 
            name: 'DetailProduk', 
            params: { id_produk: productId } 
          });
        }
      } else {
        // Handle product category navigation using path
        const query = patent.productRoute.query;
        await router.push({
          path: '/ProdukPage', // Gunakan path absolut
          query: {
            filter: query.filter,
            scrollToFilter: 'true',
            timestamp: Date.now()
          }
        });

        // After navigation, scroll to filter section
        await nextTick();
        setTimeout(() => {
          const filterSection = document.querySelector('.horizontal-group-produk');
          if (filterSection) {
            const headerHeight = document.querySelector('header')?.offsetHeight || 0;
            const elementPosition = filterSection.getBoundingClientRect().top + window.pageYOffset;
            
            window.scrollTo({
              top: elementPosition - headerHeight - 20,
              behavior: 'smooth'
            });
          }
        }, 100);
      }
    } catch (err) {
      console.error('Navigation error:', err);
    }
  }
};


    // Scroll to patents section
    const scrollToPatents = () => {
      if (patentsRef.value) {
        patentsRef.value.scrollIntoView({ behavior: 'smooth' });
      }
    };

    // Patent highlight handler
    const highlightPatent = (patentTitle) => {
      highlightedPatent.value = patentTitle;
    };

    // Lifecycle hooks
    onMounted(() => {
      window.addEventListener('resize', updateWindowWidth);
      
      if (route.query.scrollTo === 'patents') {
        scrollToPatents();
      }
      if (route.params.highlightPatent) {
        highlightPatent(route.params.highlightPatent);
      }
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWindowWidth);
    });

    // Route watchers
    watch(() => route.query, (newQuery) => {
      if (newQuery.scrollTo === 'patents') {
        scrollToPatents();
      }
    });

    watch(() => route.params, (newParams) => {
      if (newParams.highlightPatent) {
        highlightPatent(newParams.highlightPatent);
      }
    });

    
      return {
        patents,
        getStatusClass,
        readMore,
      slidesPerView,
      navigateToProduct,
      patentsRef,
      highlightedPatent,
      swiperModules: [Navigation, Pagination],
      onSwiper,
      };
    }
  };
  </script>
  
  <style scoped>

.patents-swiper {
  padding: 20px 15px 50px;
  margin: 0 5%;
}

.patent-card {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 220px;
  text-align: left;
  width: 100%;
  margin: 0;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.patent-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.patent-icon {
  margin-bottom: 12px;
}

.patent-icon img {
  width: 50px;
  height: 50px;
}

.patent-title {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
  line-height: 1.3;
  flex-grow: 1;
}

.patent-id {
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  color: #000000;
  margin-bottom: 12px;
}

.read-more-wrapper {
  margin-top: auto;
}

.read-more-link {
  color: #ffca2e;
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-decoration: none;
}

.status-pending {
  color: red;
}

@media (max-width: 480px) {
  .patent-card {
    height: 200px;
    padding: 12px;
  }

  .patent-title {
    font-size: 11px;
  }

  .patent-id, .read-more-link {
    font-size: 9px;
  }
}

@media (max-width: 1024px) {
  .slidesPerView {
    value: 4;
  }
}



@media (max-width: 480px) {
  .slidesPerView {
    value: 2;
  }
}

@media only screen and (min-width: 1921px) { 


.patent-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 220px;
    text-align: left;
    width: 290px;
    margin: 0 10px;
}

.patent-title {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    line-height: 1.3;
}

.patent-id {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #666;
    margin-bottom: 12px;
}

.read-more-link {
    color: #ffca2e;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: bold;
}

    .section-title {
    text-align: left;
    font-family: 'Oswald', sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 16px;
    margin-left: 16px;
}

}

@media only screen and (max-width: 1920px) and (min-width: 1800px)  {


.patent-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 220px;
    text-align: left;
    width: 300px;
    margin: 0 10px;
}

.patent-title {
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 8px;
    line-height: 1.3;
}

.patent-id {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: #000000;
    margin-bottom: 12px;
}

.read-more-link {
    color: #ffca2e;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-weight: bold;
}

.patent-icon img {
    width: 89px;
    height: 80px;
}



.section-title {
    text-align: left;
    font-family: 'Oswald', sans-serif;
    font-size: 30px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 16px;
    margin-left: 16px;
}

}

@media only screen and (max-width: 1799px) and (min-width: 1600px)  {


.patent-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 220px;
    text-align: left;
    width: 270px;
    margin: 0 10px;
}

.patent-icon img {
    width: 80px;
    height: 80px;
}

.section-title {
    text-align: left;
    font-family: 'Oswald', sans-serif;
    font-size: 25px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 16px;
    margin-left: 16px;
}


.patent-title {
    font-family: 'Poppins', sans-serif;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 8px;
    line-height: 1.3;
}

.patent-id {
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    color: #000000;
    margin-bottom: 12px;
}


.read-more-link {
    color: #ffca2e;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: bold;
}

}



@media only screen and (max-width: 1599px) and (min-width: 1441px)  {


  .patents-swiper {
  padding: 20px 15px 50px;
  margin: 0 5%;
}

.patent-card {
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  height: 220px;
  text-align: left;
  width: 100%;
  margin: 0;
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.patent-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.patent-icon {
  margin-bottom: 12px;
}

.patent-icon img {
  width: 50px;
  height: 50px;
}

.patent-title {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 8px;
  line-height: 1.3;
  flex-grow: 1;
}

.patent-id {
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  color: #000000;
  margin-bottom: 12px;
}

.read-more-wrapper {
  margin-top: auto;
}

.read-more-link {
  color: #ffca2e;
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  font-weight: bold;
  text-decoration: none;
}

.status-pending {
  color: red;
}

@media (max-width: 480px) {
  .patent-card {
    height: 200px;
    padding: 12px;
  }

  .patent-title {
    font-size: 11px;
  }

  .patent-id, .read-more-link {
    font-size: 9px;
  }
}


}

@media only screen and (max-width: 1280px) and (min-width: 1025px) {


  .patent-icon img {
    width: 60px;
    height: 60px;
}

.patent-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 220px;
    text-align: left;
    width: 180px;
    margin: 0 10px;
}

}


@media only screen and (max-width: 1024px) and (min-width: 991px) {

.patent-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 220px;
    text-align: left;
    width: 180px;
    margin: 0 10px;
}


.patents-back {
    padding: 0 100px;
}

.patent-icon img {
    width: 60px;
    height: 60px;
}

.gold-bar-certif {
    width: 20%;
    height: 5px;
    background-color: #FFCA2E;
    position: absolute;
    margin-left: 250px;
    top: 100%;
}

}

@media only screen and (max-width: 991px) and (min-width: 765px) {

 
.patent-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 220px;
    text-align: left;
    width: 170px;
    margin: 0 10px;
}

.patents-back {
    padding: 0 100px;
}

.gold-bar-certif {
    margin-left: 280px;
    width: 20%;
  }

}

@media only screen and (max-width: 760px) and (min-width: 481px) {


    .patents-back {
    padding: 0 50px;
}

.gold-bar-certif {
    margin-left: 210px;
    width: 20%;
  }


.patent-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 220px;
    text-align: left;
    width: 180px;
    margin: 0 10px;
}


}

@media only screen and (max-width: 480px) and (min-width: 360px) {


    .patents-back {
    padding: 0 0;
}

.patent-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 220px;
    text-align: left;
    width: 150px;
    margin: 0 10px;
}

.patent-icon img {
    width: 40px;
    height: 40px;
}

.patent-title {
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 8px;
    line-height: 1.3;
}

.patent-id {
    font-family: 'Poppins', sans-serif;
    font-size: 8px;
    color: #000000;
    margin-bottom: 12px;
}

.read-more-link {
    color: #ffca2e;
    font-family: 'Poppins', sans-serif;
    font-size: 8px;
    font-weight: bold;
}


.our-patents-container {
    padding: 0px 0;
    background-color: #ffffff;
}

.section-title {
    text-align: left;
    font-family: 'Oswald', sans-serif;
    font-size: 15px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 16px;
    margin-left: 16px;
}

.gold-bar-certif {
        margin-left: 160px;
        width: 20%;
    }

}


@media only screen and (max-width: 359px) {

  .gold-bar-certif {
        margin-left: 115px;
        width: 30%;
    }

.patents-back {
    padding: 0 0;
}

.patent-card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: left;
    height: 220px;
    text-align: left;
    width: 140px;
    margin: 0 10px;
}

.patent-title {
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: bold;
    margin-bottom: 8px;
    line-height: 1.3;
}

.patent-id {
    font-family: 'Poppins', sans-serif;
    font-size: 8px;
    color: #000000;
    margin-bottom: 12px;
}

.read-more-link {
    color: #ffca2e;
    font-family: 'Poppins', sans-serif;
    font-size: 8px;
    font-weight: bold;
}

.section-title {
    text-align: left;
    font-family: 'Oswald', sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #000000;
    margin-bottom: 16px;
    margin-left: 16px;
}

}


@media only screen and (max-width: 340px) {


.patents-back {
    padding: 0 0;
}

}
  /* Styles remain the same as in the previous version */
  </style>