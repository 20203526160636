<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <div class="content-wrapper">
            <section class="content">
                <div class="container-fluid">
                    <div class="card card-primary card-outline">
                        <div class="card-header">
                            <div class="col-6">
                                <h5>Tulisan Baru</h5>
                            </div>
                            <div class="text-right">
                                <router-link to="/TampilanKategori" class="btn btn-primary" style="color: #ffffff;">
                                    <i class="fas fa-angle-left" style="color: #ffffff;"> </i> Kembali
                                </router-link>
                            </div>
                        </div>
                        <form @submit.prevent="store" class="card-body">
                            <!-- Title Input -->
                            <div class="form-group">
                                <label for="postTitle">Nama Kategori:</label>
                                <input type="text" id="nama_kategoeri" v-model="post.nama_kategori" class="form-control"
                                    placeholder="Enter Post Title" required>
                            </div>

                            <!-- Submit Button -->
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>
  
<script>
import FooterDs from "../components/FooterDs.vue"
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios';

export default {
    name: "TambahKategori",
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
    },
    setup() {
        const post = reactive({
            nama_kategori: ''
        })

        const validationErrors = ref([])
        const router = useRouter()

        function store() {
            const { nama_kategori } = post

            axios.post('https://puracigarettesolutions.com:3000/api/kategori/CreateKategori', { nama_kategori })
                .then(() => {
                    router.push('/TampilanKategori');
                })
                .catch(error => {
                    handleErrors(error)
                })
        }

        function handleErrors(error) {
            if (error.response && error.response.data) {
                validationErrors.value = error.response.data
            } else {
                // Handle unexpected errors
                console.error('Unexpected error structure:', error)
                validationErrors.value = [{ param: 'error', msg: 'Unexpected error occurred' }]
            }
        }

        return {
            post,
            validationErrors,
            store
        }
    }
}
</script>
  
  
<style></style>
  

