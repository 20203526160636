<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <div class="content-wrapper">
            <section class="content">
                <div class="container-fluid">
                    <div class="card card-primary card-outline">
                        <div class="card-header">
                            <div class="col-6">
                                <h5>Tulisan Baru</h5>
                            </div>
                            <div class="text-right">
                                <router-link to="/TampilanDetailProduk" class="btn btn-primary" style="color: #ffffff;">
                                    <i class="fas fa-angle-left" style="color: #ffffff;"></i> Kembali
                                </router-link>
                            </div>
                        </div>
                        <form @submit.prevent="update" class="card-body">
                            <!-- Title Input -->
                            <div class="form-group">
                                <label for="postTitle">Nama Detail Produk EN:</label>
                                <input type="text" id="nama_detail_produk_en" v-model="post.nama_detail_produk_en" class="form-control"
                                    placeholder="Enter Post Nama Detail Produk Inggris">
                            </div>

                            <div class="form-group">
                                <label for="postTitle">Nama Detail Produk ID:</label>
                                <input type="text" id="nama_detail_produk_id" v-model="post.nama_detail_produk_id" class="form-control"
                                    placeholder="Enter Post Nama Detail Produk Indo">
                            </div>

                            <div class="form-group">
                                <label for="category">Nama Produk:</label>
                                <select v-model="post.id_produk" class="form-control">
                                    <option v-for="produkItem in produk" :key="produkItem.id_produk"
                                        :value="produkItem.id_produk">
                                        {{ produkItem.judul_en }}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group">
                                <label for="imageUpload">Image:</label>
                                <input type="file" @change="handleFileUpload" accept="image/*" />
                                <div class="image-container">
                                    <img :src="getFileUrl(post.gambar)" alt="Deskripsi Gambar" class="custom-img-style">
                                </div>
                                <img v-if="post.gambar" :src="post.gambar" height="50" width="50" alt="Image Preview" />
                            </div>


                            <!-- Submit Button -->
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>

<script>
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import { reactive, ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router';

export default {
  components: {
    FooterDs,
    NavbarDs,
    SidebarDs,
  },
  setup() {
    const post = reactive({
      id_detail_produk: '',
      nama_detail_produk_en: '',
      nama_detail_produk_id: '',
      id_produk: null,
      gambar: null,
    });
    const produk = reactive([]);
    const imageFile = ref(null);
    const router = useRouter();
    const route = useRoute();

    onMounted(async () => {
      await fetchProduk();
      await fetchDetailProduk();
    });

    const fetchProduk = async () => {
      try {
        const response = await axios.get('https://puracigarettesolutions.com:3000/api/produk/TampilanProduk');
        produk.splice(0, produk.length, ...response.data.data);
      } catch (error) {
        console.error('Error fetching produk:', error);
      }
    };

    const fetchDetailProduk = async () => {
      if (!route.params.id_detail_produk) {
        console.error('No Detail Produk ID provided in route parameters.');
        return;
      }

      try {
        const response = await axios.get(`https://puracigarettesolutions.com:3000/api/detailproduk/TampilanDetail/${route.params.id_detail_produk}`);
        if (response.data.success && response.data.data) {
          const { id_detail_produk, nama_detail_produk_en, nama_detail_produk_id, gambar, id_produk } = response.data.data;
          post.id_detail_produk = id_detail_produk;
          post.nama_detail_produk_en = nama_detail_produk_en;
          post.nama_detail_produk_id = nama_detail_produk_id;
          post.gambar = gambar;
          post.id_produk = id_produk;
        } else {
          console.error('Invalid or missing data in server response.');
        }
      } catch (error) {
        console.error('Error fetching Detail Produk data:', error);
      }
    };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      if (file) {
        const imageUrl = URL.createObjectURL(file);
        post.gambar = imageUrl;
        imageFile.value = file;
      }
    };

    const getFileUrl = (filename) => {
      const baseUrl = 'https://puracigarettesolutions.com:3000/api/detailproduk/file';
      return `${baseUrl}/${filename}`;
    };

    const update = async () => {
      try {
        const formData = new FormData();
        formData.append('nama_detail_produk_en', post.nama_detail_produk_en);
        formData.append('nama_detail_produk_id', post.nama_detail_produk_id);
        formData.append('id_produk', post.id_produk);
     
        if (imageFile.value) {
          formData.append('gambar', imageFile.value);
        }

        const config = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };

        const response = await axios.put(`https://puracigarettesolutions.com:3000/api/detailproduk/EditDetailProduk/${post.id_detail_produk}`, formData, config);

        if (response.status === 200) {
          router.push('/TampilanDetailProduk');
          console.log('Detail Produk berhasil diperbarui');
        } else {
          throw new Error('Gagal memperbarui Detail Produk');
        }
      } catch (error) {
        console.error('Error updating Detail Produk:', error);
        // Handle errors or validation if necessary
      }
    };

    return {
      post,
      produk,
      imageFile,
      handleFileUpload,
      getFileUrl,
      update,
    };
  },
};
</script>


  
<style>
/* CSS */
.image-container {
    width: 50px;
    height: 50px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-img-style {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
</style>