<template>
    <HeaderCom />
    <!-- Start of breadcurmb section
        ============================================= -->
    <section id="saasio-breadcrumbin" class="saasio-breadcurmb-sectionix">
        <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div class="carousel-item active">
                    <div class="carousel-background">
                        <img src="assets/img/business-4/slider/produkk.webp" alt="First slide">
                    </div>
                    <div class="carousel-content">
                        <h1>{{ $t('BlogPages.title') }}</h1>
                        <p>{{ $t('BlogPages.desc') }}</p>
                        <div class="button-container-news">
                            <a href="/SimulasiCtp" class="btn btn-primary-news">
                                {{ $t('BlogPages.buttommore') }}<span class="arrow-icon">></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="filter-section">
        <div class="filter-label">{{ $t('BlogPages.filter') }}</div>
        <div class="horizontal-group">
            <div class="filter-blog" @click="toggleActive(0)" :class="{ active: activeItem === 0 }">
                {{ $t('BlogPages.all') }}</div>
            <div class="filter-blog" @click="toggleActive(8)" :class="{ active: activeItem === 8 }">
                {{ $t('BlogPages.event') }}</div>
            <div class="filter-blog" @click="toggleActive(1)" :class="{ active: activeItem === 1 }">
                {{ $t('BlogPages.cp') }}</div>
            <div class="filter-blog" @click="toggleActive(2)" :class="{ active: activeItem === 2 }">
                {{ $t('BlogPages.tp') }}</div>
            <div class="filter-blog" @click="toggleActive(3)" :class="{ active: activeItem === 3 }">
                {{ $t('BlogPages.etiket') }}</div>
            <div class="filter-blog" @click="toggleActive(9)" :class="{ active: activeItem === 9 }">
                {{ $t('BlogPages.inovasi') }}</div>
        </div>
    </div>

    <!-- Start of blog page section
        ============================================= -->
    <section id="news-feed" class="news-feed-section">
        <div class="container">
            <div class="blog-feed-content">
                <div class="row">
                    <div class="col-md-12">
                                                              <div class="blog-feed-post" v-for="(post, index) in paginatedPosts" :key="index">
    <div class="blog-feed-img-txt">
      <div class="row">
        <div class="col-lg-4 col-md-12 col-sm-12">
          <div class="saasio-blog-img">
            <img :src="getFileUrl(post.gambar2)" alt="Description of Image">
          </div>
        </div>
        <div class="col-lg-8 col-md-12 col-sm-12">
          <div class="saasio-blog-text">
            <!-- Link judul artikel -->
            <router-link 
              :to="{
                name: 'DetailBlog',
                params: {
                  slug: generateSlug(post)
                }
              }"
              class="newscontent"
            >
              <article class="center-text" v-html="currentLanguage === 'en' ? post.judul_en : post.judul_id">
              </article>
            </router-link>

            <article class="center-text" v-html="currentLanguage === 'en' ? post.deskripsi_en : post.deskripsi_id">
            </article>
            <br>
            
            <!-- Link read more -->
            <router-link 
              :to="{
                name: 'DetailBlog',
                params: {
                  slug: generateSlug(post)
                }
              }"
              class="newscontent" 
              style="color: #dcb83d;"
            >
              {{ $t('BlogPages.buttom') }} >
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>

                        <!-- Pagination section -->
                        <div class="saasio-pagination text-center">
    <ul class="pagination">
        <li 
            v-for="page in totalPages" 
            :key="page" 
            class="page-item"
        >
            <a 
                href="#" 
                @click.prevent="setPage(page)" 
                class="page-link"
                :class="{ active: currentPage === page }"
            >
                {{ page }}
            </a>
        </li>
    </ul>
</div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <FooterCom />
</template>

<script>
import HeaderCom from '@/components/HeaderCom.vue';
import FooterCom from '@/components/FooterCom.vue';
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import { metaMixin } from '@/mixins/metaMixin';

const API_URL = process.env.VUE_APP_API_URL || 'https://puracigarettesolutions.com:3000';

export default {
    name: 'BlogPura',
    mixins: [metaMixin],
    components: {
        HeaderCom,
        FooterCom,
    },
    setup() {
        const currentPage = ref(1);
        const totalPages = ref(0);
        const postsPerPage = 3;
        const categories = ref([]);
        const postsArtikel = ref([]);
        const activeItem = ref(null);
        const { locale } = useI18n();

        const currentLanguage = computed(() => locale.value);

        const generateSlug = (post) => {
      const title = currentLanguage.value === 'en' ? post.judul_en : post.judul_id;
      
      if (!title) return 'default-slug';

      // Bersihkan HTML tags dan entitas
      let cleanTitle = title
        .replace(/<[^>]*>/g, '')
        .replace(/&nbsp;/g, ' ')
        .replace(/&amp;/g, '&')
        .trim();

      // Ambil bagian pertama dari judul (sebelum pemisah)
      cleanTitle = cleanTitle.split(/[:–—\n]/)[0].trim();

      // Ambil 5 kata pertama
      const words = cleanTitle.split(/\s+/).slice(0, 5);
      cleanTitle = words.join(' ');

      // Generate slug yang konsisten
      const slug = cleanTitle
        .toLowerCase()
        .replace(/[^a-z0-9\s-]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')
        .replace(/^-+|-+$/g, '');

      return slug || 'default-slug';
    };

      
        const getFileUrl = (filename) => {
            return `${API_URL}/api/artikel/file/${filename}`;
        };

        const paginatedPosts = computed(() => {
            const start = (currentPage.value - 1) * postsPerPage;
            const end = start + postsPerPage;
            return postsArtikel.value.slice(start, end);
        });

        const setPage = (page) => {
            currentPage.value = page;
        };

        const toggleActive = async (index) => {
            activeItem.value = index;
            await getData(index);
        };

        const getData = async (id_kategori = 0) => {
            try {
                const url = `${API_URL}/api/artikel/TampilanKategori?category=${id_kategori}`;
                const response = await axios.get(url);
                const formattedPosts = response.data.data.map(post => ({
                    ...post,
                    tanggal: moment(post.tanggal).format('YYYY-MM-DD')
                }));
                postsArtikel.value = formattedPosts;
                totalPages.value = Math.ceil(formattedPosts.length / postsPerPage);
            } catch (err) {
                console.error('Error fetching data:', err);
            }
        };

        onMounted(() => {
            getData();
        });

        return {
            currentPage,
            totalPages,
            paginatedPosts,
            categories,
            activeItem,
            postsArtikel,
            currentLanguage,
            generateSlug,
            getFileUrl,
            setPage,
            toggleActive
        };
    }
};
</script>

<style scoped>
.button-container-news {
    display: flex;
    justify-content: center;
}

.btn-primary-news {
    background-color: #FFCA2E;
    color: black;
    padding: 10px 20px;
    border-radius: 25px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;
}

.btn-primary-news:hover {
    background-color: black;
    color: white;
}

.saasio-pagination {
    margin: 40px 0;
    display: flex;
    justify-content: center;
}

.pagination {
    display: flex !important;
    list-style: none !important;
    padding: 0;
    margin: 0;
}

.page-item {
    margin: 0 5px;
    text-align: center;
    list-style: none !important;
}

.page-link {
    display: inline-block;
    padding: 0px 16px;
    text-decoration: none;
    color: #ffffff;
    background-color: #000000;
    border: 1px solid #ddd;
    transition: all 0.3s ease;
}

.page-link:hover {
    background-color: #FFCA2E;
}

.page-link.active {
    background-color: #FFCA2E;
    border-color: #FFCA2E;
    color: #000;
}
</style>