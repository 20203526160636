<template>
  <div id="menu-header">
    <header :class="['apbc-header-section', {'apbc-header-sticky': isSticky}]">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-2 col-md-6 col-7">
            <div class="apbc-logo-wrapper">
              <a href="/">
                <img 
                  :src="logoPath"
                  :alt="$t('header.logo')"
                  class="logo-image"
                  width="150"
                  height="50"
                  loading="eager"
                  fetchpriority="high"
                >
              </a>
            </div>
          </div>
          <div class="col-lg-8 apbc-desktop-menu">
            <nav class="apbc-nav-menu">
              <ul>
                <li v-for="item in menuItems" 
                    :key="item.section" 
                    :class="{ 'active': activeSection === item.section }">
                  <a :href="item.path">{{ $t(`header.${item.section}`) }}</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="col-lg-2 col-md-6 col-5 d-flex justify-content-end align-items-center">
            <div class="apbc-lang-menu d-none d-lg-flex">
              <ul class="d-flex align-items-center">
                <li v-for="lang in languages" 
                    :key="lang" 
                    class="language-switcher">
                  <button 
                    :class="{ 'selected': locale === lang }" 
                    @click="changeLanguage(lang)">
                    {{ lang.toUpperCase() }}
                  </button>
                  <span v-if="lang === 'en'" class="separator">|</span>
                </li>
              </ul>
            </div>
            <button 
              class="apbc-mobile-menu-open d-lg-none"
              @click="toggleMobileMenu"
              aria-label="Toggle menu">
              <i class="fas fa-bars"></i>
            </button>
          </div>
        </div>
      </div>
    </header>

    <div class="apbc-mobile-navigation" :class="{ 'open': isMobileMenuOpen }">
      <div class="apbc-mobile-menu-wrapper">
        <button 
          class="apbc-mobile-menu-close" 
          @click="toggleMobileMenu" 
          aria-label="Close menu">
          <i class="fas fa-times"></i>
        </button>
        <nav>
          <ul>
            <li v-for="item in menuItems" 
                :key="item.section" 
                :class="{ 'active': activeSection === item.section }">
              <a :href="item.path">{{ $t(`header.${item.section}`) }}</a>
            </li>
          </ul>
          <div class="translite">
            <ul class="d-flex align-items-center">
              <li v-for="lang in languages" 
                  :key="lang" 
                  class="language-switcher">
                <button 
                  :class="{ 'selected': locale === lang }" 
                  @click="changeLanguage(lang)">
                  {{ lang.toUpperCase() }}
                </button>
                <span v-if="lang === 'en'" class="separator">|</span>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

// Constants
const SCROLL_THRESHOLD = 100;
const LANGUAGES = ['en', 'id'];
const MENU_ITEMS = [
  { section: 'home', path: '/' },
  { section: 'about', path: '/AboutPage' },
  { section: 'product', path: '/ProdukPage' },
  { section: 'blog', path: '/BlogPura' },
  { section: 'contactus', path: '/ContactUs' }
];

// Logo configuration
const LOGO_CONFIG = {
  default: {
    path: '/assets/img/business-4/LOGO.webp'
  },
  sticky: {
    path: '/assets/img/business-4/LOGO_Black.webp'
  }
};

export default defineComponent({
  name: 'HeaderCom',
  
  setup() {
    const { locale } = useI18n();
    const router = useRouter();
    const isSticky = ref(false);
    const isMobileMenuOpen = ref(false);
    const activeSection = ref('home');

    // Computed
    const logoPath = computed(() => 
      isSticky.value ? LOGO_CONFIG.sticky.path : LOGO_CONFIG.default.path
    );

    // Methods
    const handleScroll = () => {
      isSticky.value = window.scrollY > SCROLL_THRESHOLD;
    };

    const changeLanguage = (lang) => {
      locale.value = lang;
      localStorage.setItem('preferred-language', lang);
    };

    const toggleMobileMenu = () => {
      isMobileMenuOpen.value = !isMobileMenuOpen.value;
      document.body.style.overflow = isMobileMenuOpen.value ? 'hidden' : '';
    };

    const updateActiveSection = () => {
      const currentRoute = router.currentRoute.value.name?.toLowerCase() || '';
      activeSection.value = MENU_ITEMS.find(item => 
        item.section === currentRoute
      )?.section || 'home';
    };

    // Lifecycle hooks
    onMounted(() => {
      // Initial setup
      updateActiveSection();
      
      // Event listeners
      window.addEventListener('scroll', handleScroll, { passive: true });
      router.afterEach(updateActiveSection);
      
      // Load saved language preference
      const savedLanguage = localStorage.getItem('preferred-language');
      if (savedLanguage && LANGUAGES.includes(savedLanguage)) {
        locale.value = savedLanguage;
      }
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', handleScroll);
      document.body.style.overflow = '';
    });

    return {
      // Constants
      languages: LANGUAGES,
      menuItems: MENU_ITEMS,
      
      // State
      isSticky,
      isMobileMenuOpen,
      activeSection,
      locale,
      logoPath,
      
      // Methods
      changeLanguage,
      toggleMobileMenu
    };
  }
});
</script>


<style scoped>

/* Header section styles */
.apbc-header-section {
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  z-index: 10;
   background-color: rgb(0, 0, 0); /* 50% transparency */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.apbc-header-sticky {
  background-color: white;
  color: black;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: fixed;
  z-index: 20;
}


.apbc-logo-wrapper a {
  color: white; /* Inherits text color based on header state */
}

.apbc-nav-menu ul li a {
  color: white; /* Inherits text color based on header state */
}

.apbc-nav-menu ul li.active a {
  color: gold; /* Active link color */
}

.apbc-nav-menu ul li a:hover {
  color: gold; /* Hover color */
}

/* Specific styles for the mobile menu */
.apbc-mobile-navigation {
  position: fixed;
  right: -320px;
  top: 0;
  width: 300px;
  height: 100vh;
  overflow: scroll;
  background-color: #000000;
  padding-top: 20px;
  z-index: 300;
  transition: right 0.3s ease-in;
}

.apbc-mobile-navigation.open {
  right: 0;
}

.apbc-mobile-menu-open a {
  background-color: gold;
  color: black;
}

.apbc-header-sticky .apbc-nav-menu ul li a {
  color: black;
  font-weight: bold;
}

.apbc-header-sticky .apbc-nav-menu ul li.active a {
  color: gold; /* Active link color */
}

.apbc-header-sticky .apbc-logo-wrapper a,
.apbc-header-sticky .language-switcher button {
  color: black;
}

.apbc-lang-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.translite {
  margin-left: 20px;
}

.language-switcher {
  display: flex;
  align-items: center;
}

.language-switcher button {
  background: none;
  border: none;
  color: white;
  margin-top: 13px;
  font-family: 'Poppins', sans-serif; 
  font-size: 12px;
  cursor: pointer;
  padding: 10px 15px; /* Adjusted padding for better width */
  margin-right: 2px;
}

.language-switcher button.selected {
  color: gold;
}

.language-switcher button:hover {
  color: gold;
}

.language-switcher button:active {
  color: gold; /* Ensuring the color stays consistent when clicked */
}

.separator {
  color: white;
  margin-top: 13px;
  padding: 0 5px;
}

.apbc-header-sticky .separator {
  color: black;
}

.d-none {
  display: none;
}

.d-lg-flex {
  display: flex !important;
}

.d-lg-none {
  display: none !important;
}

@media (max-width: 991.98px) {
  .d-lg-flex {
    display: none !important;
  }

  .d-lg-none {
    display: block !important;
  }
}
</style>
