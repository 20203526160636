<template>
    <HeaderCom />
    <section class="saasio-breadcurmb-section-contact">
        <div class="container">
            <div class="breadcurmb-title-contact">
                <h2>CIgarette Paper Simulation</h2>
            </div>
        </div>
    </section>

    <section id="saas_two_about" class="saas_two_about_section relative-position">
        <div class="container">
            <div class="about_content_s2">
                <div class="row">
                    <div class="col-lg-6 col-md-12 wow fadeFromLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div class="s2-about_img">
                            <div class="centered-content">
                                <div class="cp-rokok" ref="cpRokokElement">
                                    <div class="cp-filter"
                                        :style="selectedCpMotif !== null ? { backgroundImage: 'url(' + selectedCpMotif.url + ')' } : {}">
                                    </div>
                                    <div class="cp-tubuh"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 wow fadeFromRight" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div class="s2-about_text_icon">
                            <div class="s2-about_text saas2-headline pera-content">
                                <h3>{{ $t('cpSimulasiPage.subtitle') }}</h3>
                                <p class="justify-text">{{ $t('cpSimulasiPage.content') }}</p>
                                <br>
                                <br>
                            </div>

                            <!-- <label for="cpFilterSelect">{{ $t('cpSimulasiPage.filter') }}</label>
                            <select v-model="selectedCpFilter" id="cpFilterSelect" class="filter-select">
                                <option value="">{{ $t('cpSimulasiPage.allMotifs') }}</option>
                                <option v-for="category in cpMotifCategories" :key="category" :value="category">
                                    {{ category }}
                                </option>
                            </select> -->

                            <label>Motif</label>
                            <div class="cp-motifs-container">
                                <br>
                                <br>
                                <div v-for="cpMotif in filteredCpMotifs" :key="cpMotif.name" class="cp-motif-selector"
                                    @click="selectCpMotif(cpMotif)">
                                    <img :src="cpMotif.url" :alt="cpMotif.name" />
                                    <div v-if="selectedCpMotif === cpMotif" class="cp-checkmark">✓</div>
                                </div>
                            </div>
                            <br>

                            <button class="btn btn-primary" @click="downloadCpImage">{{ $t('cpSimulasiPage.download') }}</button>
                            <canvas ref="cpCanvas" width="20" height="200" style="display: none;"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <FooterCom />
</template>

<script>
import html2canvas from 'html2canvas';
import FooterCom from '@/components/FooterCom.vue';
import HeaderCom from '@/components/HeaderCom.vue';
import { useI18n } from 'vue-i18n';

export default {
    name: 'CpSimulasi',
    components: {
        FooterCom,
        HeaderCom,
    },
    setup() {
        const { t, locale } = useI18n();
        return { t, locale };
    },
    data() {
        return {
            selectedCpMotif: null,
            selectedCpFilter: '',
            cpMotifs: [
                { name: 'Asset 1', url: 'assets/img/business-4/motifcp/Asset1.png', category: 'Assets' },
                { name: 'Asset 2', url: 'assets/img/business-4/motifcp/Asset2.png', category: 'Assets' },
                { name: 'Asset 3', url: 'assets/img/business-4/motifcp/Asset3.png', category: 'Assets' },
                { name: 'CP-PRT01', url: 'assets/img/business-4/motifcp/CPPRT01.jpg', category: 'PRT' },
                { name: 'CP-PRT02', url: 'assets/img/business-4/motifcp/CP-PRT02.jpg', category: 'PRT' },
                { name: 'CP-PRT03', url: 'assets/img/business-4/motifcp/CP-PRT03.jpg', category: 'PRT' },
                { name: 'CP-PRT04', url: 'assets/img/business-4/motifcp/CP-PRT04.jpg', category: 'PRT' },
                { name: 'CP-PRT05', url: 'assets/img/business-4/motifcp/CP-PRT05.jpg', category: 'PRT' },
                { name: 'CP-PRT06', url: 'assets/img/business-4/motifcp/CP-PRT06.jpg', category: 'PRT' },
                { name: 'CP-PRT07', url: 'assets/img/business-4/motifcp/CP-PRT07.jpg', category: 'PRT' },
                { name: 'CP-PRT08', url: 'assets/img/business-4/motifcp/CP-PRT08.jpg', category: 'PRT' },
                { name: 'CP-PRT09', url: 'assets/img/business-4/motifcp/CP-PRT09.jpg', category: 'PRT' },
                { name: 'CP-PRT10', url: 'assets/img/business-4/motifcp/CP-PRT10.jpg', category: 'PRT' },
            ],
        };
    },
    computed: {
        cpMotifCategories() {
            return [...new Set(this.cpMotifs.map(motif => motif.category))];
        },
        filteredCpMotifs() {
            if (!this.selectedCpFilter) return this.cpMotifs;
            return this.cpMotifs.filter(motif => motif.category === this.selectedCpFilter);
        }
    },
    methods: {
        selectCpMotif(cpMotif) {
            this.selectedCpMotif = cpMotif;
        },
        changeLanguage(lang) {
            console.log(`Changing language to: ${lang}`);
            this.locale = lang;
        },
        downloadCpImage() {
            const cpRokokElement = this.$refs.cpRokokElement;

            html2canvas(cpRokokElement, {
                logging: true,
                letterRendering: 1,
                useCORS: true
            }).then(canvas => {
                const image = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.download = 'custom_cp_rokok.png';
                link.href = image;
                link.click();
            }).catch(err => {
                console.error('Error in html2canvas: ', err);
            });
        }
    },
};
</script>

<style scoped>
.cp-rokok {
    position: relative;
    width: 40px;
    height: 400px;
    background-color: #f5f5f5;
    border-radius: 20px;
    border: 1px solid #ddd;
    margin: 50px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}

.cp-filter {
    position: absolute;
    bottom: 0;
    width: 38.8px;
    height: 450px;
    background-image: url('../assets/Asset1.png');
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.cp-tubuh {
    width: 100%;
    height: 300px;
    background-color: #ffffff;
}

.cp-motifs-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.cp-motif-selector {
    margin: 10px;
    width: 50px;
    height: 50px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cp-motif-selector img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.cp-checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    color: #ffffff;
    border: 2px solid #dcb83d;
    border-radius: 50%;
    background-color: #dcb83d;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    text-align: center;
    line-height: 20px;
}

.justify-text {
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
}

.filter-select {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
}

.cp-motif-name {
    margin-top: 5px;
    font-size: 12px;
    text-align: center;
}
</style>