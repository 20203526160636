<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">Pura MICS</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <div class="content-wrapper">
            <section class="content">
                <div class="container-fluid">
                    <div class="card card-primary card-outline">
                        <div class="card-header">
                            <div class="col-6">
                                <h5>Blog Baru</h5>
                            </div>
                            <div class="text-right">
                                <router-link to="/TampilanDetailProduk" class="btn btn-primary" style="color: #ffffff;">
                                    <i class="fas fa-angle-left" style="color: #ffffff;"> </i> Kembali
                                </router-link>
                            </div>
                        </div>
                        <form @submit.prevent="store" class="card-body">
                            <!-- Title Input -->
                            <div class="form-group">
                                <label for="postTitle">Nama Detail Produk EN:</label>
                                <input type="text" id="nama_detail_produk_en" v-model="post.nama_detail_produk_en" class="form-control"
                                    placeholder="Masukkan Detail Produk Inggris">
                            </div>
                            <div class="form-group">
                                <label for="postTitle">Nama Detail Produk ID:</label>
                                <input type="text" id="nama_detail_produk_id" v-model="post.nama_detail_produk_id" class="form-control"
                                    placeholder="Masukkan Detail Produk Indo">
                            </div>
                            <div class="form-group">
                                <label for="category">Nama Produk :</label>
                                <select v-model="post.id_produk" class="form-control">
                                    <option disabled selected>Pilih Porduk</option>
                                    <option v-for="produk in produk" :key="produk.id_produk"
                                        :value="produk.id_produk">
                                        {{ produk.judul_en }}
                                    </option>
                                </select>

                            </div>

                            <!-- Image Upload -->
                            <div class="form-group">
                                <label for="imageUpload">Image:</label>
                                <input type="file" id="gambar" class="form-control-file" @change="handleImageUpload">
                                <img v-if="imagePreview" :src="imagePreview" alt="Preview"
                                    style="max-width: 200px; max-height: 200px; margin-top: 10px;">
                            </div>

                            <!-- Submit Button -->
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>
 
<script>
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import axios from 'axios';

export default {
    name: "CombinedComponent",
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
    },
    data() {
        return {
            produk: [],
            post: {
                nama_detail_produk_en: '',
                nama_detail_produk_id: '',
                id_produk: null,
                gambar: null
            },
            validationErrors: [],
            imagePreview: '',
            isSubmitting: false
        };
    },
    async mounted() {
        await this.fetchProduk();
    },
    methods: {
        async fetchProduk() {
            try {
                const response = await axios.get('https://puracigarettesolutions.com:3000/api/produk/TampilanProduk');
                this.produk = response.data.data; // Mengakses array kategori dari properti 'data' pada objek respons
            } catch (error) {
                console.error('Error saat mengambil data Produk:', error);
            }
        },

        async store() {
            if (!this.validateForm()) {
                alert('Please fill in all required fields.');
                return;
            }
            this.isSubmitting = true;
            const formData = new FormData();
            formData.append('nama_detail_produk_en', this.post.nama_detail_produk_en);
            formData.append('nama_detail_produk_id', this.post.nama_detail_produk_id);
            formData.append('id_produk', this.post.id_produk); // Menambahkan kategori
            formData.append('gambar', this.post.gambar);
            try {
                const response = await axios.post('https://puracigarettesolutions.com:3000/api/detailproduk/CreateDetailProduk', formData);
                console.log('Submitted:', response.data);
                this.$router.push({ name: 'TampilanDetailProduk' });
            } catch (error) {
                this.handleErrors(error);
            } finally {
                this.isSubmitting = false;
            }
        },
        handleErrors(error) {
            if (error.response && error.response.data) {
                this.validationErrors = error.response.data;
            } else {
                console.error('Unexpected error structure:', error);
                this.validationErrors = [{ param: 'error', msg: 'Unexpected error occurred' }];
            }
        },
        validateForm() {
            return this.post.gambar;
        },

        handleImageUpload(event) {
            this.post.gambar = event.target.files[0];
            this.imagePreview = URL.createObjectURL(event.target.files[0]);
        }
        // ... metode lainnya ...
    }
};
</script>


  
<style></style>
  

