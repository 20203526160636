<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Data Produk</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">Data Produk</li>
                            </ol>
                        </div>
                    </div>
                </div><!-- /.container-fluid -->
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header row">
                                    <h3 class="card-title">Data Produk</h3>
                                    <div class="col-12 d-flex justify-content-end">
                                        <router-link to="/TambahDetailProduk" class="btn btn-primary">
                                            <i class="fas fa-plus" style="color: #ffffff;"> Tambah Detail Produk</i>
                                        </router-link>
                                    </div>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table id="detailproduk" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">No</th>
                                                <th scope="col">Nama Detail Produk EN</th>
                                                <th scope="col">Nama Detail Produk ID</th>
                                                <th scope="col">Produk</th>
                                                <th scope="col">Gambar</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(post, index) in posts" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ post.nama_detail_produk_en }}</td>
                                                <td>{{ post.nama_detail_produk_id }}</td>
                                                <td>{{ post.judul_en }}</td>
                                                <td>
                                                    <div class="image-container">
                                                        <img :src="getFileUrl(post.gambar)" alt="Deskripsi Gambar"
                                                            class="custom-img-style">
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <router-link :to="{ name: 'EditDetailProduk', params: { id_detail_produk: post.id_detail_produk } }" class="btn btn-warning mr-2" style="color: #ffffff;">EDIT</router-link>
                                                    <button @click.prevent="confirmDelete(post.id_detail_produk)"
                                                        class="btn btn-sm btn-danger">DELETE</button>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                        </div>
                                 
                                </div>
                                <!-- /.card-body -->
                            </div>
                            <!-- /.card -->
                        </div>
                        <!-- /.col -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.container-fluid -->
            </section>
            <!-- /.content -->
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>
  
<script>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import { nextTick } from 'vue';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.min.css';
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";

export default {
    name: "TampilanProduk",
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
    },
    setup() {
        const posts = ref([]);

        const getDataPosts = () => {
            axios.get('https://puracigarettesolutions.com:3000/api/detailproduk/TampilanDetailProduk')
                .then(response => {
                    posts.value = response.data.data;
                    // Initialize DataTables after data is fetched
                    nextTick(() => {
                        $('#detailproduk').DataTable({
                            responsive: true,
                        });
                    });
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const getFileUrl = (filename) => {
            const baseUrl = 'https://puracigarettesolutions.com:3000/api/detailproduk/file';
            return `${baseUrl}/${filename}`;
        };

        const confirmDelete = (id_detail_produk) => {
            if (confirm("Are you sure you want to delete this post?")) {
                deletePost(id_detail_produk);
            }
        };

        const deletePost = (id_detail_produk) => {
            axios.delete(`https://puracigarettesolutions.com:3000/api/detailproduk/TampilanDetailProduk/${id_detail_produk}`)
                .then(() => {
                    getDataPosts();
                }).catch(error => {
                    console.error("Error deleting post:", error.response.data);
                });
        };

        onMounted(getDataPosts);

        return {
            posts,
            getFileUrl,
            confirmDelete
        };
    },
};
</script>
  
<style>
/* CSS */


</style>
  