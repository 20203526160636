<!-- src/components/Login.vue -->
<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->
        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <div class=" login-page">
            <div class="login-box">
                <!-- /.login-logo -->
                <div class="card card-outline card-primary">
                    <div class="card-body">
                        <p class="login-box-msg">REGISTRASI</p>

                        <form @submit.prevent="register">
                            <div class="input-group mb-3">
                                <input v-model="username" type="text" class="form-control" placeholder="Username" required>
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-envelope"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <input v-model="password" type="password" class="form-control" placeholder="Password"
                                    required>
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <input v-model="confirmPassword" type="password" class="form-control"
                                    placeholder="Confirm Password" required>
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                        <span class="fas fa-lock"></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">

                                <!-- /.col -->
                                <div class="col-4">
                                    <button :disabled="loading" type="submit"
                                        class="form-control btn btn-primary rounded submit px-3">
                                        {{ loading ? 'Registering...' : 'Register' }}
                                    </button>
                                </div>
                                <!-- /.col -->
                            </div>
                        </form>
                        <!-- /.social-auth-links -->
                        <br>
                        <p class="mb-0">
                            <a href="register.html" class="text-center">Register a new membership</a>
                        </p>
                    </div>
                    <!-- /.card-body -->
                </div>
                <!-- /.card -->
            </div>
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
</template>
  
<script>
import axios from 'axios';
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";

export default {
    name: 'UserRegistration',
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
    },
    data() {
        return {
            username: '',
            password: '',
            confirmPassword: '',
            id_role: '',
            successMessage: '',
            errorMessage: '',
            loading: false,
        };
    },
    methods: {
        register() {
            if (!this.username || !this.password || !this.confirmPassword) {
                this.errorMessage = 'Username, password, and confirm password are required.';
                return;
            }

            if (this.password !== this.confirmPassword) {
                this.errorMessage = 'Password and confirm password do not match.';
                return;
            }

            this.loading = true;

            axios
                .post('https://puracigarettesolutions.com:3000/api/users/register', {
                    username: this.username,
                    password: this.password,
                })
                .then((response) => {
                    if (response.data.success) {
                        this.successMessage = response.data.message;
                        // Lakukan sesuatu jika registrasi berhasil
                        this.$router.push('/DashboardDs');
                    } else {
                        this.errorMessage = response.data.message || 'Registration failed.';
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    console.error('Registration error:', error.response.data);
                    this.errorMessage = 'Error during registration.';
                    this.loading = false;
                });


        },
    },
};
</script>



  
<style scoped>
.login-page,
.register-page {
    -ms-flex-align: center;
    align-items: center;
    background-color: #e9ecef;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    -ms-flex-pack: center;
    justify-content: center;
}
</style>
  