<template>
  <div v-cloak>
    <HeaderCom />
    
    <!-- Breadcrumb Section -->
    <section class="saasio-breadcurmb-section-detail-produk">
      <div class="container">
        <div class="breadcurmb-title-detail-produk">
          <h1>{{ $t('detailProduk.title') }}</h1>
        </div>
      </div>
    </section>

    <!-- Loading State -->
    <div v-if="loading" class="loading-skeleton">
      <div class="skeleton-image"></div>
      <div class="skeleton-content">
        <div class="skeleton-line"></div>
        <div class="skeleton-line"></div>
      </div>
    </div>

    <!-- Main Product Content -->
    <div v-else-if="currentProduct" class="product-container">
      <!-- Image Gallery Section -->
      <div class="image-gallery">
        <div class="main-image-container">
          <button 
            v-if="showNavButtons" 
            @click="goLeft" 
            class="nav-button prev"
            aria-label="Previous image"
          >
            &lt;
          </button>
          
          <!-- Main Product Image with optimized loading -->
          <img 
            :src="mainImageUrl"
            :alt="`Product Image ${currentIndex + 1}`" 
            class="main-image"
            @click="openImageModal(mainImageUrl)"
            ref="mainImageRef"
            loading="lazy"
            @error="handleImageError"
            :key="currentIndex"
          >
          
          <button 
            v-if="showNavButtons" 
            @click="goRight" 
            class="nav-button next"
            aria-label="Next image"
          >
            &gt;
          </button>
        </div>

        <!-- Thumbnail Gallery -->
        <div class="thumbnail-gallery">
          <swiper
            :modules="modules"
            :slides-per-view="computedSlidesPerView"
            :space-between="10"
            class="swiper-container-gallery"
            :observer="true"
            :observeParents="true"
          >
            <swiper-slide 
              v-for="(detail, index) in detailProduk" 
              :key="detail.id"
            >
              <div
                class="thumbnail-item"
                :class="{ 'active': index === currentIndex }"
                @click="updateMainImage(index)"
                role="button"
                tabindex="0"
              >
                <img
                  :src="getFileUrlix(detail.gambar)"
                  :alt="`Thumbnail ${index + 1}`"
                  class="thumbnail"
                  loading="lazy"
                  @error="handleImageError"
                >
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>

      <!-- Zoom Modal -->
      <div 
        v-if="isModalOpen" 
        class="modal" 
        @click="closeImageModal"
      >
        <div 
          class="modal-content" 
          @click.stop
        >
          <div 
            class="image-container"
            @wheel="handleWheel"
            @mousedown="startDrag"
            @mousemove="doDrag"
            @mouseup="stopDrag"
            @mouseleave="stopDrag"
            @touchstart="handleTouchStart"
            @touchmove="handleTouchMove"
            @touchend="handleTouchEnd"
          >
            <img 
              :src="zoomedImageUrl" 
              alt="Zoomed Product Image" 
              class="zoomed-image"
              :style="{
                transform: `scale(${zoomLevel}) translate(${panPosition.x / zoomLevel}px, ${panPosition.y / zoomLevel}px)`,
                transition: isDragging ? 'none' : 'transform 0.3s ease-out'
              }"
            >
          </div>
          
          <button 
            class="close-button" 
            @click="closeImageModal"
            aria-label="Close zoom view"
          >
            &times;
          </button>
          
          <div class="zoom-controls">
            <button @click="zoomIn" aria-label="Zoom in">+</button>
            <button @click="zoomOut" aria-label="Zoom out">-</button>
            <button @click="resetZoom" aria-label="Reset zoom">Reset</button>
          </div>
        </div>
      </div>

      <!-- Product Info Section -->
      <div class="product-info">
        <h2>{{ currentProduct.judul_en }}</h2>
        <h3>{{ currentProduct.nama_kategori }}</h3>
        <hr class="separator">
        
        <div class="description">
          <h4>
            <strong>{{ $t('detailProduk.title2') }}</strong>
          </h4>
          <article 
            class="description-produk"
            v-html="currentProduct.keterangan_en"
          ></article>
        </div>
        <hr class="separator">

        <!-- Product Options -->
        <div class="additional-value">
  <div class="value-options">
    <select 
      v-if="processedOptions.printed?.length > 0" 
      class="custom-select"
    >
      <option value="" disabled selected>Printed</option>
      <option 
        v-for="option in processedOptions.printed" 
        :value="option.value" 
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <select 
      v-if="processedOptions.flavor?.length > 0" 
      class="custom-select"
    >
      <option value="" disabled selected>Flavor</option>
      <option 
        v-for="option in processedOptions.flavor" 
        :value="option.value" 
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <select 
      v-if="processedOptions.sweetener?.length > 0" 
      class="custom-select"
    >
      <option value="" disabled selected>Sweetener</option>
      <option 
        v-for="option in processedOptions.sweetener" 
        :value="option.value" 
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <select 
      v-if="processedOptions.finishing?.length > 0" 
      class="custom-select"
    >
      <option value="" disabled selected>Finishing</option>
      <option 
        v-for="option in processedOptions.finishing" 
        :value="option.value" 
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <select 
      v-if="processedOptions.size?.length > 0" 
      class="custom-select"
    >
      <option value="" disabled selected>Size</option>
      <option 
        v-for="option in processedOptions.size" 
        :value="option.value" 
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <select 
      v-if="processedOptions.materials?.length > 0" 
      class="custom-select"
    >
      <option value="" disabled selected>Materials</option>
      <option 
        v-for="option in processedOptions.materials" 
        :value="option.value" 
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <select 
      v-if="processedOptions.features?.length > 0" 
      class="custom-select"
    >
      <option value="" disabled selected>Added Features</option>
      <option 
        v-for="option in processedOptions.features" 
        :value="option.value" 
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <select 
      v-if="processedOptions.length?.length > 0" 
      class="custom-select"
    >
      <option value="" disabled selected>Length</option>
      <option 
        v-for="option in processedOptions.length" 
        :value="option.value" 
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <select 
      v-if="processedOptions.diameter?.length > 0" 
      class="custom-select"
    >
      <option value="" disabled selected>Diameter</option>
      <option 
        v-for="option in processedOptions.diameter" 
        :value="option.value" 
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <select 
      v-if="processedOptions.special?.length > 0" 
      class="custom-select"
    >
      <option value="" disabled selected>Spesial</option>
      <option 
        v-for="option in processedOptions.special" 
        :value="option.value" 
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>

  <hr class="separator">

  <!-- Patent Info Section -->
  <div v-if="showPatentInfo" class="patent-info">
    <h4><strong>Our Patent</strong></h4>
    <div v-for="patent in productPatents" :key="patent.id" class="patent-item">
      <div class="patent-content" @click="navigateToPatent(patent)">
        <img :src="patent.certificateImage" :alt="patent.title + ' Certificate'" class="certificate-image">
        <div class="patent-details">
          <h5>{{ patent.title }}</h5>
          <p>{{ patent.description }}</p>
        </div>
      </div>
    </div>
  </div>
  
  <hr class="separator">

  <!-- Action Buttons -->
  <div class="buttons-ket">
    <div class="top-buttons">
      <button class="simulasi">
        <a :href="getSimulationLink(currentProduct.nama_kategori)" target="_blank">
          <img src="/assets/img/business-4/projects/sample.webp" alt="Simulasi Icon">
          {{ $t('detailProduk.simulasi') }}
        </a>
      </button>

      <button class="free-sample">
        <a href="/SamplePage" target="_blank">
          <img src="/assets/img/business-4/projects/sample.webp" alt="Free Sample Icon">
          {{ $t('detailProduk.sample') }}
        </a>
      </button>

      <div class="share-dropdown">
        <button class="bagikan" @click="toggleDropdown">
          <img src="/assets/img/business-4/projects/share.webp" alt="Bagikan Icon">
          {{ $t('detailProduk.share') }}
        </button>
        
        <div v-show="isDropdownOpen" class="dropdown-content">
          <a href="#" @click="shareOnFacebook($event)" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
              <linearGradient id="Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1" x1="9.993" x2="40.615" y1="9.993" y2="40.615" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#2aa4f4"></stop>
                <stop offset="1" stop-color="#007ad9"></stop>
              </linearGradient>
              <path fill="url(#Ld6sqrtcxMyckEl6xeDdMa_uLWV5A9vXIPu_gr1)" d="M24,4C12.954,4,4,12.954,4,24s8.954,20,20,20s20-8.954,20-20S35.046,4,24,4z"></path>
              <path fill="#fff" d="M26.707,29.301h5.176l0.813-5.258h-5.989v-2.874c0-2.184,0.714-4.121,2.757-4.121h3.283V12.46 c-0.577-0.078-1.797-0.248-4.102-0.248c-4.814,0-7.636,2.542-7.636,8.334v3.498H16.06v5.258h4.948v14.452 C21.988,43.9,22.981,44,24,44c0.921,0,1.82-0.084,2.707-0.204V29.301z"></path>
            </svg>
            Facebook
          </a>
          <a href="#" @click="shareOnTwitter($event)" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 50 50">
              <path d="M 11 4 C 7.134 4 4 7.134 4 11 L 4 39 C 4 42.866 7.134 46 11 46 L 39 46 C 42.866 46 46 42.866 46 39 L 46 11 C 46 7.134 42.866 4 39 4 L 11 4 z M 13.085938 13 L 21.023438 13 L 26.660156 21.009766 L 33.5 13 L 36 13 L 27.789062 22.613281 L 37.914062 37 L 29.978516 37 L 23.4375 27.707031 L 15.5 37 L 13 37 L 22.308594 26.103516 L 13.085938 13 z M 16.914062 15 L 31.021484 35 L 34.085938 35 L 19.978516 15 L 16.914062 15 z"></path>
            </svg> 
            x
          </a>
          <a href="#" @click="shareOnInstagram($event)" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
              <!-- Instagram SVG Path -->
            </svg>
            Instagram
          </a>
          <a href="#" @click="shareOnWhatsApp($event)" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
              <!-- WhatsApp SVG Path -->
            </svg>
            WhatsApp
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
      </div>
    </div>

    <!-- Related Products -->
    <div class="kreatif-back">
      <div class="kreatif-section">
        <swiper 
          :modules="modules"
          :slides-per-view="slidesPerView"
          :space-between="30"
          :pagination="{ clickable: true }"
          class="kreatif-swiper"
        >
          <swiper-slide 
            v-for="(product, index) in detailkategori" 
            :key="index"
          >
            <div class="kreatif-item">
              <img 
                :src="getFileUrl(product.gambar1)"
                alt="Product Image" 
                class="product-image"
                @load="onImageLoad"
                :onerror="(e) => e.target.src = placeholderImage"
                loading="lazy"
              >
              <div class="kreatif-content">
                <h4>{{ product.nama_kategori }}</h4>
                <h3>
                  {{ currentLanguage === 'en' ? product.judul_en : product.judul_id }}
                </h3>
                <router-link 
                  :to="`/DetailProduk/${createSlug(currentLanguage === 'en' ? product.judul_en : product.judul_id)}`"
                >
                  <button 
                    class="read-more-button" 
                    @click="scrollToTop"
                  >
                    {{ $t('detailProduk.buttom') }}
                  </button>
                </router-link>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
    </div>

    <FooterCom />
  </div>
</template>

<script>
import axios from 'axios';
import HeaderCom from '@/components/HeaderCom.vue';
import FooterCom from '@/components/FooterCom.vue';
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { useRoute } from 'vue-router';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { metaMixin } from '@/mixins/metaMixin';

const BASE_URL = 'https://puracigarettesolutions.com:3000/api/produk';

export default {
name: 'ProductDetail',
mixins: [metaMixin],
components: {
  HeaderCom,
  FooterCom,
  Swiper,
  SwiperSlide,
},
setup() {
  const windowWidth = ref(window.innerWidth);
  const { locale } = useI18n();
  const detailkategori = ref([]);
  const postsProduk = ref([]);
  const detailProduk = ref([]);
  const route = useRoute();
  const currentIndex = ref(0);
  const isDropdownOpen = ref(false);
  const modules = [Navigation];
  const productPatents = ref([]);
  const showPatentInfo = computed(() => productPatents.value.length > 0);
   const router = useRouter();
   const showNavButtons = computed(() => windowWidth.value > 991);
   const isModalOpen = ref(false);
   const zoomedImageUrl = ref('');
   const placeholderImage = ref('/assets/img/business-4/projects/Load_produk.webp');
   const mainImageRef = ref(null);

   const zoomLevel = ref(1);
    const minZoom = 1;
    const maxZoom = 3;
    const zoomStep = 0.1;
    const panPosition = ref({ x: 0, y: 0 });
    const isDragging = ref(false);
    const startPan = ref({ x: 0, y: 0 });
    const touchStartDistance = ref(0);
    const currentProduct = ref(null);
    const { slug } = route.params
    const loading = ref(true);
    

    const updateDocumentTitle = (product) => {
      if (!product) return;
      
      const title = locale.value === 'en' ? product.judul_en : product.judul_id;
      const category = product.nama_kategori;
      
      // Remove HTML tags and clean the title
      const cleanTitle = title?.replace(/<[^>]*>/g, '')?.trim() || '';
      const cleanCategory = category?.trim() || '';
      
      // Set the page title
      document.title = `Pura MICS Products | ${cleanTitle} - ${cleanCategory}`;

      // Update Open Graph title
      let ogTitle = document.querySelector('meta[property="og:title"]');
      if (!ogTitle) {
        ogTitle = document.createElement('meta');
        ogTitle.setAttribute('property', 'og:title');
        document.head.appendChild(ogTitle);
      }
      ogTitle.setAttribute('content', `Pura MICS Products | ${cleanTitle} - ${cleanCategory} `);

      // Update meta description
      let metaDescription = document.querySelector('meta[name="description"]');
      if (!metaDescription) {
        metaDescription = document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        document.head.appendChild(metaDescription);
      }
      const description = locale.value === 'en' ? 
        (product.keterangan_en || product.deskripsi_en) : 
        (product.keterangan_id || product.deskripsi_id);
      const cleanDescription = description?.replace(/<[^>]*>/g, '')?.trim() || 
        `Premium ${cleanCategory} solutions from Pura MICS`;
      metaDescription.setAttribute('content', cleanDescription);
    };

    const createSlug = (title) => {
      if (!title) return '';
      // Remove HTML tags and clean the title
      const textOnly = title.replace(/<[^>]*>/g, '');
      return textOnly
        .toLowerCase()
        .trim()
        .replace(/[^a-z0-9-\s]/g, '')
        .replace(/\s+/g, '-')
        .replace(/-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
    };

  const slidesPerView = computed(() => {
      if (windowWidth.value <= 560) {
        return 2;
      } else if (windowWidth.value <= 991) {
        return 3;
      } else {
        return 4;
      }
    });

    const computedSlidesPerView = computed(() => {
      if (windowWidth.value <= 560) {
        return 2;
      } else if (windowWidth.value <= 991) {
        return 3;
      } else {
        return 3;
      }
    });

    const updateWindowWidth = () => {
      windowWidth.value = window.innerWidth;
    };
 
    function openImageModal(imageUrl) {
      zoomedImageUrl.value = imageUrl;
      isModalOpen.value = true;
      resetZoom();
    }

    function closeImageModal() {
      isModalOpen.value = false;
      resetZoom();
    }


    const resetZoom = () => {
      zoomLevel.value = 1;
      panPosition.value = { x: 0, y: 0 };
    };

    const zoomIn = () => {
      zoomLevel.value = Math.min(zoomLevel.value + zoomStep, maxZoom);
    };

    const zoomOut = () => {
      zoomLevel.value = Math.max(zoomLevel.value - zoomStep, minZoom);
      if (zoomLevel.value === 1) {
        resetZoom();
      }
    };

    const handleWheel = (event) => {
      event.preventDefault();
      if (event.deltaY < 0) {
        zoomIn();
      } else {
        zoomOut();
      }
    };

    const startDrag = (event) => {
      if (zoomLevel.value > 1) {
        isDragging.value = true;
        startPan.value = {
          x: event.clientX - panPosition.value.x,
          y: event.clientY - panPosition.value.y
        };
      }
    };

    const doDrag = (event) => {
      if (isDragging.value) {
        panPosition.value = {
          x: event.clientX - startPan.value.x,
          y: event.clientY - startPan.value.y
        };
      }
    };

    const stopDrag = () => {
      isDragging.value = false;
    };

    const handleTouchStart = (event) => {
      if (event.touches.length === 1) {
        startDrag(event.touches[0]);
      } else if (event.touches.length === 2) {
        touchStartDistance.value = Math.hypot(
          event.touches[0].pageX - event.touches[1].pageX,
          event.touches[0].pageY - event.touches[1].pageY
        );
      }
    };

    const handleTouchMove = (event) => {
      if (event.touches.length === 1) {
        doDrag(event.touches[0]);
      } else if (event.touches.length === 2) {
        const currentDistance = Math.hypot(
          event.touches[0].pageX - event.touches[1].pageX,
          event.touches[0].pageY - event.touches[1].pageY
        );

        if (currentDistance > touchStartDistance.value) {
          zoomIn();
        } else if (currentDistance < touchStartDistance.value) {
          zoomOut();
        }

        touchStartDistance.value = currentDistance;
      }
    };

    const handleTouchEnd = () => {
      stopDrag();
      touchStartDistance.value = 0;
    };


  const currentLanguage = computed(() => locale.value);

  const getSimulationLink = (category) => {
      if (category.toLowerCase() === 'tipping paper') {
        return '/SimulasiCtp';
      } else if (category.toLowerCase() === 'cigarette paper') {
        return '/SimulasiCp';
      } else {
        // Default link or handle other categories
        return '#';
      }
    };

  const thumbnails = computed(() => {
    if (detailProduk.value.length > 0) {
      return detailProduk.value.map(detail => detail.gambar).filter(Boolean);
    }
    return [];
  });

  const mainImageUrl = computed(() => {
  if (detailProduk.value.length > 0 && detailProduk.value[currentIndex.value]) {
    return getFileUrlix(detailProduk.value[currentIndex.value].gambar);
  }
  return placeholderImage.value;
});


const updateMetaData = async () => {
      if (!currentProduct.value) return;
      
      const context = {
        $i18n: { locale: locale.value },
        currentProduct: currentProduct.value,
        formatContent: (content) => {
          if (!content) return '';
          return content
            .replace(/<[^>]*>/g, '')
            .replace(/&nbsp;/g, ' ')
            .replace(/\s+/g, ' ')
            .trim();
        },
        createSlug: (title) => {
          if (!title) return '';
          return title
            .toLowerCase()
            .replace(/<[^>]*>/g, '')
            .replace(/[^a-z0-9-\s]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-')
            .replace(/^-+/, '')
            .replace(/-+$/, '');
        }
      };

      // Call the metaMixin's method with proper context
      await metaMixin.methods.updateMetaForDetailProduct.call(context);
    };


const staticPatents = [
  {
    id: 1,
    title: "Penghalang Tembakau Eksternal Pada Bagian Ujung Hisap Produk Rokok",
    certificate_image: "clarity.webp",
    description: "Patent for external tobacco barrier"
  },
  {
    id: 2,
    title: 'Lapis Ganda Kertas Tipping Dengan Kertas Rokok Untuk Sigaret Kretek Tangan (SKT)',
    certificate_image: "clarity.webp",
    description: "Patent for double layer tipping paper"
  },
  {
    id: 8,
    title: 'IDP000090780',
    certificate_image: "clarity.webp",
    description: "Patent IDP000090780"
  },
  {
    id: 9,
    title: 'IDS000007486',
    certificate_image: "clarity.webp",
    description: "Patent IDS000007486"
  },
  {
    id: 10,
    title: 'IDS000007188',
    certificate_image: "clarity.webp",
    description: "Patent IDS000007188"
  },
  {
    id: 3,
    title: 'Penghalang Tembakau Eksternal Pada Bagian Ujung Hisap Produk Rokok',
    certificate_image: "clarity.webp",
    description: "Patent for filter tips"
  },
  {
    id: 4,
    title: 'Pembungkus Rokok Dari Bahan Alami Untuk Rokok Sigaret Kretek Tangan (SKT)',
    certificate_image: "clarity.webp",
    description: "Patent for natural cigarette wrapper"
  },
  {
    id: 5,
    title: 'Penghalang Tembakau Dari Bahan Alami Dengan Pembungkus Kertas Pada Ujung Hisap Rokok',
    certificate_image: "clarity.webp",
    description: "Patent for natural tobacco barrier"
  },
  {
    id: 6,
    title: 'Kertas Rokok dan Kertas Tembakau Dengan Aroma dan Motif',
    certificate_image: "clarity.webp",
    description: "Patent for aromatic cigarette paper"
  },
  {
    id: 7,
    title: 'Kertas Tipping Rokok Dengan Rasa, Aroma dan Morif',
    certificate_image: "clarity.webp",
    description: "Patent for flavored tipping paper"
  }
];

const navigateToPatent = (patent) => {
  router.push({
    path: '/AboutPage',
    params: { highlightPatent: patent.title },
    query: { scrollTo: 'patents' }
  });
};

const readMore = (patent) => {
      console.log(`Read more about patent: ${patent.title}`);
      // Implement your read more functionality here
    };

    const getStatusClass = (status) => {
      return status.toLowerCase() === 'pending' ? 'status-pending' : 'status-success';
    };

    const truncateAbstract = (abstract, maxLength) => {
    if (abstract.length <= maxLength) {
      return abstract;
    }
    return abstract.slice(0, maxLength) + '...';
  };


  function updateMainImage(index) {
  currentIndex.value = index;
  // Memaksa pembaruan gambar utama
  if (mainImageRef.value) {
    mainImageRef.value.src = getFileUrlix(detailProduk.value[index].gambar);
  }
}

  function changeLanguage(lang) {
    console.log(`Changing language to: ${lang}`);
    locale.value = lang;
  }

  function getFileUrl(filename) {
  // Cek apakah filename sudah berisi URL lengkap
  if (filename && filename.startsWith('http')) {
    return filename;
  }
  // Jika tidak, tambahkan base URL
  return `https://puracigarettesolutions.com:3000/api/produk/file/${filename}`;
}

function getFileUrlix(filename) {
  if (filename && filename.startsWith('http')) {
    return filename;
  }
  return `https://puracigarettesolutions.com:3000/api/detailproduk/file/${filename}`;
}

async function getData() {
  try {
    const { slug } = route.params;
    const productResponse = await axios.get(`${BASE_URL}/TampilanProduk/${slug}`);
    
    if (productResponse.data && productResponse.data.data) {
      const product = productResponse.data.data;
      postsProduk.value = [product];
      console.log('Product data:', product);

      // Clear existing patents
      productPatents.value = [];

      // Get product ID and category
      const id_produk = product.id_produk;
      const category = product.nama_kategori.toLowerCase();
      
      console.log('Processing patents for:', {
        id_produk,
        category
      });

      // Specific ID-based patent assignments
      const idPatentMap = {
        '53': [1],
        '20': [2],
        '42': [8],
        '43': [9],
        '44': [10]
      };

      // Add patents based on specific product ID
      if (idPatentMap[id_produk]) {
        const patents = idPatentMap[id_produk].map(id => 
          staticPatents.find(p => p.id === id)
        );
        productPatents.value.push(...patents);
        console.log(`Added patents for ID ${id_produk}:`, patents);
      }

      // Add patents based on category
      if (category === 'filter tips') {
        const filterPatents = staticPatents.filter(p => p.id === 3 || p.id === 5);
        productPatents.value.push(...filterPatents);
        console.log('Added filter tips patents:', filterPatents);
      } else if (category === 'selongsong') {
        const selongsongPatent = staticPatents.find(p => p.id === 4);
        if (selongsongPatent) {
          productPatents.value.push(selongsongPatent);
          console.log('Added selongsong patent:', selongsongPatent);
        }
      } else if (category === 'cigarette paper') {
        const cigarettePatent = staticPatents.find(p => p.id === 6);
        if (cigarettePatent) {
          productPatents.value.push(cigarettePatent);
          console.log('Added cigarette paper patent:', cigarettePatent);
        }
      } else if (category === 'tipping paper') {
        const tippingPatent = staticPatents.find(p => p.id === 7);
        if (tippingPatent) {
          productPatents.value.push(tippingPatent);
          console.log('Added tipping paper patent:', tippingPatent);
        }
      }

      // Remove duplicates and undefined values
      productPatents.value = [...new Set(productPatents.value)].filter(Boolean);

      // Add certificate image paths and ensure all required fields
      productPatents.value = productPatents.value.map(patent => ({
        ...patent,
        certificateImage: `/assets/img/business-4/icon/${patent.certificate_image}`,
        description: patent.description || 'Patent description'
      }));

      console.log('Final processed patents:', productPatents.value);

      await fetchProductDetails(product.judul_en, product.judul_id);
      await getdetailkategori(product.id_kategori, product.id_produk);
    } else {
      console.error('Invalid data format from API', productResponse.data);
      postsProduk.value = [];
    }
  } catch (error) {
    console.error('Error in getData:', error);
    productPatents.value = [];
  }
}

// 3. Add watch effect to monitor patent changes
watch(productPatents, (newValue) => {
  console.log('productPatents updated:', newValue);
  console.log('showPatentInfo value:', showPatentInfo.value);
});


async function fetchProductData(slug) {
  try {
        const response = await axios.get(`${BASE_URL}/TampilanProduk/${slug}`);
        if (response.data.success) {
          currentProduct.value = response.data.data;
          await updateMetaData(); // Update meta after fetching product
          updateDocumentTitle(currentProduct.value);
          await fetchProductDetails(currentProduct.value.judul_en, currentProduct.value.judul_id);
          await getdetailkategori(currentProduct.value.id_kategori, currentProduct.value.id_produk);
        }
      } catch (error) {
        console.error('Error fetching product:', error);
        router.push('/404');
      } finally {
        loading.value = false;
      }
    }

    async function fetchProductDetails(judul_en, judul_id) {
      try {
        const slug = createSlug(currentLanguage.value === 'en' ? judul_en : judul_id);
        const response = await axios.get(`https://puracigarettesolutions.com:3000/api/detailproduk/TampilanDetailProduk/${slug}`);
        if (response.data.success) {
          detailProduk.value = response.data.data;
        }
      } catch (error) {
        console.error('Error fetching product details:', error);
      }
    }

  const processedOptions = computed(() => {
if (!postsProduk.value || postsProduk.value.length === 0) return {};

const product = postsProduk.value[0];
console.log('Processing options for product:', product); // Log produk yang diproses

return {
  printed: processOptionString(product.printed),
  flavor: processOptionString(product.flavor),
  sweetener: processOptionString(product.sweetener),
  finishing: processOptionString(product.finishing),
  size: processOptionString(product.size),
  materials: processOptionString(product.materials),
  features: processOptionString(product.features),
  diameter: processOptionString(product.diameter),
  length: processOptionString(product.length),
  special: processOptionString(product.special)
};
});

function processOptionString(optionString) {
console.log('Processing option string:', optionString); // Log string opsi yang diproses
if (!optionString) return [];
return optionString.split(',').map(option => ({
  value: option.trim(),
  label: option.trim()
}));
}

function goLeft() {
      currentIndex.value = (currentIndex.value - 1 + detailProduk.value.length) % detailProduk.value.length;
    }

    function goRight() {
      currentIndex.value = (currentIndex.value + 1) % detailProduk.value.length;
    }

    async function getdetailkategori(id_kategori, currentProductId) {
  try {
    if (!id_kategori) {
      console.log('id_kategori is undefined or null');
      detailkategori.value = [];
      return;
    }

    const response = await axios.get(`${BASE_URL}/TampilanKategori?id_kategori=${id_kategori}`);
    if (response.data && response.data.data) {
      detailkategori.value = response.data.data
        .filter(item => item.id_produk !== currentProductId)
        .map(item => ({
          ...item,
          // Pastikan gambar1 tidak memiliki duplikasi URL
          gambar1: item.gambar1 ? getFileUrl(item.gambar1) : null,
          id_produk: item.id_produk,
          judul_en: item.judul_en,
          judul_id: item.judul_id
        }));
      console.log("Updated detailkategori data:", detailkategori.value);
    } else {
      console.error('Data received is not in expected format');
      detailkategori.value = [];
    }
  } catch (err) {
    console.error('Failed to fetch data:', err);
    detailkategori.value = [];
  }
}


  function toggleDropdown() {
    isDropdownOpen.value = !isDropdownOpen.value;
  }

  function shareOnFacebook($event) {
    $event.preventDefault();
    const url = encodeURIComponent(window.location.href);
    const shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
    window.open(shareUrl, 'facebook-share-dialog', 'width=800,height=600');
  }



  function shareOnTwitter($event) {
    $event.preventDefault();
    const url = encodeURIComponent(window.location.href);
    const shareUrl = `https://twitter.com/intent/tweet?url=${url}`;
    window.open(shareUrl, 'twitter-share-dialog', 'width=800,height=600');
  }

 function shareOnInstagram($event) {
    $event.preventDefault();
    const url = encodeURIComponent(window.location.href);
    const shareUrl = `https://api.instagram.com/send?text=${url}`;
    window.open(shareUrl, '_blank');
  }

  function shareOnWhatsApp($event) {
    $event.preventDefault();
    const url = encodeURIComponent(window.location.href);
    const shareUrl = `https://api.whatsapp.com/send?text=${url}`;
    window.open(shareUrl, '_blank');
  }

  function updateDimensions() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 360) {
      slidesPerView.value = 2;
    } 
    if (screenWidth <= 480) {
      slidesPerView.value = 2;
    } else if (screenWidth <= 768) {
      slidesPerView.value = 3;
    } else {
      slidesPerView.value = 4;
    }
  }

  function scrollToTop() {
const headerElement = document.getElementById('menu-header');
if (headerElement) {
  headerElement.scrollIntoView({ behavior: 'smooth' });
} else {
  // Fallback ke scroll ke atas halaman jika elemen header tidak ditemukan
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}
}

function scrollToHeader() {
    nextTick(() => {
      const headerElement = document.getElementById('menu-header');
      if (headerElement) {
        headerElement.scrollIntoView({ behavior: 'smooth' });

      }
    });
  }

  function onImageLoad(event) {
  const img = event.target;
  if (img && img.dataset && img.dataset.src && img.src !== img.dataset.src) {
    console.log('Loading image:', img.dataset.src);
    img.src = img.dataset.src;
    img.classList.add('loaded');
  }
}

    const setupIntersectionObserver = () => {
      const observer = new IntersectionObserver(
        (entries, observer) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const img = entry.target;
              if (img && img.dataset && img.dataset.src) {
                img.src = img.dataset.src;
                img.classList.add('loaded');
                observer.unobserve(img);
              }
            }
          });
        },
        { threshold: 0.1 }
      );

      return observer;
    };

    const observeImages = () => {
      const observer = setupIntersectionObserver();
      const images = document.querySelectorAll('.main-image, .thumbnail, .product-image');
      images.forEach(img => {
        if (img && img.dataset && img.dataset.src) {
          observer.observe(img);
        }
      });
    };

    onMounted(async () => {
      await fetchProductData(slug);
      nextTick(() => {
        observeImages();
      });
      window.addEventListener('resize', updateWindowWidth);
      await getData();
      scrollToHeader();
    });

  onUnmounted(() => {
    window.removeEventListener('resize', updateDimensions);
  });

  watch(detailProduk, (newDetailProduk) => {
  console.log('Detail produk updated:', newDetailProduk);
});

watch(currentIndex, (newIndex) => {
  console.log('Current index changed:', newIndex);
  // Anda bisa menambahkan logika tambahan di sini jika diperlukan
});

  watch([detailProduk, detailkategori], () => {
      nextTick(() => {
        observeImages();
      });
    });

    onMounted(async () => {
      const { slug } = route.params;
      if (slug) {
        await fetchProductData(slug);
      }
      window.addEventListener('resize', updateWindowWidth);
    });

    watch(() => route.params.slug, async (newSlug) => {
      if (newSlug) {
        await fetchProductData(newSlug);
        scrollToTop();
      }
    });

    watch(() => locale.value, async () => {
      if (currentProduct.value) {
        await updateMetaData();
        updateDocumentTitle(currentProduct.value);
      }
    });

    watch(currentLanguage, async () => {
      if (currentProduct.value) {
        const newSlug = createSlug(
          currentLanguage.value === 'en' ? 
          currentProduct.value.judul_en : 
          currentProduct.value.judul_id
        );
        if (route.params.slug !== newSlug) {
          await router.push(`/DetailProduk/${newSlug}`);
        }
      }
    });

  watch(() => route.params.id_produk, async (newVal, oldVal) => {
if (newVal !== oldVal) {
  await getData();
  await fetchProductDetails(newVal);
  scrollToHeader();
  // Potentially other updates like resetting the currentIndex or refreshing other parts of the page
}
}, { immediate: true });

watch(currentProduct, async (newProduct) => {
      if (newProduct) {
        updateDocumentTitle(newProduct);
        await updateMetaData();
      }
    }, { immediate: true });

  return {
    mainImageUrl,
    updateMainImage,
    thumbnails,
    currentIndex,
    goLeft,
    goRight,
    detailProduk,
    toggleDropdown,
    shareOnFacebook,
    shareOnTwitter,
    shareOnInstagram,
    shareOnWhatsApp,
    isDropdownOpen,
    slidesPerView,
    getFileUrl,
    detailkategori,
    scrollToTop,
    scrollToHeader,
    changeLanguage,
    currentLanguage,
    postsProduk,
    getFileUrlix,
    processedOptions,
    getSimulationLink,
    getStatusClass,
    truncateAbstract,
    readMore,
    modules,
    computedSlidesPerView,
    productPatents,
  showPatentInfo,
   navigateToPatent,
   showNavButtons,
   isModalOpen,
      zoomedImageUrl,
      openImageModal,
      closeImageModal,
      zoomLevel,
      panPosition,
      zoomIn,
      zoomOut,
      resetZoom,
      handleWheel,
      startDrag,
      doDrag,
      stopDrag,
      handleTouchStart,
      handleTouchMove,
      handleTouchEnd,
      placeholderImage,
      onImageLoad,
      createSlug,
      currentProduct,
  };
}
};
</script>

<style scoped>

@import url('https://fonts.googleapis.com/css?family=Oswald:700&display=swap');

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  font-size: 1.2em;
  color: #666;
}

.breadcurmb-title-contact h3{
position: absolute;
width: 100%;
top: 80px;
color: #FFCA2E;
bottom: 70%;
text-align: center; /* Center text horizontally */
font-family: 'Oswald', sans-serif; /* Use the Oswald font family */
font-weight: bold; /* Use bold font weight */
font-size: 45px; /* Set the font size to 72px */
}

.breadcurmb-title-contact p {
position: relative;
width: 50%;
top: 80px;
left: 250px;
bottom: 80%;
text-align: center; /* Center text horizontally */
font-family: 'Poppins', sans-serif; /* Use the Oswald font family */
font-size: 12px; /* Set the font size to 72px */
color: #D3D3D3;
}


@media screen and (max-width: 767px) {
.breadcurmb-title-contact h3{
  width: 85%;
  top: 80px;
  left: 10%;
  bottom: 70%;
}

.breadcurmb-title-contact p {
  width: 90%;
      top: 50px;
      left: 10%;
      bottom: 80%;
}


}

.custom-select {
width: 30%;
padding: 8px 15px;
border-radius: 10px;
margin-right: 15px;
border: 2px solid #aaa;
background-color: rgb(0, 0, 0);
font-family: 'Poppins', sans-serif;
color: rgb(255, 255, 255);
font-size: 12px;
appearance: none;
}
.custom-select option {

font-family: 'Poppins', sans-serif;
color: rgb(255, 255, 255);
font-size: 12px;
}

.custom-select:focus {
border-color: #aaa;
outline: none;
}

.product-container {
display: flex;
flex-direction: row;
background-color: white;
padding: 20px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
border-radius: 8px;
max-width: 1200px;
margin-top: 100px;
margin-bottom: 100px;
margin-left: auto;
margin-right: auto;
text-align: left;
align-items: left;
flex-wrap: nowrap;
flex-direction: column;
}

.image-gallery {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f8f8f8;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
}

#main-image {
  width: 100%;
  max-width: 500px;
  height: auto;
  margin-bottom: 20px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.arrow {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
  width: 40px; /* Adjusts the arrow size for better fit */
  height: 40px; /* Make the arrow container circular */
  color: rgb(0, 0, 0); /* Arrow color */
  font-size: 40px; /* Adjust arrow size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
  border: none;
  border-radius: 50%; /* Makes the background circular */
}

/* Adjust the position to be slightly inside the image area */
.arrow-left {
  left: 20px; /* Adjusts to position left arrow */
}

.arrow-right {
  right: 20px; /* Adjusts to position right arrow */
}

.thumbnail-gallery {
  width: 100%;
  max-width: 500px;
  position: relative;
}

.swiper-container-galery {
  width: 100%;
  padding: 0 40px;
}

.thumbnail-item {
  cursor: pointer;
}

.thumbnail-item p {
  margin-top: 5px;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-weight: bold;
}

.thumbnail {
  width: 100%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s;
}


.thumbnail-item:hover .thumbnail,
.thumbnail-item.active .thumbnail {
  transform: scale(1.05);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.swiper-button-next,
.swiper-button-prev {
  color: #ffffff;
  width: 30px;
  height: 30px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-button-prev {
  top: 70px;
  left: -40px;
}

.swiper-button-next {
  top: 70px;
  right: -40px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 18px;
}

.thumbnail-content {
text-align: center;
}

.thumbnail-content p {
margin: 5px 0;
font-family: 'Poppins', sans-serif;
font-size: 12px;
}

.select-button {
background-color: #FFCA2E;
color: white;
border: none;
border-radius: 20px;
padding: 5px 10px;
font-size: 12px;
cursor: pointer;
transition: background-color 0.3s;
}

.select-button:hover {
background-color: #000000;
}


.product-info {
  flex: 1;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
}

h2 {
 font-family: 'Oswald', sans-serif;
  font-weight: bold;
  margin: 0;
  font-size: 36px;
  color: #DFA419;
}

h3 {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 50px;
  color: #000000;
}

.separator {
  width: 100%;
  height: 1px;
  margin-top: 0; /* No margin between SPESIAL INK and the separator */
  background-color: #C3C3C3; /* Adjust to desired color */
  border: none; /* Ensures no visible border */
}

.separator-paper {
  width: 100%;
  height: 1px;
  margin-top: 5px; /* No margin between SPESIAL INK and the separator */
  background-color: #C3C3C3; /* Adjust to desired color */
  border: none; /* Ensures no visible border */
}

.description-produk p{
  color: #868686;
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
}

.description h4{
  margin: 10px 0;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #000000;
}

.paper-color {
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.paper-color label {
  margin-bottom: 5px;
  font-weight: bold;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 14px;
  color: #000000;
}

.color-options {
  display: flex;
  gap: 10px;
}

.color-swatch {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 2px solid #ccc;
}

.color-swatch.white {
  background-color: #ffffff;
}

.color-swatch.gold {
  background-color: #f4c430;
}


.buttons-ket {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 10px 0;
  width: 100%;
}

.top-buttons {
  display: flex;
  gap: 10px; /* Adds spacing between "Simulasi" and "Free Sample" */
  justify-content: space-between; /* Spread buttons to the sides */
  width: 100%;
}

.share-dropdown {
  gap: 10px; /* Adds spacing between "Simulasi" and "Free Sample" */
  justify-content: space-between; /* Spread buttons to the sides */
  width: 100%;
}

button {
  padding: 12px 20px;
  border: none;
  border-radius: 20px; /* Rounded corners */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center; /* Center text and icons */
  background-color: #0000;
  color: black;
  font-size: 10px; /* Adjust the font size as required */
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  transition: background-color 0.3s ease;
  width: 100%;
}

button img {
  width: 16px;
  height: 16px;
}


button.simulasi  {
  background-color: #FFCA2E;
  color: #000000;
}

button.simulasi:hover {
  background-color: #000000;
     color: white;
}

button.free-sample {
  background-color: #FFCA2E;
  color: #000000;
}

button.free-sample:hover {
  background-color: #000000;
     color: white;
}

button.bagikan {
  background-color: #FFCA2E;
}

button.bagikan:hover {
  background-color: #000000;
     color: white;
}


/* Responsive adjustments */
@media (max-width: 768px) {
  .product-container {
      flex-direction: column;
      align-items: center;
      padding: 50px;
      margin: 50px auto 0;
  }

  .image-gallery, .product-info {
      width: 100%;
      margin-left: 0;
  }

  .product-info {
      margin-top: 20px;
  }

}

@media (max-width: 765px) {
  .product-container {
      margin-top: 30px;
  }

  h2 { font-size: 20px; }
  h3 { font-size: 24px; }

 .description h4 {
      font-size: 14px;
  }

  .description-produk {
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
  }


  .custom-select {
  margin-right: 5px;

  }

  .thumbnail-gallery {
  margin-top: 50px;
  height: 150px;
  width: 100%;

  }

  .swiper-container-galery {
      display: flex;
      justify-content: center;
      padding-left: 10px;

  }

  .top-buttons {
      flex-direction: column;
  }

  button {
      font-size: 12px;
      padding: 10px 15px;
  }


}

@media (max-width: 991px) {
.product-container {
  flex-direction: column;
}

.image-gallery {
  width: 100%;
  order: 1;
}

.product-info {
  width: 100%;
  order: 2;
  margin-left: 0;
  margin-top: 20px;
}

.thumbnail-gallery {
  margin-top: 20px;
  width: 100%;
  max-width: 100%;
}

.swiper-container-galery {
  padding-left: 0;
}

h2 {
  font-size: 28px;
}

h3 {
  font-size: 40px;
}

.description h4 {
  font-size: 18px;
}


.buttons-ket {
  flex-direction: column;
}

.top-buttons {
  flex-direction: column;
}

button {
  width: 100%;
  margin-bottom: 10px;
}
}


.kreatif-section h3 {
text-align: center;
margin-top: 30px;
margin-bottom: 25px;
font-family: 'Poppins', sans-serif;
font-weight: bold;
font-size: 16px;
color: #000000;
}

.kreatif-carousel {
display: flex;
justify-content: center;
gap: 20px;
overflow: hidden;
}



.kreatif-section {
  text-align: center;
  position: relative;
  padding: 0 60px; /* Padding to prevent edge items from touching container edges */
  margin-bottom: 20px;
}

.kreatif-item {
  width: 190px; /* Lebar tetap untuk konsistensi */
  height: 290px; /* Tinggi tetap untuk konsistensi */
  border-radius: 15px; /* Meningkatkan sudut bulat untuk tampilan yang lebih melingkar */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Bayangan yang lebih halus */
  display: flex;
  padding: 0 10px;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-bottom: 15px;
  background-color: #f8f9fa; /* Warna lebih terang atau sesuai dengan gambar */
}

.product-image {
  width: 100%; /* Menyesuaikan lebar gambar dengan container */
  height: 120px; /* Tinggi tetap untuk konsistensi */
  border-radius: 15px; /* Meningkatkan sudut bulat untuk tampilan yang lebih melingkar */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Bayangan yang lebih halus */
  object-fit: cover; /* Pastikan gambar menutupi area tanpa memotong */
}

.kreatif-content h4
{
  font-size: 14px;
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  margin-top: 20px;
  color: #333; /* Warna teks gelap untuk kontras yang lebih baik */
}

.kreatif-content h5 {

  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #000000; /* Warna teks gelap untuk kontras yang lebih baik */

}

.read-more-button {
  background-color: #FFCA2E; /* Warna tombol biru */
  color: white;
  border-radius: 20px; /* Tombol dengan sudut yang lebih bulat */
  padding: 8px 10px;
  width: 95%;
  align-items: center;
  justify-content: center;
  font-size: 10px; /* Ukuran font lebih besar untuk keterbacaan */
  margin-top: 10px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.read-more-button:hover {
  background-color: #000000; /* Warna lebih gelap saat hover */
  color: white;
}



.dropdown-content {
display: none;
position: absolute;
background-color: #f9f9f9;
min-width: 160px;
box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
z-index: 3;
display: flex; /* Align icons horizontally */
flex-direction: column;
}

.dropdown-content a {
color: black;
padding: 12px 16px;
text-decoration: none;
display: block;
}

.dropdown-content a img {
width: 20px; /* Adjust size as needed */
}

.dropdown-content a:hover {
background-color: #a5a5a5;
}


@media (max-width: 1300px) and (min-width: 1281px) {

  .kreatif-section h3 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 14px;
    color: #000000;
}

.custom-select {
  width: 28%;
  padding: 8px 15px;
  border-radius: 10px;
  margin-right: 15px;
  border: 2px solid #aaa;
  background-color: rgb(0, 0, 0);
  font-family: 'Poppins', sans-serif;
  color: rgb(255, 255, 255);
  font-size: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.kreatif-section {
text-align: center;
position: relative;
padding: 0 60px; /* Padding to prevent edge items from touching container edges */
margin-bottom: 20px;
}

.kreatif-item {
width: 170px;
height: 300px; /* Tinggi tetap untuk konsistensi */
border-radius: 15px; /* Meningkatkan sudut bulat untuk tampilan yang lebih melingkar */
box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Bayangan yang lebih halus */
display: flex;
padding: 0 10px;
flex-direction: column;
align-items: center;
margin: auto;
background-color: #f8f9fa; /* Warna lebih terang atau sesuai dengan gambar */
}

.product-image {
width: 100%; /* Menyesuaikan lebar gambar dengan container */
height: 130px; /* Tinggi tetap untuk konsistensi */
border-radius: 15px; /* Meningkatkan sudut bulat untuk tampilan yang lebih melingkar */
box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15); /* Bayangan yang lebih halus */
object-fit: cover; /* Pastikan gambar menutupi area tanpa memotong */
}

.kreatif-content h4
{
font-size: 20px;
font-family: 'Oswald', sans-serif;
font-weight: bold;
margin-top: 20px;
color: #333; /* Warna teks gelap untuk kontras yang lebih baik */
}

.kreatif-content h5 {

font-size: 22px;
font-family: 'Poppins', sans-serif;
font-weight: bold;
color: #000000; /* Warna teks gelap untuk kontras yang lebih baik */

}

.read-more-button {
background-color: #FFCA2E; /* Warna tombol biru */
color: white;
border-radius: 20px; /* Tombol dengan sudut yang lebih bulat */
padding: 8px 10px;
width: 95%;
align-items: center;
justify-content: center;
font-size: 12px; /* Ukuran font lebih besar untuk keterbacaan */
margin-top: 10px;
cursor: pointer;
transition: background-color 0.3s, color 0.3s;
}
}

@media (max-width: 991px) {

.kreatif-section h3 {
  margin-left: 10px; /* Adjust left margin for better alignment */

}

.product-container {
  flex-direction: column;
  align-items: center;
  padding: 50px;
  margin-left: 0;
  flex-wrap: nowrap;
flex-direction: column;
}



.image-gallery {
  width: 100%;
  margin-bottom: 20px;
}

#main-image {
  max-width: 100%;
  height: auto;
}

.product-info {
  width: 100%;
  margin-left: 0;
  margin-top: 60px;
}


}


/* Media Queries */
/* Tablets */
@media (max-width: 768px) {

  .kreatif-section h3 {
      margin-left: 10px; /* Adjust left margin for better alignment */

  }

  .image-gallery {
      width: 100%;
      margin-bottom: 0;
  }

  #main-image {
      max-width: 100%;
      height: auto;
  }

  .product-info {
      width: 100%;
      margin-left: 0;
      margin-top: 60px;
  }


}

/* Phones */
/* Phones */
@media (max-width: 765px) and (min-width: 700px) {

  
.kreatif-section h3 {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #000000;
}

  .product-info {
      margin-top: 60px;
  }

  
  .kreatif-item {
      width: 350px; /* Make it slightly narrower to fit on tablet screens */
      height: 250px;
      margin: 25px 0;
  }


  .product-image {
      height: 130px;
      width: 100%;
  }

 .kreatif-content h4 {
  font-size: 15px;
}

  .value-button {
      padding: 8px 10px; /* Smaller padding for buttons */
      font-size: 10px; /* Smaller font size for buttons */
  }

  
  .kreatif-item {
      width: 100%; /* Make it slightly narrower to fit on tablet screens */
      height: 250px;
      margin: 15px 0;
  }


  .read-more-button {
      padding: 5px 8px;
      font-size: 10px; /* Adjust read more button size */
  }

  .read-more-button {
      margin-right: 10px;
      font-size: 8px;
      margin-bottom: 20px;
  }
}


@media (max-width: 699px) and (min-width: 520px){

  
.kreatif-section h3 {
text-align: center;

}

  .product-info {
      margin-top: 60px;
  }

  .product-image {
      height: 130px;
      width: 100%;
  }

 .kreatif-content h4 {
  font-size: 15px;
}

  .value-button {
      padding: 8px 10px; /* Smaller padding for buttons */
      font-size: 10px; /* Smaller font size for buttons */
  }

  .read-more-button {
      padding: 5px 8px;
      font-size: 10px; /* Adjust read more button size */
  }

  .read-more-button {
      margin-right: 10px;
      font-size: 8px;
      margin-bottom: 20px;
  }
}


@media only screen and (max-width: 760px) and (min-width: 480px) {

.kreatif-section h3 {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: #000000;
}

  .product-info {
      margin-top: 60px;
  }

  .product-image {
      height: 130px;
      width: 100%;
  }

 .kreatif-content h4 {
  font-size: 15px;
}

  .value-button {
      padding: 8px 10px; /* Smaller padding for buttons */
      font-size: 10px; /* Smaller font size for buttons */
  }
  

  .read-more-button {
      padding: 5px 8px;
      font-size: 10px; /* Adjust read more button size */
  }

  .read-more-button {
      margin-right: 10px;
      font-size: 8px;
      margin-bottom: 20px;
  }
}

@media only screen and (max-width: 479px) {
.kreatif-section h3 {
font-size: 10px;
}

.kreatif-content h4 {
font-size: 10px;
}

.kreatif-content h5 {
font-size: 10px;
}

}

@media only screen and (max-width: 360px) {
.kreatif-section h3 {
font-size: 10px;
}

.kreatif-item {
width: calc(100% - -15px);
margin: 10px;
height: 230px;
margin-left: -5px;
margin-top: 20px;
margin-bottom: 20px;
}

.product-image {
height: 90px;
}

.kreatif-content h4 {
font-size: 10px;
}

.kreatif-content h5 {
font-size: 10px;
}


}


.kreatif-swiper {
  padding: 20px 15px 50px; /* Add horizontal padding */
  margin: 0 5%;
}

.kreatif-back {
  padding: 0 170px;
}


@media (max-width: 1024px) {
  .slidesPerView {
    value: 4;
  }
}



@media (max-width: 480px) {
  .slidesPerView {
    value: 2;
  }
}

@media only screen and (min-width: 1921px) { 

  .kreatif-item {
    width: 260px;
    height: 350px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 0 10px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 15px;
    background-color: #f8f9fa;
}

.kreatif-content h4 {
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    margin-top: 20px;
    color: #333;
}

.kreatif-section h3 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 18px;
    color: #000000;
}

.product-image {
    width: 100%;
    height: 150px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    -o-object-fit: cover;
    object-fit: cover;
}

.kreatif-section h3 {
        text-align: center;
        margin-top: 30px;
        margin-bottom: 25px;
        font-family: 'Poppins', sans-serif;
        font-weight: bold;
        font-size: 20px;
        color: #000000;
    }

    .main-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

}

@media only screen and (max-width: 1920px) and (min-width: 1800px)  {
  .kreatif-item {
    width: 270px;
    height: 350px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 0 10px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 15px;
    background-color: #f8f9fa;
}

.product-image {
    width: 100%;
    height: 140px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    -o-object-fit: cover;
    object-fit: cover;
}


.kreatif-section h3 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
}

.main-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

}

@media only screen and (max-width: 1799px) and (min-width: 1600px)  {

  .kreatif-item {
    width: 250px;
    height: 330px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 0 10px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 15px;
    background-color: #f8f9fa;
}

.kreatif-section h3 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: #000000;
}

.main-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

}



@media only screen and (max-width: 1599px) and (min-width: 1441px)  {
  .product-image {
    width: 100%;
    height: 150px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    -o-object-fit: cover;
    object-fit: cover;
}

.kreatif-item {
    width: 210px;
    height: 300px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 0 10px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 15px;
    background-color: #f8f9fa;
}

.main-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

}

@media only screen and (max-width: 1280px) and (min-width: 1025px) {

  .kreatif-item {
    width: 160px;
    height: 300px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 0 10px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 15px;
    background-color: #f8f9fa;
}

.kreatif-section h3 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
}

.main-image {
  width: 100%;
  height: auto;
  border-radius: 15px;
}

}


@media only screen and (max-width: 1024px) and (min-width: 991px) {

  .product-container {
    display: flex;
    flex-direction: row;
    background-color: white;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    max-width: 1200px;
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: auto;
    margin-right: 20px;
    text-align: left;
    align-items: left;
    flex-wrap: nowrap;
flex-direction: column;
}

h2 {
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    margin: 0;
    font-size: 20px;
    color: #DFA419;
}

h3 {
    font-family: 'Poppins';
    font-weight: bold;
    font-size: 30px;
    color: #000000;
}

.custom-select {
    width: 30%;
    padding: 8px 15px;
    border-radius: 10px;
    margin-right: 8px;
    border: 2px solid #aaa;
    background-color: rgb(0, 0, 0);
    font-family: 'Poppins', sans-serif;
    color: rgb(255, 255, 255);
    font-size: 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

button img {
    width: 8px;
    height: 8px;
}

button {
    padding: 12px 20px;
    border: none;
    border-radius: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0000;
    color: black;
    font-size: 8px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    transition: background-color 0.3s ease;
    width: 100%;
}

.separator-paper {
    width: 100%;
    height: 1px;
    margin-top: 20px;
    background-color: #C3C3C3;
    border: none;
}

.kreatif-section {
    text-align: center;
    position: relative;
    padding: 0 0;
    margin-bottom: 20px;
}


.kreatif-back {
    padding: 0 0;
}

.kreatif-section h3 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
}

.main-image {
  width: 90%;
  height: auto;
  border-radius: 15px;
}


}

@media only screen and (max-width: 991px) and (min-width: 765px) {

  .kreatif-section h3 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 16px;
    color: #000000;
}

.kreatif-back {
    padding: 0 100px;
}

.kreatif-section {
    text-align: center;
    position: relative;
    padding: 0 0;
    margin-bottom: 20px;
}



.thumbnail-item {
  cursor: pointer;
}

.kreatif-item {
    width: 150px;
    height: 290px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 0 10px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 15px;
    background-color: #f8f9fa;
}

.product-container {

    margin-top: 30px;

}

.main-image{
  width: calc(100%);
    height: auto;
    border-radius: 15px;
}

}

@media only screen and (max-width: 760px) and (min-width: 481px) {

  .kreatif-back {
    padding: 0 0;
}

.kreatif-item {
        width: 100%;
        height: 240px;
        margin: 15px 0;
    }


    .thumbnail-item {
      cursor: pointer;
}

}

@media only screen and (max-width: 480px) and (min-width: 360px) {

  .kreatif-back {
    padding: 0 0px;
}

.product-image {
    width: 100%;
    height: 100px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    -o-object-fit: cover;
    object-fit: cover;
}

.kreatif-item {
    width: 150px;
    height: 230px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    display: flex;
    padding: 0 10px;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-bottom: 15px;
    background-color: #f8f9fa;
}

.read-more-button {
        font-size: 8px;
        padding: 2px 10px;
    }



.thumbnail-item {
  cursor: pointer;
}

.kreatif-section {
    text-align: center;
    position: relative;
    padding: 0 0;
    margin-bottom: 20px;
}

.our-patents-container {
    padding: 0px 0;
    background-color: #ffffff;
}


h3 {
        font-size: 24px;
    }

    h2 {
        font-size: 18px;
    }
  
    .product-container {
        margin-top: -15px;
    }

    .kreatif-section h3 {
      font-size: 12px;
    }

}


@media only screen and (max-width: 359px) {
  .thumbnail-item {
    cursor: pointer;
}


    .kreatif-back {
    padding: 0 0;
}

.kreatif-section {
    text-align: center;
    position: relative;
    padding: 0 0;
    margin-bottom: 20px;
}

.read-more-button {
    background-color: #FFCA2E;
    color: white;
    border-radius: 20px;
    padding: 8px 10px;
    width: 95%;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    margin-top: 10px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

}

.patent-info {
  background-color: #ffffff;
  border-radius: 8px;
}

.patent-info h4 {
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  color: #000000;
  margin-bottom: 20px;
}

.patent-item {
  padding-bottom: 20px;
  border-bottom: 1px solid #ffffff;
}

.patent-item:last-child {
  border-bottom: none;
}

.patent-content {
  display: flex;
  align-items: flex-start;
}

.certificate-image {
  width: 40px;
  height: auto;
  margin-right: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.patent-details {
  flex: 1;
}

.patent-details h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 14px;
  color: #000000;
}


@media (max-width: 768px) {
  
  .certificate-image {
    width: 100%;
    max-width: 70px;
    margin-bottom: 15px;
    margin-right: 0;
  }
  .patent-content {
  display: flex;
  align-items: flex-start;
}


.patent-info {
  background-color: #ffffff;
  border-radius: 8px;
}

.patent-info h4 {
  font-family: 'Oswald', sans-serif;
  font-size: 16px;
  color: #000000;
  margin-bottom: 20px;
}

.patent-item {
  padding-bottom: 20px;
  border-bottom: 1px solid #ffffff;
}

.patent-item:last-child {
  border-bottom: none;
}

.patent-details {
  flex: 1;
  margin-top: 20px;
  margin-left: 10px;
}

}

@media (max-width: 768px) {

  .patent-details {
        flex: 1;
        margin-top: 0px;
        margin-left: 15px;
    }

    .certificate-image {
        width: 100%;
        max-width: 45px;
        margin-bottom: 15px;
        margin-right: 0;
    }
}

.image-gallery {
  display: flex;
    align-items: center;
    flex-direction: column;
}

.main-image-container {
  position: relative;
  width: 100%;
  max-width: 950px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav-button {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  cursor: pointer;
  z-index: 10;
}

.nav-button.prev { left: -30px;; }
.nav-button.next { right: -30px; }

.thumbnail-gallery {
  width: 100%;
  max-width: 500px;
}

.thumbnail-item {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 8px;
  overflow: hidden;
  transition: border-color 0.3s ease;
}

.thumbnail-item.active {
  border-color: gold;
}

.thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .main-image-container {
    flex-direction: column;
  }
  
  .main-image {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .nav-button.prev { left: 10px; }
  .nav-button.next { right: 10px; }
}


.modal {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  width: 90%;
  max-width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.zoomed-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  transition: transform 0.3s ease-out;
}

.close-button {
    position: absolute;
    top: -20px;
    right: -380px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background: none;
    border: none;
    cursor: pointer;
}

.zoom-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.zoom-controls button {
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  padding: 10px 15px;
  font-size: 18px;
  cursor: pointer;
  border-radius: 5px;
}

.value-options {
  align-items: center;
  text-align: center;
}



@media (max-width: 768px) {
  .modal-content {
    width: 95%;
  }

  .zoom-controls {
    bottom: 10px;
  }

  zoom-controls button {
        padding: 4px 8px;
        font-size: 8px;
    }
}
</style>