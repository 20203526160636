<template>
 <section id="sertifikat" class="apbc-team-section">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 offset-lg-2">
                    <div class="apbc-title-area text-center wow fadeInUp">
                        <div class="apbc-headline">
                            <h4>{{ $t('awardPage.title') }}</h4>
                            <div class="gold-bar-certif"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="custom-apbc-team-single">
  <div class="custom-apbc-img-wrapper text-center" 
       v-for="(image, index) in images" 
       :key="index" 
       @click="openModalWithImage(image)" 
       style="cursor: pointer;">
    <img :src="image.src" :alt="'Image ' + image.id" class="gambarser">
    <p class="image-description">{{ image.description }}</p>
    <p class="nomer">{{ image.nomer }}</p>
  </div>
</div>

<transition name="fade">
  <div v-if="showModal" class="modal-overlay" @click.self="showModal = false" style="z-index: 1050;">
    <div class="modal-content" @click.stop>
      <span class="close-button" @click="showModal = false">&times;</span>
      <img :src="activeImage" alt="Active Image" style="max-width: 100%;">
    </div>
  </div>
</transition>

</template>

<script>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'CertificateSection',
  setup() {
    const { t } = useI18n();
    const showModal = ref(false);
    const activeImage = ref('');

    const images = ref([
      {
        id: 'ECOVADIS',
        src: 'assets/img/business-4/team/ECOVADIS.svg',
        certificateSrc: 'assets/img/business-4/team/ECOVADIS POP UP.webp',
        description: computed(() => t('awardPage.ecovadis'))
      },
      {
        id: 'HALAL',
        src: 'assets/img/business-4/team/HALAL.svg',
        certificateSrc: 'assets/img/business-4/team/Sertifikat_Halal.webp',
        description: computed(() => t('awardPage.halal')),
        nomer: 'No. ID00410000156580921'
      },
      {
        id: 'ISO',
        src: 'assets/img/business-4/team/ISO.svg',
        certificateSrc: 'assets/img/business-4/team/Sertifikat_ISO.webp',
        description: computed(() => t('awardPage.iso')),
        nomer: 'No. QSC 00119'
      },
    ]);

    const openModalWithImage = (image) => {
      activeImage.value = image.certificateSrc;
      showModal.value = true;
    };

    const closeModal = () => {
      showModal.value = false;
    };

    return {
      images,
      showModal,
      activeImage,
      openModalWithImage,
      closeModal,
    };
  },
};
</script>


<style scoped>
.apbc-team-section {
  margin-bottom: 50px;
}

.apbc-headline h4 {
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  font-size: 30px;
  color: #000000;
  margin-bottom: 15px;
}

.gold-bar-certif {
  width: 20%;
  height: 5px;
  background-color: #FFCA2E;
  position: absolute;
  margin-left: 300px;
  top: 100%;
}

.custom-apbc-team-single {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 50px;
  width: 103%;
}

.custom-apbc-img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 30px 15px 0;
  width: 200px;
  height: auto;
}

.custom-apbc-img-wrapper img {
  width: 100%;
  height: auto;
  object-fit: cover;
  margin-left: -15px;
}

.custom-apbc-img-wrapper .image-description {
  margin-top: 5px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #4A4A4A;
  text-align: center;
  width: calc(100% + 15px);
  margin-left: -18px;
}

.custom-apbc-img-wrapper .nomer {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  color: #4A4A4A;
  text-align: center;
  width: calc(100% + 15px);
  margin-left: -25px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: auto;
  max-width: 250px;
}

.close-button {
  color: #aaa;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media only screen and (max-width: 1024px) and (min-width: 992px) {
  .gold-bar-certif {
    width: 20%;
    height: 5px;
    background-color: #FFCA2E;
    position: absolute;
    margin-left: 250px;
    top: 100%;
}
}

@media (max-width: 991px) and (min-width: 768px) {
  .gold-bar-certif {
    margin-left: 280px;
    width: 20%;
  }
  
}

@media (max-width: 767px) and (min-width: 481px) {
  .gold-bar-certif {
    margin-left: 210px;
    width: 20%;
  }

  .apbc-headline h4 {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .custom-apbc-team-single {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .custom-apbc-img-wrapper {
    margin: 15px auto;
    width: 100%;
    max-width: 200px;
  }

  .custom-apbc-img-wrapper img {
    margin-left: 0;
  }

  .custom-apbc-img-wrapper .image-description,
  .custom-apbc-img-wrapper .nomer {
    width: 100%;
    margin-left: 0;
    font-size: 12px;
  }

  .gold-bar-certif {
    margin-left: 150px;
    width: 20%;
  }

  .apbc-headline h4 {
    font-size: 20px;
  }

  .modal-content {
    width: 310px;
  }

  .close-button {
    top: 0px;
    right: 5px;
  }
}

@media (max-width: 360px) {
  .apbc-headline h4 {
    font-size: 18px;
  }

  .custom-apbc-img-wrapper .image-description,
  .custom-apbc-img-wrapper .nomer {
    font-size: 10px;
  }

  .gold-bar-certif {
        margin-left: 115px;
        width: 30%;
    }
}
</style>