<!-- src/components/Login.vue -->
<template>
  <div class="login-page">
    <div class="login-box">
      <!-- /.login-logo -->
      <div class="card card-outline card-primary">
        <div class="card-header text-center">
          <img src="assets/img/business-4/LOGO_Black.png" width="50px" height="50px">
        </div>
        <div class="card-body">
          <p class="login-box-msg">LOGIN</p>

          <form @submit.prevent="login">
            <div class="input-group mb-3">
              <input v-model="username" type="text" class="form-control" placeholder="Username" required>
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            <div class="input-group mb-3">
              <input v-model="password" type="password" class="form-control" placeholder="Password" required
                autocomplete="current-password">
              <div class="input-group-append">
                <div class="input-group-text">
                  <span class="fas fa-lock"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <!-- /.col -->
              <div class="col-4">
                <button type="submit" :disabled="!isValidForm || loading"
                  class="form-control btn btn-primary rounded submit px-3">
                  {{ loading ? 'Signing In...' : 'Sign In' }}
                </button>
              </div>
              <!-- /.col -->
            </div>
          </form>
          <!-- /.social-auth-links -->
          <br>
        </div>
        <!-- /.card-body -->
      </div>
      <!-- /.card -->
    </div>
  </div>
</template>

<script>
import store from '../store';

export default {
  name: 'UserLogin',
  data() {
    return {
      username: '',
      password: '',
      errorMessage: '',
      loading: false,
      isValidForm: false,
    };
  },
  watch: {
    username() {
      this.validateForm();
    },
    password() {
      this.validateForm();
    },
  },
  methods: {
    validateForm() {
      this.isValidForm = !!this.username && !!this.password;
    },
    async login() {
      this.errorMessage = '';
      this.loading = true;

      try {
        const response = await this.$axios.post('https://puracigarettesolutions.com:3000/api/users/login', {
          username: this.username,
          password: this.password,
        });

        if (response.data.success) {
          // Simpan token ke local storage
          localStorage.setItem('token', response.data.token);
          // Atur sesi pengguna atau redirect ke halaman yang tepat
          store.commit('SET_LOGGED_IN', true); // Memanggil mutasi
          store.commit('SET_USER_ROLE', response.data.role); // Menyimpan peran pengguna
          this.$router.push({ name: 'DashboardDS' });
        } else {
          this.errorMessage = response.data.message || 'Login gagal';
        }
      } catch (error) {
        console.error(error);
        if (error.code === 'ECONNABORTED') {
          this.errorMessage = 'Koneksi timeout. Silakan coba lagi.';
        } else if (error.message === 'Network Error') {
          this.errorMessage = 'Terjadi kesalahan jaringan. Periksa koneksi Anda dan coba lagi.';
        } else {
          this.errorMessage = error.response?.data?.message || 'Terjadi kesalahan saat login.';
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.login-page,
.register-page {
  -ms-flex-align: center;
  align-items: center;
  background-color: #e9ecef;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  -ms-flex-pack: center;
  justify-content: center;
}
</style>