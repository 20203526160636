<template>
  <div class="wrapper">
    <NavbarDs />
    <aside class="main-sidebar sidebar-dark-primary elevation-4">
      <a href="index3.html" class="brand-link">
        <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo" class="brand-image img-circle elevation-3" style="opacity: .8">
        <span class="brand-text font-weight-light">PURA MISC</span>
      </a>
      <SidebarDs />
    </aside>
    <div class="content-wrapper">
      <section class="content">
        <div class="container-fluid">
          <div class="card card-primary card-outline">
            <div class="card-header">
              <div class="col-6">
                <h5>Tulisan Baru</h5>
              </div>
              <div class="text-right">
                <router-link to="/TampilanArtikel" class="btn btn-primary" style="color: #ffffff;">
                  <i class="fas fa-angle-left"></i> Kembali
                </router-link>
              </div>
            </div>
            <form @submit.prevent="update" class="card-body">
              <div class="form-group">
                <input type="hidden" id="id_artikel" v-model="post.id_artikel" class="form-control" placeholder="Enter Post Title" required>
                <label for="postTitle">Title EN:</label>
                <ckeditor :editor="editor" v-model="post.judul_en" :config="editorConfig"></ckeditor>
              </div>
              <div class="form-group">
                <label for="postTitle">Title ID:</label>
                <ckeditor :editor="editor" v-model="post.judul_id" :config="editorConfig"></ckeditor>
              </div>
              
              <select 
  id="id_kategori" 
  v-model="post.id_kategori" 
  class="form-control"
  :class="{ 'is-invalid': errors && errors.id_kategori }"
  required
>
  <option value="">Select Category</option>
  <option 
    v-for="category in kategori"
    :key="category.id_kategori"
    :value="category.id_kategori"
  >
    {{ category.nama_kategori }}
  </option>
</select>


              <div class="form-group">
                <label for="author">Tanggal:</label>
                <input type="date" id="tanggal" v-model="post.tanggal" class="form-control" required>
              </div>
              <div class="form-group">
                <label for="articleContent">Deskripsi EN:</label>
                <ckeditor :editor="editor" v-model="post.deskripsi_en" :config="editorConfig"></ckeditor>
              </div>
              <div class="form-group">
                <label for="articleContent">Deskripsi ID:</label>
                <ckeditor :editor="editor" v-model="post.deskripsi_id" :config="editorConfig"></ckeditor>
              </div>
              <div class="form-group">
                <label for="articleContent">Content EN:</label>
                <ckeditor :editor="editor" v-model="post.keterangan_en" :config="editorConfig"></ckeditor>
              </div>
              <div class="form-group">
                <label for="articleContent">Content ID:</label>
                <ckeditor :editor="editor" v-model="post.keterangan_id" :config="editorConfig"></ckeditor>
              </div>
              <div class="form-group">
                <label for="articleContent">Content 2 EN:</label>
                <ckeditor :editor="editor" v-model="post.keterangan_en2" :config="editorConfig"></ckeditor>
              </div>
              <div class="form-group">
                <label for="articleContent">Content 2 ID:</label>
                <ckeditor :editor="editor" v-model="post.keterangan_id2" :config="editorConfig"></ckeditor>
              </div>
              <div class="form-group">
                <label for="author">Pembuat:</label>
                <input type="text" id="pembuat" v-model="post.pembuat" class="form-control" required>
              </div>
              <div class="form-group">
              <label for="customTags">Custom Tags:</label>
              <select id="keyword" ref="keyword" multiple class="form-control">
                <option v-for="tag in post.keyword" :value="tag" :key="tag">{{ tag }}</option>
              </select>
            </div>
              <div class="form-group">
                <label for="author">Referensi 1 :</label>
                <input type="text" id="link_ref1" v-model="post.link_ref1" class="form-control">
              </div>
              <div class="form-group">
                <label for="author">Referensi 2 :</label>
                <input type="text" id="link_ref2" v-model="post.link_ref2" class="form-control">
              </div>
              <div class="form-group">
                <label for="author">Referensi 3 :</label>
                <input type="text" id="link_ref3" v-model="post.link_ref3" class="form-control">
              </div>
              <div class="image-upload-section">
        <!-- Home Image -->
        <div class="form-group">
          <label for="imageUpload1" class="form-label">
            Gambar untuk Home 
            <span class="text-muted">(Maks. 5MB - Format: JPG, PNG, GIF, WEBP)</span>
          </label>
          <div class="image-upload-container">
            <input 
              type="file" 
              id="imageUpload1"
              @change="(e) => handleFileUpload(e, 1)" 
              accept="image/jpeg,image/png,image/gif,image/webp"
              class="form-control"
              :disabled="isUploading"
            />
            <div v-if="isUploading && uploadingImageNumber === 1" class="upload-progress">
              Uploading... Please wait.
            </div>
            <div class="preview-container mt-2" v-if="post.gambar1 || imagePreview[1]">
              <img 
                :src="imagePreview[1] || getFileUrl(post.gambar1)" 
                class="preview-image"
                alt="Preview Home"
                @error="handleImageError(1)"
              />
              <button 
                type="button" 
                class="btn btn-danger btn-sm remove-image"
                @click="removeImage(1)"
                :disabled="isUploading"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div v-if="imageErrors[1]" class="text-danger mt-1">
              {{ imageErrors[1] }}
            </div>
          </div>
        </div>

        <!-- List Image -->
        <div class="form-group">
          <label for="imageUpload2" class="form-label">
            Gambar untuk List Artikel
            <span class="text-muted">(Maks. 5MB - Format: JPG, PNG, GIF, WEBP)</span>
          </label>
          <div class="image-upload-container">
            <input 
              type="file" 
              id="imageUpload2"
              @change="(e) => handleFileUpload(e, 2)" 
              accept="image/jpeg,image/png,image/gif,image/webp"
              class="form-control"
              :disabled="isUploading"
            />
            <div v-if="isUploading && uploadingImageNumber === 2" class="upload-progress">
              Uploading... Please wait.
            </div>
            <div class="preview-container mt-2" v-if="post.gambar2 || imagePreview[2]">
              <img 
                :src="imagePreview[2] || getFileUrl(post.gambar2)" 
                class="preview-image"
                alt="Preview List"
                @error="handleImageError(2)"
              />
              <button 
                type="button" 
                class="btn btn-danger btn-sm remove-image"
                @click="removeImage(2)"
                :disabled="isUploading"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div v-if="imageErrors[2]" class="text-danger mt-1">
              {{ imageErrors[2] }}
            </div>
          </div>
        </div>

        <!-- Detail Image -->
        <div class="form-group">
          <label for="imageUpload3" class="form-label">
            Gambar untuk Detail Artikel
            <span class="text-muted">(Maks. 5MB - Format: JPG, PNG, GIF, WEBP)</span>
          </label>
          <div class="image-upload-container">
            <input 
              type="file" 
              id="imageUpload3"
              @change="(e) => handleFileUpload(e, 3)" 
              accept="image/jpeg,image/png,image/gif,image/webp"
              class="form-control"
              :disabled="isUploading"
            />
            <div v-if="isUploading && uploadingImageNumber === 3" class="upload-progress">
              Uploading... Please wait.
            </div>
            <div class="preview-container mt-2" v-if="post.gambar3 || imagePreview[3]">
              <img 
                :src="imagePreview[3] || getFileUrl(post.gambar3)" 
                class="preview-image"
                alt="Preview Detail"
                @error="handleImageError(3)"
              />
              <button 
                type="button" 
                class="btn btn-danger btn-sm remove-image"
                @click="removeImage(3)"
                :disabled="isUploading"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div v-if="imageErrors[3]" class="text-danger mt-1">
              {{ imageErrors[3] }}
            </div>
          </div>
        </div>
      </div>

      <!-- Submit Button -->
      <div class="form-group mt-4">
        <button 
          type="submit" 
          class="btn btn-primary"
          :disabled="isUploading || isSubmitting"
        >
          {{ isSubmitting ? 'Updating...' : 'Update Article' }}
        </button>
      </div>
    </form>
  </div>
        </div>
      </section>
    </div>
    <FooterDs />
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Content here -->
    </aside>
  </div>
</template>

<script>
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import { ref, reactive, onMounted, nextTick } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import axios from 'axios';
import jQuery from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: 'EditArtikel',
  components: {
    FooterDs,
    NavbarDs,
    SidebarDs,
    ckeditor: CKEditor.component
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    
    // State
    const post = reactive({
      id_artikel: '',
      judul_en: '',
      judul_id: '',
      tanggal: '',
      id_kategori: '',
      keterangan_en: '',
      keterangan_id: '',
      keterangan_en2: '',
      keterangan_id2: '',
      deskripsi_en: '',
      deskripsi_id: '',
      pembuat: '',
      keyword: [],
      link_ref1: '',
      link_ref2: '',
      link_ref3: '',
      link_ref4: '',
      gambar1: null,
      gambar2: null,
      gambar3: null
    });

    const kategori = ref([]);
    const imagePreview = ref({
      1: null,
      2: null,
      3: null
    });
    const imageFiles = ref({
      1: null,
      2: null,
      3: null
    });
    const imageErrors = ref({
      1: null,
      2: null,
      3: null
    });
    const isUploading = ref(false);
    const isSubmitting = ref(false);
    const uploadingImageNumber = ref(null);
    const errors = ref({});

    // Editor config
    const editorConfig = {
      toolbar: [
        'heading',
        '|',
        'bold',
        'italic',
        'strikethrough',
        'underline',
        'fontColor',
        'link',
        'bulletedList',
        'numberedList',
        'imageUpload',
        'mediaEmbed',
        '|',
        'undo',
        'redo'
      ]
    };

    // Methods
    const getFileUrl = (filename) => {
      if (!filename) return '';
      return `https://puracigarettesolutions.com:3000/api/artikel/file/${filename}`;
    };

    const fetchArticle = async (id) => {
      try {
        const response = await axios.get(`https://puracigarettesolutions.com:3000/api/artikel/TampilanArtikel/${id}`);
        if (response.data.success && response.data.data) {
          const articleData = response.data.data;
          
          // Update post data
          Object.keys(post).forEach(key => {
            if (key === 'keyword') {
              post[key] = articleData[key] ? 
                (typeof articleData[key] === 'string' ? 
                  articleData[key].split(',') : 
                  articleData[key]) : 
                [];
            } else {
              post[key] = articleData[key] || post[key];
            }
          });

          // Initialize select2 after data is loaded
          nextTick(() => {
            initializeSelect2();
          });
        }
      } catch (error) {
        console.error('Error fetching article:', error);
        alert('Failed to load article data');
      }
    };

    const fetchKategori = async () => {
      try {
        const response = await axios.get('https://puracigarettesolutions.com:3000/api/kategori/TampilanKategori');
        if (response.data.success) {
          kategori.value = response.data.data;
        }
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    const initializeSelect2 = () => {
      if (!post.keyword) post.keyword = [];
      
      jQuery('#keyword').select2({
        tags: true,
        tokenSeparators: [',', '\n'],
        theme: 'classic',
        data: post.keyword.map(tag => ({ id: tag, text: tag }))
      }).on('change', function() {
        const selected = jQuery(this).select2('data');
        post.keyword = selected.map(item => item.id);
      });
    };

    const handleFileUpload = async (event, imageNumber) => {
      const file = event.target.files[0];
      if (!file) return;

      try {
        imageErrors.value[imageNumber] = null;
        
        // Validate file
        if (file.size > 5 * 1024 * 1024) {
          throw new Error('File size should not exceed 5MB');
        }

        const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
        if (!allowedTypes.includes(file.type)) {
          throw new Error('Only JPG, PNG, GIF and WEBP files are allowed');
        }

        // Create preview
        if (imagePreview.value[imageNumber]) {
          URL.revokeObjectURL(imagePreview.value[imageNumber]);
        }
        imagePreview.value[imageNumber] = URL.createObjectURL(file);
        
        // Store file for upload
        imageFiles.value[imageNumber] = file;

      } catch (error) {
        imageErrors.value[imageNumber] = error.message;
        event.target.value = '';
        if (imagePreview.value[imageNumber]) {
          URL.revokeObjectURL(imagePreview.value[imageNumber]);
          imagePreview.value[imageNumber] = null;
        }
        imageFiles.value[imageNumber] = null;
      }
    };

    const removeImage = (imageNumber) => {
      const fileInput = document.getElementById(`imageUpload${imageNumber}`);
      if (fileInput) fileInput.value = '';

      if (imagePreview.value[imageNumber]) {
        URL.revokeObjectURL(imagePreview.value[imageNumber]);
        imagePreview.value[imageNumber] = null;
      }

      imageFiles.value[imageNumber] = null;
      imageErrors.value[imageNumber] = null;
      post[`gambar${imageNumber}`] = null;
    };

    const update = async () => {
      if (isSubmitting.value) return;
      
      try {
        isSubmitting.value = true;
        const formData = new FormData();

        // Append basic fields
        Object.entries(post).forEach(([key, value]) => {
          if (!key.startsWith('gambar')) {
            if (key === 'keyword' && Array.isArray(value)) {
              formData.append(key, value.join(','));
            } else if (value !== null && value !== undefined) {
              formData.append(key, value);
            }
          }
        });

        // Append new images
        Object.entries(imageFiles.value).forEach(([number, file]) => {
          if (file) {
            formData.append(`gambar${number}`, file);
          }
        });

        const response = await axios.put(
          `https://puracigarettesolutions.com:3000/api/artikel/EditArtikel/${post.id_artikel}`,
          formData,
          {
            headers: { 'Content-Type': 'multipart/form-data' }
          }
        );

        if (response.data.success) {
          alert('Article updated successfully');
          router.push('/TampilanArtikel');
        }
      } catch (error) {
        console.error('Error updating article:', error);
        alert(error.response?.data?.message || 'Error updating article');
      } finally {
        isSubmitting.value = false;
      }
    };

    // Lifecycle hooks
    onMounted(async () => {
      const articleId = route.params.id_artikel;
      if (articleId) {
        await Promise.all([
          fetchKategori(),
          fetchArticle(articleId)
        ]);
      } else {
        router.push('/TampilanArtikel');
      }
    });

    return {
      post,
      kategori,
      imagePreview,
      imageFiles,
      imageErrors,
      isUploading,
      isSubmitting,
      uploadingImageNumber,
      errors,
      editor: ClassicEditor,
      editorConfig,
      getFileUrl,
      handleFileUpload,
      removeImage,
      update
    };
  }
};
</script>



<style>
/* CSS */
.image-container {
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-img-style {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>