const MetaHelpers = {
  setMetaTag(attributeName, name, content) {
    let meta = document.querySelector(`meta[${attributeName}="${name}"]`);
    if (!meta) {
      meta = document.createElement('meta');
      meta.setAttribute(attributeName, name);
      document.head.appendChild(meta);
    }
    meta.setAttribute('content', content);
  },

  setCanonicalUrl(url) {
    let canonical = document.querySelector('link[rel="canonical"]');
    if (!canonical) {
      canonical = document.createElement('link');
      canonical.setAttribute('rel', 'canonical');
      document.head.appendChild(canonical);
    }
    canonical.setAttribute('href', url);
  },

  formatContent(content) {
    if (!content) return '';
    return content
      .replace(/<[^>]*>/g, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();
  },

  createSlug(title) {
    if (!title) return '';
    const text = this.formatContent(title).toLowerCase();
    const stopWords = new Set([
      'and', 'or', 'the', 'to', 'from', 'in', 'out', 'by',
      'with', 'on', 'at', 'of', 'for', 'into', 'over',
      'guide', 'ultimate', 'what', 'when', 'where', 'why',
      'how', 'solution', 'solutions', 'about'
    ]);

    const words = text
      .split(/\s+/)
      .map(word => word.replace(/[^a-z0-9]/g, ''))
      .filter(word => 
        word.length > 2 && 
        !stopWords.has(word) && 
        !/^\d+$/.test(word)
      )
      .slice(0, 3);

    let slug = words.join('-');
    return slug.length > 35 ? slug.substring(0, 35).replace(/-[^-]*$/, '') : slug;
  }
};

export const metaMixin = {
  data() {
    return {
      baseUrl: 'https://puracigarette.com',
      defaultImage: '/assets/img/business-4/slider/default-banner.webp',
      // Tambahkan imageSize
      imageSize: {
        width: 1200,
        height: 630
      },
      socialLinks: {
        facebook: 'https://www.facebook.com/puracigarettesolutions',
        instagram: 'https://www.instagram.com/pura.mics/',
        youtube: 'https://www.youtube.com/channel/UC_1YqET6QtMRvh7IlD-TJYg',
        tiktok: 'https://www.tiktok.com/@puracigarette',
        linkedin: 'https://www.linkedin.com/company/puracigarette/'
      }
    }
  },

  
  methods: {
    updateCanonicalUrl(url) {
      if (!url) return;
      MetaHelpers.setCanonicalUrl(url);
    },

    updateStructuredData(data) {
      const existingScript = document.querySelector('script[type="application/ld+json"]');
      if (existingScript) {
        existingScript.remove();
      }
      const script = document.createElement('script');
      script.type = 'application/ld+json';
      script.textContent = JSON.stringify(data);
      document.head.appendChild(script);
    },

    // Main meta update methods
    updateMetaTags(metaInfo) {
      document.title = metaInfo.title || 'Pura Material Innovations and Cigarette Solutions (MICS)';
      this.updateAllMetaTags(metaInfo);
      
      if (metaInfo.type === 'product' || metaInfo.type === 'article') {
        this.updateStructuredData(metaInfo);
      }
    },

    updateAllMetaTags(metaInfo) {
      const fullImageUrl = metaInfo.image ? `${this.baseUrl}${metaInfo.image}` : `${this.baseUrl}${this.defaultImage}`;
      
      const basicTags = {
        name: {
          'description': metaInfo.description,
          'keywords': metaInfo.keywords,
          'author': 'Pura MICS',
          'robots': 'index, follow'
        },
        property: {
          'og:title': metaInfo.title,
          'og:description': metaInfo.description,
          'og:image': fullImageUrl,
          'og:url': metaInfo.url || `${this.baseUrl}${this.$route.path}`,
          'og:type': metaInfo.type || 'website',
          'og:site_name': 'Pura MICS',
          'og:locale': this.$i18n?.locale === 'id' ? 'id_ID' : 'en_US',
          'og:image:width': this.imageSize.width.toString(),
          'og:image:height': this.imageSize.height.toString(),
          'og:image:alt': metaInfo.imageAlt || metaInfo.title,
          'linkedin:card': 'summary_large_image',
          'linkedin:title': metaInfo.title,
          'linkedin:description': metaInfo.description,
          'linkedin:image': fullImageUrl
        },
        name_twitter: {
          'twitter:card': 'summary_large_image',
          'twitter:site': '@PuraMICS',
          'twitter:creator': '@PuraMICS',
          'twitter:title': metaInfo.title,
          'twitter:description': metaInfo.description,
          'twitter:image': fullImageUrl,
          'twitter:image:alt': metaInfo.imageAlt || metaInfo.title
        },
        name_youtube: {
          'youtube:channel': this.socialLinks.youtube.split('/').pop(),
          'youtube:title': metaInfo.title,
          'youtube:description': metaInfo.description,
          'youtube:image': fullImageUrl
        }
      };

      // Update all meta tags
      Object.entries(basicTags.name).forEach(([name, content]) => {
        if (content) {
          MetaHelpers.setMetaTag('name', name, content);
        }
      });

      Object.entries(basicTags.property).forEach(([property, content]) => {
        if (content) {
          MetaHelpers.setMetaTag('property', property, content);
        }
      });

      Object.entries(basicTags.name_twitter).forEach(([name, content]) => {
        if (content) {
          MetaHelpers.setMetaTag('name', name, content);
        }
      });

      Object.entries(basicTags.name_youtube).forEach(([name, content]) => {
        if (content) {
          MetaHelpers.setMetaTag('name', name, content);
        }
      });

      // Update canonical URL
      if (metaInfo.canonical) {
        this.updateCanonicalUrl(metaInfo.canonical);
      }
    },

    formatContent(content) {
      if (!content) return '';
      return content
        .replace(/<[^>]*>/g, '')    // Remove HTML tags
        .replace(/&nbsp;/g, ' ')    // Replace HTML entities
        .replace(/\s+/g, ' ')       // Normalize whitespace
        .trim();
    },

    formatTitle(content) {
      if (!content) return '';
      return content
        .replace(/<[^>]*>/g, '')    // Remove HTML tags
        .replace(/&nbsp;/g, ' ')    // Replace HTML entities
        .replace(/\s+/g, ' ')       // Normalize whitespace
        .trim();
    },

    createSlug(title) {
      if (!title) return '';
      
      // Basic cleanup
      let text = this.formatContent(title).toLowerCase();
      
      // Core stop words - keep minimal for better slugs
      const stopWords = new Set([
        'and', 'or', 'the', 'to', 'from', 'in', 'out', 'by',
        'with', 'on', 'at', 'of', 'for', 'into', 'over',
        'guide', 'ultimate', 'what', 'when', 'where', 'why',
        'how', 'solution', 'solutions', 'about'
      ]);
      
      // Get words and clean them
      let words = text
        .split(/\s+/)
        .map(word => word.replace(/[^a-z0-9]/g, ''))
        .filter(word => 
          word.length > 2 && 
          !stopWords.has(word) && 
          !/^\d+$/.test(word)
        );
      
      // Take first 2-3 significant words
      words = words.slice(0, 3);
      
      // Join and limit length
      let slug = words.join('-');
      if (slug.length > 35) {
        slug = slug.substring(0, 35).replace(/-[^-]*$/, '');
      }
      
      return slug;
    },


    formatPageTitle(title, prefix = '') {
      if (!title) return '';
      
      let cleanTitle = this.formatContent(title);
      
      // Limit main title length
      if (cleanTitle.length > 40) {
        cleanTitle = cleanTitle.substring(0, 37) + '...';
      }
      
      // Add prefix if provided
      return prefix ? `${prefix} - ${cleanTitle}` : cleanTitle;
    },


    async updateMetaForDetailProduct() {
      try {
        // Ensure we have product data
        const product = this.product || this.currentProduct;
        if (!product) {
          console.warn('No product data available for meta update');
          return;
        }

        const currentLanguage = this.$i18n?.locale || 'en';
        
        // Format title and description
        const title = this.formatContent(
          currentLanguage === 'en' ? product.judul_en : product.judul_id
        );
        const description = this.formatContent(
          currentLanguage === 'en' ? 
          (product.deskripsi_en || product.keterangan_en) : 
          (product.deskripsi_id || product.keterangan_id)
        );
        const category = this.formatContent(product.nama_kategori);
        
        // Create SEO-friendly slug
        const slug = this.createSlug(title);
        
        // Build comprehensive meta information
        const metaInfo = {
          title: `Pura MICS Products - ${title} - ${category}`,
          description: description || `Premium ${category} solutions from Pura MICS. ${this.defaultDescription}`,
          keywords: `${product.keyword || ''}, ${category}, ${this.defaultKeywords}`.trim(),
          image: product.gambar1 ? `${this.baseUrl}/api/produk/file/${product.gambar1}` : null,
          url: `${this.baseUrl}/DetailProduk/${slug}`,
          canonical: `${this.baseUrl}/DetailProduk/${slug}`,
          type: 'product',
          product: {
            '@context': 'https://schema.org',
            '@type': 'Product',
            name: title,
            description: description,
            brand: {
              '@type': 'Brand',
              name: 'Pura MICS'
            },
            manufacturer: {
              '@type': 'Organization',
              name: 'Pura Group'
            },
            category: category,
            additionalProperty: this.getProductSpecifications(product)
          }
        };

        // Update all meta tags
        this.updateMetaTags(metaInfo);
        this.updateStructuredData(metaInfo.product);

      } catch (error) {
        console.error('Error updating product meta:', error);
      }
    },

    getProductSpecifications(product) {
      const specs = [];
      const properties = ['size', 'materials', 'features', 'finishing', 'printed', 'flavor', 'sweetener'];
      
      properties.forEach(prop => {
        if (product[prop]) {
          specs.push({
            '@type': 'PropertyValue',
            name: prop,
            value: product[prop]
          });
        }
      });
      
      return specs;
    },

    async updateMetaForDetailBlog() {
      try {
        if (!this.article) return;

        const currentLanguage = this.$i18n?.locale || 'en';
        
        // Get base content
        const rawTitle = currentLanguage === 'en' ? 
          this.article.judul_en : 
          this.article.judul_id;
        
        const description = this.formatContent(
          currentLanguage === 'en' ? 
            this.article.deskripsi_en : 
            this.article.deskripsi_id
        );
        
        // Generate optimized slug and title
        const slug = this.createSlug(rawTitle);
        const title = this.formatPageTitle(rawTitle, 'Pura MICS');

        const metaInfo = {
          title: title,
          description: description || 'Read our latest article about tobacco industry innovations and solutions.',
          keywords: this.article.keyword || this.defaultKeywords,
          image: this.article.gambar1 ? `${this.baseUrl}/api/artikel/file/${this.article.gambar1}` : null,
          url: `${this.baseUrl}/DetailBlog/${slug}`,
          canonical: `${this.baseUrl}/DetailBlog/${slug}`,
          type: 'article'
        };

        this.updateMetaTags(metaInfo);

      } catch (error) {
        console.error('Error updating article meta:', error);
      }
    },

    getMetaConfig() {
      const configs = {
        home: {
          title: 'Pura Material Innovations and Cigarette Solutions (MICS) - Home',
          description: 'PURA GROUP produces various special products to support the tobacco industry, including Homogenized Tobacco Leaf Wrapper, Cigarette Tipping Paper, Colored Cigarette Paper, and Cigarette Packs.',
          keywords: 'pura mics, tobacco industry solutions, cigarette manufacturing, homogenized tobacco leaf, tipping paper, hand-rolled cigarettes, machine-made cigarettes, e-cigarette solutions, ryo myo tobacco, halal certified tobacco, eco-friendly tobacco solutions, tobacco industry innovation, cigarette paper manufacturer, tobacco leaf wrapper, custom cigarette solutions, indonesian tobacco manufacturer, premium cigarette materials, sustainable tobacco solutions, tobacco industry supplier, cigarette packaging solutions',
          image: '/assets/img/business-4/slider/LANDINGPAGE.webp',
          imageAlt: 'Pura MICS Home Banner',
          type: 'website',
          url: this.baseUrl,
          canonical: this.baseUrl
        },
        about: {
          title: 'About Pura MICS - A GUIDING LIGHT IN THE TOBACCO INDUSTRY',
          description: 'Learn about Pura MICS, a leading innovator in cigarette solutions. Discover our history, values, and commitment to quality in the tobacco industry.',
          keywords: [
            'pura mics',
            'pura group subsidiary',
            'indonesian cigarette manufacturer',
            'tobacco manufacturing solutions',
            'cigarette industry experience',
            'quality manufacturing standards',
            'sustainable tobacco solutions'
          ].join(', '),
          image: '/assets/img/business-4/slider/about.webp',
           imageAlt: 'Pura MICS About Banner',
          type: 'website',
          url: `${this.baseUrl}/AboutPage`,
          canonical: `${this.baseUrl}/AboutPage`
        },
        product: {
          title: 'Pura MICS Products - Innovative Cigarette Solutions',
          description: 'Explore our comprehensive range of cigarette solutions including Homogenized Tobacco Leaf Wrapper, Tipping Paper, and Cigarette Packs.',
          keywords: [
            'cigarette paper',
            'tipping paper',
            'cigarette pack',
            'filter tips',
            'individual cigarette pack',
            'pre-rolled cones',
            'ryo/myo',
            'tobacco processing solutions',
            'quality cigarette components'
          ].join(', '),
          image: '/assets/img/business-4/slider/PRODUCT.webp',
           imageAlt: 'Pura MICS Produk Banner',
          type: 'website',
          url: `${this.baseUrl}/ProdukPage`,
          canonical: `${this.baseUrl}/ProdukPage`
        },
        contactus: {
          title: 'Contact Pura MICS - Get in Touch With Us',
          description: 'Contact Pura MICS for innovative cigarette solutions. Reach out to our team for inquiries about our products and services.',
          keywords: [
            'pura mics contact',
            'cigarette manufacturer contact',
            'tobacco solutions contact',
            'manufacturing consultation',
            'business collaboration'
          ].join(', '),
          type: 'website',
          url: `${this.baseUrl}/ContactUs`,
          canonical: `${this.baseUrl}/ContactUs`
        },
        Sampel: {
          title: 'Request Samples - Pura MICS Products',
          description: 'Request samples of our premium cigarette solutions. Experience the quality of Pura MICS products firsthand.',
          keywords: [
            'cigarette paper samples',
            'tipping paper samples',
            'filter samples',
            'packaging samples',
            'tobacco product samples'
          ].join(', '),
          type: 'website',
          url: `${this.baseUrl}/SamplePage`,
          canonical: `${this.baseUrl}/SamplePage`
        },
        BlogPura: {
          title: 'Pura MICS Blog - Tobacco Industry News, Innovations & Insights',
          description: 'Stay updated with the latest tobacco industry news, product innovations, manufacturing insights, and events from Pura MICS.',
          keywords: [
            'tobacco industry news',
            'cigarette manufacturing updates',
            'tobacco product innovations',
            'industry best practices',
            'manufacturing standards'
          ].join(', '),
          image: '/assets/img/business-4/slider/produkk.webp',
           imageAlt: 'Pura MICS Blog Banner',
          type: 'website',
          url: `${this.baseUrl}/BlogPura`,
          canonical: `${this.baseUrl}/BlogPura`
        },
        simulasi: {
          title: 'CTP Simulation - Pura MICS Tools',
          description: 'Use our CTP simulation tool to visualize and customize your tobacco product solutions.',
          keywords: 'CTP simulation, tipping paper calculator, cigarette design tool',
          url: `${this.baseUrl}/simulasiCtp`,
          canonical: `${this.baseUrl}/simulasiCtp`
        }
      };

      // Handle dynamic routes
      if (this.$route?.name === 'DetailProduct' && this.$route?.params?.id) {
        this.updateMetaForDetailProduct();
        return null;
      }

      if (this.$route?.name === 'DetailBlog' && this.$route?.params?.id) {
        this.updateMetaForDetailBlog();
        return null;
      }

      const currentFilter = this.$route?.query?.filter || 'all';
      if (this.$route?.name === 'product') {
        configs.product.url = `${this.baseUrl}/ProdukPage?filter=${currentFilter}`;
        configs.product.canonical = `${this.baseUrl}/ProdukPage?filter=${currentFilter}`;
      }

      return configs[this.$route?.name] || configs.home;
    }
  },

  watch: {
    '$route': {
      immediate: true,
      handler() {
        const config = this.getMetaConfig();
        if (config) {
          this.updateMetaTags(config);
        }
      }
    },
    
    '$i18n.locale'() {
      const config = this.getMetaConfig();
      if (config) {
        this.updateMetaTags(config);
      } else if (this.$route?.name === 'DetailProduct') {
        this.updateMetaForDetailProduct();
      } else if (this.$route?.name === 'DetailBlog') {
        this.updateMetaForDetailBlog();
      }
    },

    'product': {
      handler(newProduct) {
        if (newProduct && this.$route?.name === 'DetailProduct') {
          this.updateMetaForDetailProduct();
        }
      }
    },

    'article'(newArticle) {
      if (newArticle && this.$route?.name === 'DetailBlog') {
        this.updateMetaForDetailBlog();
      }
    }
  }
};