<template>
    <HeaderCom />
    <section class="saasio-breadcurmb-section-contact">
      <div class="container">
        <div class="breadcurmb-title-contact">
          <h2>{{ $t('simulasiPage.title') }}</h2>
        </div>
      </div>
    </section>
  
    <section id="saas_two_about" class="saas_two_about_section relative-position">
      <div class="container">
        <div class="about_content_s2">
          <div class="row">
            <div class="col-lg-6 col-md-12 wow fadeFromLeft" data-wow-delay="300ms" data-wow-duration="1500ms">
              <div class="s2-about_img">
                <div class="centered-content">
                  <div class="rokok" ref="rokokElement">
                    <div class="filter"
                      :style="selectedMotif !== null ? { backgroundImage: 'url(' + selectedMotif.url + ')' } : {}">
                    </div>
                    <div class="ctp-line"
                      :style="{ backgroundColor: ctpColor, bottom: ctpPosition + 'px' }"></div>
                    <div v-if="isDoubleLine" class="ctp-gap"
                      :style="{ backgroundColor: ctpColor, bottom: parseInt(ctpPosition) + 85 + 'px' }">
                    </div>
                    <div class="tubuh"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 wow fadeFromRight" data-wow-delay="300ms" data-wow-duration="1500ms">
              <div class="s2-about_text_icon">
                <div class="s2-about_text saas2-headline pera-content">
                  <h3>{{ $t('simulasiPage.subtitle') }}</h3>
                  <p class="justify-text">{{ $t('simulasiPage.content') }}</p>
                  <br>
                  <br>
                </div>
  
                <!-- <label for="filterSelect">{{ $t('simulasiPage.filter') }}</label>
                <select v-model="selectedFilter" id="filterSelect" class="filter-select">
                  <option value="">{{ $t('simulasiPage.allMotifs') }}</option>
                  <option v-for="category in motifCategories" :key="category" :value="category">
                    {{ category }}
                  </option>
                </select> -->
  
                <label>Motif</label>
                <div class="motifs-container">
                  <div v-for="motif in filteredMotifs" :key="motif.name" class="motif-selector"
                    @click="selectMotif(motif)">
                    <img :src="motif.url" :alt="motif.name" />
                    <div v-if="selectedMotif === motif" class="checkmark">✓</div>
                  </div>
                </div>
  
                <button class="btn btn-primary" @click="downloadImage">{{ $t('simulasiPage.download') }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FooterCom />
    <canvas ref="canvas" width="40" height="400" style="display: none;"></canvas>
  </template>
  
  <script>
  import html2canvas from 'html2canvas';
  import FooterCom from '@/components/FooterCom.vue';
  import HeaderCom from '@/components/HeaderCom.vue';
  import { useI18n } from 'vue-i18n';
  import { metaMixin } from '@/mixins/metaMixin';
  
  export default {
    name: 'SimulasiCTP',
    mixins: [metaMixin],
    components: {
      FooterCom,
      HeaderCom,
    },
    setup() {
      const { t, locale } = useI18n();
      return { t, locale };
    },
    data() {
      return {
        selectedMotif: null,
        isDoubleLine: false,
        ctpPosition: 80,
        selectedFilter: '',
        motifs: [
          { name: 'Motif 1', url: 'assets/img/business-4/motif/Motif1.png', category: 'Generic' },
          { name: 'Motif 2', url: 'assets/img/business-4/motif/Motif2.png', category: 'Generic' },
          { name: 'Motif 3', url: 'assets/img/business-4/motif/Motif3.png', category: 'Generic' },
          { name: 'Motif 4', url: 'assets/img/business-4/motif/Motif4.png', category: 'Generic' },
          { name: 'AROMA', url: 'assets/img/business-4/motif/AROMA.png', category: 'Brand' },
          { name: 'CAMEL', url: 'assets/img/business-4/motif/CAMEL.png', category: 'Brand' },
          { name: 'Clas Mild', url: 'assets/img/business-4/motif/Clas_Mild.png', category: 'Brand' },
          { name: 'CRYSTAL CAFFE', url: 'assets/img/business-4/motif/CRYSTAL_CAFFE.png', category: 'Brand' },
          { name: 'EVO', url: 'assets/img/business-4/motif/EVO.png', category: 'Brand' },
          { name: 'EVO SOPRA', url: 'assets/img/business-4/motif/EVO_SOPRA.png', category: 'Brand' },
          { name: 'MONTE CARLO', url: 'assets/img/business-4/motif/MONTE_CARLO.png', category: 'Brand' },
          { name: 'PAMERAN', url: 'assets/img/business-4/motif/PAMERAN.png', category: 'Event' },
          { name: 'WINSTONE CASTER', url: 'assets/img/business-4/motif/WINSTONE_CASTER.png', category: 'Brand' },
        ],
        ctpColor: '#dcb83d',
      };
    },
    computed: {
      motifCategories() {
        return [...new Set(this.motifs.map(motif => motif.category))];
      },
      filteredMotifs() {
        if (!this.selectedFilter) return this.motifs;
        return this.motifs.filter(motif => motif.category === this.selectedFilter);
      }
    },
    methods: {
      selectMotif(motif) {
        this.selectedMotif = motif;
      },
      updateCtpPosition() {
        this.drawToCanvas();
      },
      updateColors() {
        this.drawToCanvas();
      },
      drawToCanvas() {
        const canvas = this.$refs.canvas;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
  
        // Draw CTP lines
        ctx.fillStyle = this.ctpColor;
        ctx.fillRect(0, canvas.height - this.ctpPosition, canvas.width, 80);
        if (this.isDoubleLine) {
          ctx.fillRect(0, canvas.height - this.ctpPosition - 85, canvas.width, 7);
        }
  
        // Draw motif if selected
        if (this.selectedMotif) {
          const motifImage = new Image();
          motifImage.crossOrigin = 'Anonymous';
          motifImage.src = this.selectedMotif.url;
          motifImage.onload = () => {
            ctx.drawImage(motifImage, 0, 0, canvas.width, canvas.height - this.ctpPosition);
          };
        }
      },
      downloadImage() {
        const rokokElement = this.$refs.rokokElement;
        html2canvas(rokokElement, {
          logging: true,
          letterRendering: 1,
          useCORS: true
        }).then(canvas => {
          const image = canvas.toDataURL('image/png');
          const link = document.createElement('a');
          link.download = 'custom_rokok.png';
          link.href = image;
          link.click();
        }).catch(err => {
          console.error('Error in html2canvas: ', err);
        });
      },
      changeLanguage(lang) {
        this.locale = lang;
      },
    },
    mounted() {
      this.drawToCanvas();
    },
    watch: {
      selectedMotif() {
        this.drawToCanvas();
      },
      isDoubleLine() {
        this.drawToCanvas();
      },
    },
  };
  </script>



<style>
.rokok {
    position: relative;
    width: 40px;
    /* increased from 20px */
    height: 400px;
    /* increased from 200px */
    background-color: #f5f5f5;
    border-radius: 20px;
    /* proportionally increased */
    border: 1px solid #ddd;
    margin: 50px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    /* adjusted for size */
}

.filter-select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  background-color: white;
}


.rokop {
    position: relative;
    width: 40px;
    /* increased from 20px */
    height: 400px;
    /* increased from 200px */
    margin: 50px;
    /* adjusted for size */
}

.filter {
    position: absolute;
    bottom: 0;
    width: 38.8px;
    height: 155px;
    /* Set the height as needed */
    background-image: url('../assets/PAMERAN.png');
    background-size: auto 100%;
    /* Scale the image height to be slightly more than the container height */
    background-repeat: no-repeat;
    /* Prevents the image from repeating */
    background-position: center;
    /* Centers the background image horizontally */
}


.tubuh {
    width: 100%;
    height: 300px;
    /* increased proportionally */
    background-color: #ffffff;
}

.motifs-container {
    display: flex;
    /* Use flexbox to layout children horizontally */
    flex-wrap: wrap;
    /* Allows motifs to wrap to the next line if the container is too small */
    justify-content: center;
    /* Center motifs horizontally */
    align-items: center;
    /* Center motifs vertically */
}


.motif-selector {
    margin: 10px;
    width: 50px;
    height: 50px;
    position: relative;
    cursor: pointer;
    display: flex;
    /* Apply flexbox for the internal alignment of image and checkmark */
    justify-content: center;
    /* Center content horizontally within each motif selector */
    align-items: center;
    /* Center content vertically within each motif selector */
}

.motif-selector img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    /* Adjusted for a more proportional size */
    color: #ffffff;
    /* Changed for better visibility */
    border: 2px solid #dcb83d;
    /* Kept the vibrant color for the border */
    border-radius: 50%;
    /* Circular shape */
    background-color: #dcb83d;
    /* Background color similar to border for consistency */
    padding: 5px;
    /* Sufficient padding for a rounded look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    /* More refined shadow */
    text-align: center;
    line-height: 20px;
    /* Align the checkmark symbol vertically */
}

.slider-container {
    position: relative;
    padding: 10px 0;
}

.animated-slider {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    border-radius: 5px;
    background: linear-gradient(90deg, #dcb83d, #dcb83d);
    outline: none;
    transition: box-shadow 0.2s ease-in-out;
}

.animated-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #fff;
    border: 3px solid #dcb83d;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;
}

.animated-slider::-webkit-slider-thumb:hover {
    transform: scale(1.3);
}

.slider-value {
    position: absolute;
    top: -30px;
    /* Adjust as needed */
    transform: translateX(-50%);
    background-color: #dcb83d;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 14px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    transition: left 0.2s ease-in-out;
}

.custom-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    background: #ccc;
    border-radius: 34px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.toggle-switch::after {
    content: '';
    position: absolute;
    width: 26px;
    height: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.2s;
    border-radius: 50%;
}

.custom-checkbox:checked+.toggle-switch {
    background-color: #2196F3;
}

.custom-checkbox:checked+.toggle-switch::after {
    transform: translateX(26px);
}

.toggle-label {
    margin-left: 15px;
    vertical-align: middle;
}

.justify-text {
    text-align: justify;
    font-family: 'Montserrat', sans-serif;
}


/* ... more existing styles ... */
</style>