<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MICS</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <div class="content-wrapper">
            <section class="content">
                <div class="container-fluid">
                    <div class="card card-primary card-outline">
                        <div class="card-header">
                            <div class="col-6">
                                <h5>Sosmed Baru</h5>
                            </div>
                            <div class="text-right">
                                <router-link to="/TampilanSosmed" class="btn btn-primary" style="color: #ffffff;">
                                    <i class="fas fa-angle-left" style="color: #ffffff;"> </i> Kembali
                                </router-link>
                            </div>
                        </div>
                        <form @submit.prevent="store" class="card-body">
                            <!-- Title Input -->
                            <div class="form-group">
                                <label for="postTitle">Title ID:</label>
                                <input type="text" id="judul_id" v-model="post.judul_id" class="form-control"
                                    placeholder="Enter Post Title" required>
                            </div>

                            <div class="form-group">
                                <label for="postTitle">Title EN:</label>
                                <input type="text" id="judul_en" v-model="post.judul_en" class="form-control"
                                    placeholder="Enter Post Title" required>
                            </div>

                            <!-- Author Input -->
                            <div class="form-group">
                                <label for="author">URL ID:</label>
                                <input type="text" id="url" v-model="post.url" class="form-control" required>
                            </div>

                            <!-- Image Upload -->
                            <div class="form-group">
                                <label for="imageUpload">Image:</label>
                                <input type="file" id="gambar" class="form-control-file" @change="handleImageUpload">
                                <img v-if="imagePreview" :src="imagePreview" alt="Preview"
                                    style="max-width: 200px; max-height: 200px; margin-top: 10px;">
                            </div>

                            <!-- Submit Button -->
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>
  
<script>
import FooterDs from "../components/FooterDs.vue"
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import axios from 'axios';

export default {
    name: "CombinedComponent",
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
    },
    data() {
        return {
            post: {
                judul_en: '',
                judul_id: '',
                url: '',
                gambar: null // Changed 'gambar' to match the FormData key for the image
            },
            validationErrors: [],
            imagePreview: '',
            editorData: '',
            isSubmitting: false
        };
    },
    methods: {
        async store() {
            if (!this.validateForm()) {
                alert('Please fill in all required fields.');
                return;
            }
            this.isSubmitting = true;

            const formData = new FormData();
            formData.append('judul_id', this.post.judul_id);
            formData.append('judul_en', this.post.judul_en);
            formData.append('url', this.post.url);
            formData.append('gambar', this.post.gambar);
            // Append other form data as needed

            try {
                const response = await axios.post('https://puracigarettesolutions.com:3000/api/sosmed/CreateSosmed', formData);
                console.log('Submitted:', response.data);
                // Reset form data after successful submission if needed
                this.$router.push({ name: 'TampilanSosmed' });
            } catch (error) {
                this.handleErrors(error);
            } finally {
                this.isSubmitting = false;
            }
        },
        handleErrors(error) {
            if (error.response && error.response.data) {
                this.validationErrors = error.response.data;
            } else {
                console.error('Unexpected error structure:', error);
                this.validationErrors = [{ param: 'error', msg: 'Unexpected error occurred' }];
            }
        },
        validateForm() {
            return this.post.judul_id && this.post.judul_en && this.post.url && this.post.gambar;
        },

        handleImageUpload(event) {
            this.post.gambar = event.target.files[0];
            this.imagePreview = URL.createObjectURL(event.target.files[0]);
        }
        // Other methods as needed...
    }
};
</script>

  
<style></style>
  

