<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1>Data promo</h1>
                        </div>
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">Data promo</li>
                            </ol>
                        </div>
                    </div>
                </div><!-- /.container-fluid -->
            </section>

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table id="banner" class="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">No</th>
                                                <th scope="col">Gambar</th>
                                                <th scope="col">Options</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(post, index) in posts" :key="index">
                                                <td>{{ index + 1 }}</td>
                                                <td>
                                                    <div class="image-container">
                                                        <img :src="getFileUrl(post.gambar)" alt="Deskripsi Gambar"
                                                            class="custom-img-style">
                                                    </div>
                                                </td>
                                                <td class="text-center">
                                                    <router-link
                                                        :to="{ name: 'EditPromo', params: { id_promo: post.id_promo } }"
                                                        class="btn btn-warning mr-2"
                                                        style="color: #ffffff;">EDIT</router-link>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                        </div>
                                </div>
                                <!-- /.card-body -->
                            </div>
                            <!-- /.card -->
                        </div>
                        <!-- /.col -->
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.container-fluid -->
            </section>
            <!-- /.content -->
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>
  
<script>
import axios from 'axios';
import { onMounted, ref } from 'vue';
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";

export default {
    name: "TampilanPromo",
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
    },
    setup() {
        const posts = ref([]);

        const getDataPosts = () => {
            axios.get('https://puracigarettesolutions.com:3000/api/promo/TampilanPromo')
                .then(response => {
                    posts.value = response.data.data;
                })
                .catch(error => {
                    console.log(error);
                });
        };

        const getFileUrl = (filename) => {
            const baseUrl = 'https://puracigarettesolutions.com:3000/api/promo/file';
            return `${baseUrl}/${filename}`;
        };

        const confirmDelete = (id_promo) => {
            if (confirm("Are you sure you want to delete this post?")) {
                deletePost(id_promo);
            }
        };

        const deletePost = (id_promo) => {
            axios.delete(`https://puracigarettesolutions.com:3000/api/promo/TampilanPromo/${id_promo}`)
                .then(() => {
                    getDataPosts();
                }).catch(error => {
                    console.error("Error deleting post:", error.response.data);
                });
        };

        onMounted(getDataPosts);

        return {
            posts,
            getFileUrl,
            confirmDelete
        };
    },
};
</script>
  
<style>
/* CSS */
.image-container {
    width: 150px; /* atau lebih, sesuai dengan keinginan Anda */
    height: 150px; /* atau lebih, sesuai dengan keinginan Anda */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-img-style {
    width: 100%; /* akan mengisi lebar container */
    height: auto; /* akan mempertahankan rasio aspek */
    display: block; /* memastikan tidak ada spasi tambahan di bawah gambar */
}



/* CSS */
.table-responsive {
    overflow-x: auto;
}

.table td, .table th {
    white-space: nowrap;
}

@media (max-width: 768px) {
    .custom-img-style {
        /* Adjust image size for small screens */
        width: 30px;
        height: 30px;
    }
}
</style>
  