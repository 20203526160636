<template>
    <HeaderCom />
    <section class="saasio-breadcurmb-section">
        <div class="container">
            <div class="breadcurmb-title">
                <h2>{{ $t('Customer.title') }}</h2>
            </div>
        </div>
    </section>
    <!-- End of breadcurmb section
        ============================================= -->
        <!-- Contact Form Section -->
  <section id="sa-contact-inner" class="sa-contact-inner-section inner-page-padding">
    <div class="container">
      <div class="saas_two_section_title saas2-headline text-center">
        <span class="title_tag">{{ $t('Customer.sub') }}</span>
        <h2><span>{{ $t('Customer.sample') }}</span></h2>
      </div>
      <div class="sa-contact-inner-form">
        <form  @submit.prevent="store" method="post" enctype="multipart/form-data">
          <div class="row">
            <!-- Name Input -->
            <div class="col-md-6">
              <input type="text" id="nama" name="nama" v-model="post.nama" placeholder="Your Full Name">
            </div>
            <!-- Email Input -->
            <div class="col-md-6">
              <input type="email" id="email" name="email" v-model="post.email" placeholder="Your Email Address">
            </div>
            <!-- Phone Input -->
            <div class="col-md-6">
              <input type="text" id="no_hp" name="no_hp" v-model="post.no_hp" placeholder="Your Mobile Number">
            </div>
            <!-- Subject Input -->
            <div class="col-md-6">
              <input type="text" id="nama_perusahaan" name="nama_perusahaan" v-model="post.nama_perusahaan" placeholder="Company Name">
            </div>
            <!-- File Upload Input -->
                <div class="col-md-12">
               <input type="file" id="file" name="file" @change="handleFileUpload" accept="image/*,.pdf">
                </div>
                <div class="col-md-12" v-if="imagePreview">
                <h3>Preview:</h3>
                <img :src="imagePreview" style="max-width: 100%; max-height: 200px;">
                </div>
                 <div class="g-recaptcha" data-sitekey="6Lfuv4IpAAAAAGo_bl2ewl3SNQkkAPhKCfmAX2FS" data-action="LOGIN"></div>
            <div class="sa-contact-inner-btn text-center">
            
              <button type="submit">{{ $t('Customer.simpan') }}</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>

    <FooterCom />
</template>

<script>
// eslint-disable-next-line no-undef
/* global grecaptcha */

import { ref } from 'vue';
import axios from 'axios';
import FooterCom from '@/components/FooterCom.vue';
import HeaderCom from '@/components/HeaderCom.vue';

const initialPostState = () => ({
  nama: '',
  email: '',
  no_hp: '',
  nama_perusahaan: '',
  file: null,
  tanggal: new Date().toISOString().substr(0, 19),
});

export default {
  name: 'CustomerContactForm',
  components: {
    FooterCom,
    HeaderCom,
  },
  setup() {
    const post = ref(initialPostState());
    const imagePreview = ref(null);

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      post.value.file = file;
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          imagePreview.value = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    };

    const store = async () => {
      const formData = new FormData();
      formData.append('nama', post.value.nama);
      formData.append('email', post.value.email);
      formData.append('no_hp', post.value.no_hp);
      formData.append('nama_perusahaan', post.value.nama_perusahaan);
      formData.append('file', post.value.file);
      formData.append('tanggal', post.value.tanggal);

      // Ensure reCAPTCHA response token is included in form submission
      const recaptchaToken = grecaptcha.getResponse();
      formData.append('g-recaptcha-response', recaptchaToken);

      try {
        const response = await axios.post('https://puracigarettesolutions.com:3000/api/customer/CreateCustomer', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log('Form submission successful', response.data);
        post.value = initialPostState();
        imagePreview.value = null;
        grecaptcha.reset();
      } catch (error) {
        console.error('Error during form submission', error);
      }
    };

    return {
      store,
      post,
      handleFileUpload,
      imagePreview,
    };
  },
};
</script>





