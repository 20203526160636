<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <div class="content-wrapper">
            <!-- Content Header (Page header) -->
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6">
                            <h1 class="m-0">Dashboard</h1>
                        </div><!-- /.col -->
                        <div class="col-sm-6">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div><!-- /.col -->
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->

            <!-- Main content -->
            <section class="content">
                <div class="container-fluid">
                    <!-- Small boxes (Stat box) -->
                    <div class="row">
                        <div class="col-lg-3 col-6">
                            <!-- small box -->
                            <div class="small-box bg-info">
                                <div class="inner">
                                    <h3>{{ JumlahArtikel }}</h3>

                                    <p>Jumlah Artikel</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-bag"></i>
                                </div>
                            </div>
                        </div>
                        <!-- ./col -->
                        <div class="col-lg-3 col-6">
                            <!-- small box -->
                            <div class="small-box bg-success">
                                <div class="inner">
                                    <h3>{{ JumlahProduk }}</h3>

                                    <p>Jumlah Produk</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-stats-bars"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <!-- small box -->
                            <div class="small-box bg-danger">
                                <div class="inner">
                                    <h3>{{ JumlahDetailProduk }}</h3>

                                    <p>Jumlah Detail Produk</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-stats-bars"></i>
                                </div>
                            </div>
                        </div>
                        <!-- ./col -->
                        <div class="col-lg-3 col-6">
                            <!-- small box -->
                            <div class="small-box bg-warning">
                                <div class="inner">
                                    <h3>{{ JumlahKategori }}</h3>

                                    <p>Jumlah Ketegeri</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-person-add"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <!-- small box -->
                            <div class="small-box bg-info">
                                <div class="inner">
                                    <h3>{{ JumlahEtiket }}</h3>

                                    <p>Jumlah Permintaan Sampel CTP</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-person-add"></i>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-3 col-6">
                            <!-- small box -->
                            <div class="small-box bg-danger">
                                <div class="inner">
                                    <h3>{{ JumlahCtp }}</h3>

                                    <p>Jumlah Permintaan Sampel Etiket</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-person-add"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <!-- small box -->
                            <div class="small-box bg-info">
                                <div class="inner">
                                    <h3>{{ JumlahCp }}</h3>

                                    <p>Jumlah Permintaan Sampel Cigarette Paper</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-person-add"></i>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-3 col-6">
                            <!-- small box -->
                            <div class="small-box bg-warning">
                                <div class="inner">
                                    <h3>{{ JumlahFilter }}</h3>

                                    <p>Jumlah Permintaan Sampel Filter Tips</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-person-add"></i>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-3 col-6">
                            <!-- small box -->
                            <div class="small-box bg-success">
                                <div class="inner">
                                    <h3>{{ JumlahPr }}</h3>

                                    <p>Jumlah Permintaan Sampel Pre-Rolled Cones</p>
                                </div>
                                <div class="icon">
                                    <i class="ion ion-person-add"></i>
                                </div>
                            </div>
                        </div>
                        <!-- ./col -->
                        <!-- ./col -->
                    </div>
                    <!-- /.row -->
                    <!-- Main row -->
                    <!-- /.row (main row) -->
                </div><!-- /.container-fluid -->
            </section>
            <!-- /.content -->
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>
  
<script>
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";

export default {
    name: "DashboardDs",
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
    },
    data() {
        return {
            JumlahArtikel: 0,
            JumlahProduk: 0,
            JumlahDetailProduk: 0,
            JumlahKategori: 0,
            JumlahEtiket: 0,
            JumlahCtp: 0,
            JumlahCp: 0,
            JumlahFilter: 0,
            JumlahPr: 0,
        };
    },
    created() {
        this.getJumlahData();
    },
    methods: {
        async getJumlahData() {
            try {
                const responseArtikel = await fetch('https://puracigarettesolutions.com:3000/api/dashboard/JumlahArtikel');
                const dataArtikel = await responseArtikel.json();
                this.JumlahArtikel = dataArtikel.data.total;

                const responseProduk = await fetch('https://puracigarettesolutions.com:3000/api/dashboard/JumlahProduk');
                const dataProduk = await responseProduk.json();
                this.JumlahProduk = dataProduk.data.total;

                const responseDetailProduk = await fetch('https://puracigarettesolutions.com:3000/api/dashboard/JumlahDetailProduk');
                const dataDetailProduk = await responseDetailProduk.json();
                this.JumlahDetailProduk = dataDetailProduk.data.total;

                const responseKategori = await fetch('https://puracigarettesolutions.com:3000/api/dashboard/JumlahKategori');
                const dataKategori = await responseKategori.json();
                this.JumlahKategori = dataKategori.data.total;

                const responseEtiket = await fetch('https://puracigarettesolutions.com:3000/api/dashboard/JumlahEtiket');
                const dataEtiket = await responseEtiket.json();
                this.JumlahEtiket = dataEtiket.data.total;

                const responseCtp = await fetch('https://puracigarettesolutions.com:3000/api/dashboard/JumlahCtp');
                const dataCtp = await responseCtp.json();
                this.JumlahCtp = dataCtp.data.total;

                const responseCp = await fetch('https://puracigarettesolutions.com:3000/api/dashboard/JumlahCtp');
                const dataCp = await responseCp.json();
                this.JumlahCp = dataCp.data.total;

                const responseFilter = await fetch('https://puracigarettesolutions.com:3000/api/dashboard/JumlahFilter');
                const dataFilter = await responseFilter.json();
                this.JumlahFilter = dataFilter.data.total;

                const responsePr = await fetch('https://puracigarettesolutions.com:3000/api/dashboard/JumlahPr');
                const dataPr = await responsePr.json();
                this.JumlahPr = dataPr.data.total;
            } catch (error) {
                console.error('Terjadi kesalahan:', error);
            }
        },
    },
};
</script>


  
<style></style>
  