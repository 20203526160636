<template>
    <div class="wrapper">

        <NavbarDs />
        <!-- /.navbar -->

        <!-- Main Sidebar Container -->
        <aside class="main-sidebar sidebar-dark-primary elevation-4">
            <!-- Brand Logo -->
            <a href="index3.html" class="brand-link">
                <img src="../assets/dist/img/AdminLTELogo.png" alt="AdminLTE Logo"
                    class="brand-image img-circle elevation-3" style="opacity: .8">
                <span class="brand-text font-weight-light">PURA MISC</span>
            </a>

            <SidebarDs />
            <!-- /.sidebar -->
        </aside>

        <div class="content-wrapper">
            <section class="content">
                <div class="container-fluid">
                    <div class="card card-primary card-outline">
                        <div class="card-header">
                            <div class="col-6">
                                <h5>Tulisan Baru</h5>
                            </div>
                            <div class="text-right">
                                <router-link to="/TampilanProduk" class="btn btn-primary" style="color: #ffffff;">
                                    <i class="fas fa-angle-left" style="color: #ffffff;"></i> Kembali
                                </router-link>
                            </div>
                        </div>
                        <form @submit.prevent="update" class="card-body">
                            <!-- Title Input -->
                           
                            <div class="form-group">
                                <label for="imageUpload">Image:</label>
                                <input type="file" @change="handleFileUpload" accept="image/*" />
                                <div class="image-container">
                                    <img :src="getFileUrl(post.gambar)" alt="Deskripsi Gambar" class="custom-img-style">
                                </div>
                                <img v-if="post.gambar" :src="post.gambar" height="50" width="50" alt="Image Preview" />
                            </div>

                            <!-- Submit Button -->
                            <div class="form-group">
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </div>
        <!-- /.content-wrapper -->
        <!--Footer-->
        <FooterDs />

        <!-- Control Sidebar -->
        <aside class="control-sidebar control-sidebar-dark">
            <!-- Control sidebar content goes here -->
        </aside>
        <!-- /.control-sidebar -->
    </div>
    <!-- ./wrapper -->
</template>

<script>
import FooterDs from "../components/FooterDs.vue";
import NavbarDs from "../components/NavbarDs.vue";
import SidebarDs from "../components/SidebarDs.vue";
import { reactive, ref, onMounted } from 'vue';
import axios from 'axios';
import { useRouter, useRoute } from 'vue-router'; // Removed unused useRoute import


export default {
    components: {
        FooterDs,
        NavbarDs,
        SidebarDs,
    },
    setup() {
        const post = reactive({
            id_promo: '',
            gambar: null,
        });

        const imageFile = ref(null);
        const router = useRouter();
        const route = useRoute();

        onMounted(async () => {
            if (!route.params.id_promo) {
                console.error('No Artikel ID provided in route parameters.');
                return;
            }

            try {
                const response = await axios.get(`https://puracigarettesolutions.com:3000/api/promo/TampilanPromo/${route.params.id_promo}`);
                console.log('Response from server:', response.data);

                if (response.data.success && response.data.data && typeof response.data.data === 'object') {
                    const { id_promo, gambar } = response.data.data;
                      post.id_promo = id_promo || '';
                    post.gambar = gambar || null;

                } else {
                    console.error('Invalid or missing data in server response.');
                }
            } catch (error) {
                console.error('Error fetching article data:', error);
            }
        });

        const handleFileUpload = (event) => {
            const file = event.target.files[0];
            if (file) {
                const imageUrl = URL.createObjectURL(file);
                post.gambar = imageUrl; // Mengganti this.post.previewImage menjadi post.gambar
                imageFile.value = file;
            }
        };

        const getFileUrl = (filename) => {
            const baseUrl = 'https://puracigarettesolutions.com:3000/api/promo/file';
            return `${baseUrl}/${filename}`;
        };

        const update = async () => {
            try {
                const formData = new FormData();
                if (imageFile.value) {
                    formData.append('gambar', imageFile.value);
                }

                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                };

                const response = await axios.put(`https://puracigarettesolutions.com:3000/api/promo/EditPromo/${post.id_promo}`, formData, config);

                if (response.status === 200) {
                    router.push('/TampilanPromo');
                    console.log('Promo berhasil diperbarui');
                } else {
                    throw new Error('Gagal memperbarui Promo');
                }
            } catch (error) {
                console.error('Error updating Product:', error);
                // Handle errors or validation if necessary
            }
        };

        return {
            post,
            imageFile,
            handleFileUpload,
            getFileUrl,
            update,
        };
    },
};
</script>


<style>
/* CSS */
.image-container {
    width: 150px;
    height: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-img-style {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
</style>
