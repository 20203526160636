<template>
    <div>
        <canvas ref="fireworksCanvas"></canvas>
        <div class="undangan" @click="playMusic" @mouseenter="isHovered = true" @mouseleave="isHovered = false">
            <div class="back"
                :style="{ transform: isHovered ? 'perspective(800px) rotateY(-170deg)' : 'perspective(800px) rotateY(0deg)' }">
                <img src="assets/img/business-4/ultah/logo.png" alt="Logo" class="back-logo">
            </div>
            <div class="front"
                :style="{ transform: isHovered ? 'perspective(800px) rotateY(-170deg)' : 'perspective(800px) rotateY(0deg)' }">
                <div class="imgset">
                    <img width="100%" height="100%" src="assets/img/business-4/ultah/ultah9.png" alt="Birthday">
                </div>
            </div>
            <div class="text-isi" v-for="(post, index) in postsUltah" :key="index">
                <center>
                    <p id="head">Happy Birthday!</p>
                </center>

                <br>
                <h5 class="nama-post">{{ post.nama }}</h5>
                <article v-html="post.ucapan">

                </article>
            </div>
        </div>
        <audio ref="birthdayMusic" loop>
            <source src="assets/img/business-4/ultah/hbdku.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
        </audio>
    </div>
</template>


<script>
import axios from 'axios';

export default {
    data() {
        return {
            postsUltah: [],
            isHovered: false, // Tambahkan ini
        };
    },

    mounted() {
        this.fetchData();
        this.createFireworks();
    },

    methods: {
        fetchData() {
            axios.get('https://puracigarettesolutions.com:3000/api/ultahcigarette/UlangTahun')
                .then(response => {
                    this.postsUltah = response.data.data;
                })
                .catch(error => {
                    console.log('Error fetching ultah data:', error);
                });
        },

        playMusic() {
            const audio = this.$refs.birthdayMusic;
            if (audio && audio.paused) {
                audio.play().catch(error => {
                    console.error("Playback was prevented.", error);
                });
            }
        },

        createFireworks() {
            const canvas = this.$refs.fireworksCanvas;
            const ctx = canvas.getContext('2d');
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;

            function random(min, max) {
                return Math.random() * (max - min) + min;
            }

            class Particle {
                constructor(x, y, color, velocity) {
                    this.x = x;
                    this.y = y;
                    this.color = color;
                    this.velocity = velocity;
                    this.alpha = 1;
                }

                draw() {
                    ctx.save();
                    ctx.globalAlpha = this.alpha;
                    ctx.beginPath();
                    ctx.arc(this.x, this.y, 3, 0, Math.PI * 2);
                    ctx.fillStyle = this.color;
                    ctx.fill();
                    ctx.restore();
                }

                update() {
                    this.x += this.velocity.x;
                    this.y += this.velocity.y;
                    this.alpha -= 0.01; // Decrease alpha for particle fading over time
                }
            }

            class Firework {
                constructor(x, y) {
                    this.x = x;
                    this.y = y;
                    this.particles = [];
                    this.color = `hsl(${random(0, 360)}, 100%, 70%)`; // Adjust color for vibrancy
                    this.explode();
                }

                explode() {
                    for (let i = 0; i < 30; i++) {
                        const angle = Math.random() * Math.PI * 2;
                        const velocity = {
                            x: Math.cos(angle) * random(1, 6),
                            y: Math.sin(angle) * random(1, 6)
                        };
                        this.particles.push(new Particle(this.x, this.y, this.color, velocity));
                    }
                }

                draw() {
                    this.particles.forEach(particle => particle.draw());
                }

                update() {
                    this.particles.forEach(particle => particle.update());
                    this.particles = this.particles.filter(particle => particle.alpha > 0);
                }
            }

            let fireworks = [];

            function animate() {
                requestAnimationFrame(animate);

                let gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
                gradient.addColorStop(0, '#A0A0A0'); // Darker shade of gray
                gradient.addColorStop(0.5, '#A0A0A0'); // Consistent color in the middle
                gradient.addColorStop(1, '#A0A0A0'); // Darker shade of gray at the bottom

                ctx.fillStyle = gradient;
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                if (Math.random() < 0.1) {
                    fireworks.push(new Firework(random(0, canvas.width), random(0, canvas.height / 2)));
                }

                fireworks.forEach(firework => firework.update());
                fireworks.forEach(firework => firework.draw());
                fireworks = fireworks.filter(firework => firework.particles.length !== 0);
            }

            animate();
        }
    }
}
</script>

<style>
.undangan {
    position: absolute;
    width: 300px;
    height: 425px;
    top: 50%;
    /* Posisi dari bagian atas */
    left: 50%;
    /* Posisi dari bagian kiri */
    transform: translate(-50%, -50%);
    /* Untuk menengahkan .undangan */
    border: 10px solid #FFD700;
    margin: 60px auto 0 auto;
    box-shadow: inset 10px 0px 15px 0px rgba(0, 0, 0, 0.1);
    background-image: linear-gradient(to bottom, rgba(192, 192, 192, 0.5), rgba(192, 192, 192, 0.5));
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #C0C0C0;
}

.undangan .text-isi {
    width: 80%;
    height: 80%;
    margin: auto;

}

.nama-post {
    color: #000000;
}


.strikethrough {
    text-decoration: line-through;
}

.undangan .text-isi #head {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5em;
    position: center;
    margin: 10px auto 10px auto;
}

.undangan p {
    font-size: 1.1em;
    line-height: 1.4;
    font-family: 'Nobile';
    color: #000000;
    font-style: italic;
    text-align: justify;
    margin: 5px auto 0px auto;
}

.undangan .front {
    position: absolute;
    width: 300px;
    /* Fixed width of the undangan */
    height: 425px;
    /* Fixed height of the undangan */
    margin: -10px 0px 0px -10px;
    border: 10px solid #FFD700;
    backface-visibility: hidden;
    background-color: #C0C0C0;
    transition: all 0.8s ease-in-out;
    /* background-image: url($cover-image);
 */
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
    transform: perspective(800px) rotateY(0deg);
    transition: all 0.8s ease-in-out;
}

.undangan:hover .front {
    transform: perspective(800px) rotateY(-170deg);
    background-color: #C0C0C0;
}

.undangan:hover .back {
    transform: perspective(800px) rotateY(-170deg);
    background-image: linear-gradient(to bottom, rgba(192, 192, 192, 0.5), rgba(192, 192, 192, 0.5));
    background-color: #C0C0C0;
}

.undangan .back {
    position: absolute;
    width: 300px;
    /* Fixed width of the undangan */
    height: 425px;
    /* Fixed height of the undangan */
    border: 10px solid #FFD700;
    margin: -10px 0px 0px -10px;
    backface-visibility: visible;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, .5));
    transform-style: preserve-3d;
    transform-origin: 0% 50%;
    transform: perspective(800px) rotateY(0deg);
    transition: all 0.8s ease-in-out;
    background-color: #C0C0C0;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.1);
}

.imgset {
    position: relative;
    z-index: 1;
    margin-bottom: -215px;
}

.imgset img {
    width: 100%;
    /* Full width of the undangan */
    height: 100%;
    /* Full height of the undangan */
    object-fit: cover;
    /* Cover the entire area, may crop the image */
    /* Ensures the image maintains its aspect ratio */
    object-position: center;
    /* Centers the image within the undangan */
    border-radius: 5px;
    /* Optional, for rounded corners */
}

.back {
    position: relative;
    /* Other styles for back... */
}

.back-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


#fireworksCanvas {
    position: relative;
    width: 100vw;
    /* Lebar sesuai dengan lebar viewport */
    height: 100vh;
    /* Tinggi sesuai dengan tinggi viewport */
}

@media screen and (max-width: 768px) {
    .undangan {
        background-size: contain;
    }
}
</style>