<template>
  <!-- Scroll to top button -->
  <a href="#" class="apbc-scroll-top" :class="{ 'show': showScrollTop }" @click.prevent="scrollToTop">
    <i class="fas fa-angle-double-up"></i>
  </a>

  <!-- Header component -->
  <HeaderCom />

  <!-- Hero section with optimized background -->
  <section class="saasio-breadcurmb-section-produk">
    <div class="container">
      <div class="breadcurmb-title-produk">
        <h1>{{ $t('ProdukPages.title') }}</h1>
        <p>{{ $t('ProdukPages.subtitle') }}</p>
      </div>
    </div>
  </section>

  <!-- Main product container -->
  <div class="container-produk" ref="productContainer">
    <!-- Filter section -->
    <div class="filter-section-produk">
      <h2>{{ $t('ProdukPages.title2') }}</h2>
      <hr class="gold-line">
      <p>{{ $t('ProdukPages.subtitle2') }}</p>
      
      <!-- Optimized filter buttons -->
      <div class="horizontal-group-produk" ref="filterGroupRef">
        <div 
          v-for="category in categories" 
          :key="getUniqueKey(category)"
          class="group-item"
          :class="{ active: isActiveCategory(category) }"
          @click="handleCategoryClick(category)"
        >
          {{ category.name }}
        </div>
      </div>
    </div>

    <!-- Product grid with lazy loading -->
    <div class="products-grid" ref="productsGrid">
      <div 
        v-for="post in displayedPosts" 
        :key="post.id_produk"
        class="product"
      >
        <router-link 
          :to="getProductRoute(post)"
          target="_blank"
        >
          <!-- Optimized image loading -->
          <div class="product-image-wrapper" v-lazy-load>
            <img
              :src="getPlaceholderImage()"
              :data-src="getFileUrl(post.gambar1)"
              :alt="getImageAlt(post)"
              class="product-image"
              @error="handleImageError"
              @load="handleImageLoad($event, post)"
            >
            <!-- Loading state -->
            <div class="loading-overlay" v-if="!post.imageLoaded">
              <div class="loading-spinner"></div>
            </div>
          </div>

          <!-- Product details -->
          <div class="product-details">
            <div class="product-title">
              <h3>{{ post.nama_kategori }}</h3>
              <h4>{{ getLocalizedTitle(post) }}</h4>
            </div>
            <button class="detail-button">{{ $t('ProdukPages.buttom') }}</button>
          </div>
        </router-link>
      </div>
    </div>

    <!-- Loading indicator -->
    <div v-if="loading" class="loading">Loading...</div>
  </div>

  <!-- Innovation section with optimized slider -->
  <div 
    class="innovations-section" 
    :style="getInnovationBackground()"
  >
    <h2>{{ $t('DerecButtom.title') }}</h2>
    <swiper 
      :navigation="shouldShowNavigation"
      :modules="swiperModules"
      :slides-per-view="slidesPerView"
      :space-between="spaceBetween"
      @swiper="onSwiperInitialized"
    >
      <swiper-slide 
        v-for="(post, index) in innovations" 
        :key="index"
      >
        <div 
          class="innovation-item" 
          :style="getInnovationItemStyle(post)"
        >
          <div class="innovation-content">
            <article 
              class="center-text" 
              v-html="getLocalizedContent(post)"
            ></article>
                  <router-link 
    :to="getBlogRoute(post)"
    class="read-more-link"
    target="_blank"
  >
    <button class="read-more-button">
      {{ $t('DerecButtom.buttom') }}
      <span class="arrow-icon">></span>
    </button>
  </router-link>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>

  <!-- Footer components -->
  <ScrollToFooterButton />
  <FooterCom />
</template>

<script>
import { ref, computed, onMounted, watch, nextTick, onUnmounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper/modules';
import HeaderCom from '@/components/HeaderCom.vue';
import FooterCom from '@/components/FooterCom.vue';
import ScrollToFooterButton from '@/components/ScrollToFooterButton.vue';
import { metaMixin } from '@/mixins/metaMixin';

// Image lazy loading directive
const lazyLoadDirective = {
  mounted(el) {
    function loadImage() {
      const imageElement = el.querySelector('img');
      if (imageElement) {
        imageElement.addEventListener('load', () => {
          setTimeout(() => el.classList.add('loaded'), 100);
        });
        imageElement.addEventListener('error', () => console.log('error'));
        imageElement.src = imageElement.dataset.src;
      }
    }

    function handleIntersect(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          loadImage();
          observer.unobserve(el);
        }
      });
    }

    function createObserver() {
      const options = {
        root: null,
        threshold: '0',
        rootMargin: '50px'
      };
      const observer = new IntersectionObserver(handleIntersect, options);
      observer.observe(el);
    }

    if (window['IntersectionObserver']) {
      createObserver();
    } else {
      loadImage();
    }
  }
};

export default {
  name: 'ProductComponent',
  mixins: [metaMixin],
  components: {
    HeaderCom,
    FooterCom,
    Swiper,
    SwiperSlide,
    ScrollToFooterButton,
  },
  directives: {
    'lazy-load': lazyLoadDirective
  },

  setup() {
    // Route and i18n setup
    const route = useRoute();
    const router = useRouter();
    const { locale } = useI18n();

    // Reactive references
    const categories = ref([
      { id: 0, name: 'All' },
      { id: [1, 10], name: 'CIGARETTE PAPER' },
      { id: 2, name: 'TIPPING PAPER' },
      { id: 3, name: 'CIGARETTE PACK' },
      { id: 4, name: 'INDIVIDUAL CIGARETTE PACK' },
      { id: 5, name: 'FILTER TIPS' },
      { id: 6, name: 'PRE-ROLLED CONES' },
      { id: 7, name: 'RYO/MYO' },
    ]);
    const postsProduk = ref([]);
    const displayedPosts = ref([]);
    const innovations = ref([]);
    const productContainer = ref(null);
    const productsGrid = ref(null);
    const activeCategory = ref('all');
    const activeItem = ref(0);
    const isMobile = ref(window.innerWidth < 991);
    const swiperModules = ref([Pagination]);
    const loading = ref(false);
    const page = ref(1);
    const postsPerPage = 9;
    const showScrollTop = ref(false);

    // Computed properties
    const slidesPerView = computed(() => isMobile.value ? 2 : 3);
    const spaceBetween = computed(() => isMobile.value ? 10 : 20);
    const currentLanguage = computed(() => locale.value);

    const filteredPosts = computed(() => {
      if (activeCategory.value.toLowerCase() === 'all') {
        return postsProduk.value;
      }
      
      const category = categories.value.find(
        cat => cat.name.toLowerCase() === activeCategory.value.toLowerCase()
      );
      
      if (category) {
        const categoryIds = Array.isArray(category.id) ? category.id : [category.id];
        return postsProduk.value.filter(post => categoryIds.includes(post.id_kategori));
      }
      
      return [];
    });

    // Methods
    const createSlug = (title) => {
  if (!title) return 'default-slug';
  
  // Handle jika title adalah HTML
  const textOnly = title.replace(/<[^>]*>/g, '');
  
  const slug = textOnly
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9-\s]/g, '') // Hapus karakter special
    .replace(/\s+/g, '-')         // Ganti spasi dengan dash
    .replace(/-+/g, '-')          // Hapus multiple dash
    .replace(/^-+/, '')           // Trim dash dari awal
    .replace(/-+$/, '');          // Trim dash dari akhir

  return slug || 'default-slug';
};

    const loadMorePosts = () => {
      const start = (page.value - 1) * postsPerPage;
      const end = start + postsPerPage;
      const newPosts = filteredPosts.value.slice(start, end);
      displayedPosts.value = [...displayedPosts.value, ...newPosts];
      page.value++;
    };

    const handleScroll = () => {
      // Show/hide scroll to top button
      showScrollTop.value = window.pageYOffset > 300;

      // Infinite scroll logic
      const container = productContainer.value;
      const grid = productsGrid.value;
      if (!container || !grid) return;

      const containerBottom = container.getBoundingClientRect().bottom;
      const gridBottom = grid.getBoundingClientRect().bottom;

      if (containerBottom >= gridBottom - 100 && 
          !loading.value && 
          displayedPosts.value.length < filteredPosts.value.length) {
        loading.value = true;
        setTimeout(() => {
          loadMorePosts();
          loading.value = false;
        }, 500);
      }
    };

    const handleImageLoad = (event, post) => {
      if (post) {
        post.imageLoaded = true;
      }
    };

    const handleImageError = (event) => {
      event.target.src = 'assets/img/business-4/projects/Load_produk.webp';
    };

    const getFileUrl = (filename) => {
      return `https://puracigarettesolutions.com:3000/api/produk/file/${filename}`;
    };

    const getFileUrli = (filename) => {
      return `https://puracigarettesolutions.com:3000/api/artikel/file/${encodeURIComponent(filename)}`;
    };

    const getData = async () => {
      try {
        loading.value = true;
        const response = await axios.get('https://puracigarettesolutions.com:3000/api/produk/TampilanProduk');
        postsProduk.value = response.data.data;
        displayedPosts.value = [];
        page.value = 1;
        await loadMorePosts();
      } catch (err) {
        console.error('Error fetching product data:', err);
      } finally {
        loading.value = false;
      }
    };

    const toggleActive = async (categoryId) => {
      let category;
      if (categoryId === 0) {
        category = categories.value.find(cat => cat.id === 0);
      } else {
        category = categories.value.find(cat => {
          if (Array.isArray(cat.id)) {
            return cat.id.includes(categoryId);
          }
          return cat.id === categoryId;
        });
      }

      if (category) {
        activeItem.value = Array.isArray(category.id) ? category.id[0] : category.id;
        activeCategory.value = category.name;
        
        const newQuery = {
          filter: category.id === 0 ? 'all' : category.name
        };

        if (route.query.scrollToFilter) {
          newQuery.scrollToFilter = route.query.scrollToFilter;
        }

        await router.push({ query: newQuery });
        displayedPosts.value = [];
        page.value = 1;
        await loadMorePosts();

        if (route.query.scrollToFilter === 'true') {
          await nextTick();
          scrollToFilter();
        }
      }
    };

    const scrollToFilter = () => {
      const filterSection = document.querySelector('.horizontal-group-produk');
      if (filterSection) {
        const headerHeight = document.querySelector('header')?.offsetHeight || 0;
        const elementPosition = filterSection.getBoundingClientRect().top + window.pageYOffset;
        
        window.scrollTo({
          top: elementPosition - headerHeight - 20,
          behavior: 'smooth'
        });

        router.replace({ 
          query: { 
            filter: route.query.filter 
          } 
        });
      }
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    };

    const getInnovations = async () => {
      try {
        const response = await axios.get('https://puracigarettesolutions.com:3000/api/artikel/TampilanArtikel');
        innovations.value = response.data.data;
        if (innovations.value.length > 3) {
          swiperModules.value.push(Navigation);
        }
      } catch (err) {
        console.error('Failed to fetch innovations:', err);
      }
    };

    const updateViewport = () => {
      isMobile.value = window.innerWidth < 768;
    };

    // Watchers
    watch([locale, activeCategory], () => {
      getData();
    });

    watch(() => route.query, async (newQuery) => {
      if (newQuery.filter) {
        const categoryId = newQuery.categoryId ? 
          parseInt(newQuery.categoryId) : 
          (newQuery.filter.toLowerCase() === 'all' ? 0 : null);

        if (categoryId !== null) {
          await toggleActive(categoryId);
        } else {
          const category = categories.value.find(
            cat => cat.name.toLowerCase() === newQuery.filter.toLowerCase()
          );
          if (category) {
            await toggleActive(Array.isArray(category.id) ? category.id[0] : category.id);
          } else {
            await toggleActive(0);
          }
        }
      } else {
        await toggleActive(0);
      }
    }, { immediate: true });

      // Lifecycle hooks continued...
      onMounted(async () => {

        window.addEventListener('resize', updateViewport);
        window.addEventListener('scroll', handleScroll);
        // Fetch data
        await Promise.all([getData(), getInnovations()]);

        // Initialize header image lazy loading
        initializeHeaderImage();

        // Handle initial category filtering
        handleInitialFilter();

        // Add meta tags for SEO
        updateMetaTags();
      });

      const initializeHeaderImage = () => {
        const section = document.querySelector('.saasio-breadcurmb-section-produk');
        if (!section) return;

        const img = new Image();
        
        // Set initial placeholder
        img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
        img.dataset.src = 'assets/img/business-4/slider/PRODUCT.webp';
        img.alt = 'Pura MICS Product';
        img.classList.add('lazy', 'product-image');

        // Optimize image styles
        Object.assign(img.style, {
          width: '100%',
          height: '250px',
          objectFit: 'cover',
          opacity: '0',
          transition: 'opacity 0.3s'
        });

        // Create and configure image wrapper
        const wrapper = document.createElement('div');
        wrapper.className = 'product-image-wrapper';
        wrapper.appendChild(img);
        section.appendChild(wrapper);

        // Implement lazy loading with Intersection Observer
        if ('IntersectionObserver' in window) {
          const lazyImageObserver = new IntersectionObserver(
            (entries) => {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  loadHeaderImage(img);
                  lazyImageObserver.unobserve(entry.target);
                }
              });
            },
            { rootMargin: '50px' }
          );

          lazyImageObserver.observe(img);
        } else {
          // Fallback for browsers without Intersection Observer
          loadHeaderImage(img);
        }

        // Preload header image
        const link = document.createElement('link');
        link.rel = 'preload';
        link.as = 'image';
        link.href = img.dataset.src;
        document.head.appendChild(link);
      };

      const loadHeaderImage = (img) => {
        const actualSrc = img.dataset.src;
        img.onload = () => {
          img.style.opacity = '1';
          img.closest('.saasio-breadcurmb-section-produk')?.classList.add('loaded');
        };
        img.src = actualSrc;
      };

      const handleInitialFilter = async () => {
        const filterQuery = route.query.filter;
        if (filterQuery) {
          const category = categories.value.find(
            cat => cat.name.toLowerCase() === filterQuery.toLowerCase()
          );
          if (category) {
            await toggleActive(Array.isArray(category.id) ? category.id[0] : category.id);
          } else {
            await toggleActive(0);
          }
        } else {
          await toggleActive(0);
        }
      };

      const updateMetaTags = () => {
        document.title = 'Products - Pura Cigarette Solutions';
        const metaDescription = document.querySelector('meta[name="description"]') || 
          document.createElement('meta');
        metaDescription.setAttribute('name', 'description');
        metaDescription.setAttribute('content', 'Discover our comprehensive range of cigarette solutions products.');
        if (!document.querySelector('meta[name="description"]')) {
          document.head.appendChild(metaDescription);
        }
      };

      // Cleanup
      onUnmounted(() => {
        window.removeEventListener('resize', updateViewport);
        window.removeEventListener('scroll', handleScroll);
      });

      // Return all necessary methods and properties
      return {
        // State
        filteredPosts,
        displayedPosts,
        categories,
        activeItem,
        postsProduk,
        currentLanguage,
        innovations,
        swiperModules,
        slidesPerView,
        isMobile,
        spaceBetween,
        productContainer,
        productsGrid,
        loading,
        showScrollTop,

        // Methods
        getFileUrl,
        getFileUrli,
        toggleActive,
        handleImageError,
        handleImageLoad,
        createSlug,
        scrollToTop,

        // Computed helpers
        getProductRoute: (post) => ({
          name: 'DetailProduk',
          params: {
            slug: createSlug(currentLanguage.value === 'en' ? post.judul_en : post.judul_id),
            id_produk: post.id_produk
          }
        }),

              getBlogRoute: (post) => {
  // Early return if post is null/undefined
  if (!post || !post.judul_en || !post.judul_id) {
    return {
      name: 'DetailBlog',
      params: { 
        slug: 'default-slug'
      }
    };
  }

  // Get the title based on current language
  const title = currentLanguage.value === 'en' ? post.judul_en : post.judul_id;

  // Only proceed with slug creation if we have a title
  if (!title) {
    return {
      name: 'DetailBlog',
      params: { 
        slug: 'default-slug'
      }
    };
  }

  try {
    // Clean and create slug
    let cleanTitle = title
      .replace(/<[^>]*>/g, '')     // Remove HTML tags
      .replace(/&nbsp;/g, ' ')     // Replace &nbsp;
      .replace(/&amp;/g, '&')      // Replace &amp;
      .trim();
    
    // Get first part before any separator
    cleanTitle = cleanTitle.split(/[:–—\n]/)[0].trim();
    
    // Take first few words
    const words = cleanTitle.split(/\s+/).slice(0, 5);
    cleanTitle = words.join(' ');
    
    // Generate consistent slug
    const slug = cleanTitle
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, '') // Remove special chars
      .replace(/\s+/g, '-')         // Replace spaces with hyphens
      .replace(/-+/g, '-')          // Remove multiple hyphens
      .replace(/^-+|-+$/g, '');     // Trim hyphens

    return {
      name: 'DetailBlog',
      params: { 
        slug: slug || 'default-slug'
      }
    };
  } catch (error) {
    console.error('Error generating blog route:', error);
    return {
      name: 'DetailBlog',
      params: { 
        slug: 'default-slug'
      }
    };
  }
},

        getLocalizedTitle: (post) => 
          currentLanguage.value === 'en' ? post.judul_en : post.judul_id,

        getLocalizedContent: (post) => 
          currentLanguage.value === 'en' ? post.judul_en : post.judul_id,

        getImageAlt: (post) => 
          `Image of ${currentLanguage.value === 'en' ? post.judul_en : post.judul_id}`,

        getInnovationBackground: () => ({
          backgroundImage: `url('assets/img/business-4/back/produk1.webp')`
        }),

        getInnovationItemStyle: (post) => ({
          backgroundImage: `url(${getFileUrli(post.gambar1)})`
        }),

        getUniqueKey: (category) => 
          Array.isArray(category.id) ? category.id.join('-') : category.id,

        isActiveCategory: (category) => 
          activeItem.value === (Array.isArray(category.id) ? category.id[0] : category.id) || 
          (category.id === 0 && activeCategory.value === 'all'),

        getPlaceholderImage: () => 
          'assets/img/business-4/projects/Load_produk.webp'
      };
    }
};
</script>


<style>
@import url('https://fonts.googleapis.com/css?family=Oswald:700&display=swap');


.group-item {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 10px 25px;
  margin: 5px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  font-size: 10px;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 20px;
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.group-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.group-item.active {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

.filter-section-produk {
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10; /* Higher z-index to ensure it's on top */
  position: relative;
  align-items: center;
}

.filter-section-produk h2 {
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  margin-top: 20px;
  font-size: 30px;
}

.filter-section-produk p {
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
  text-align: center;
  margin: 0 100px;
  margin-bottom: 50px;
}

#saasio-produk {
  background-color: black;
  padding: 20px 0;
}

.carousel-item-content {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 10px;
  min-height: 500px;
}

.carousel-image-container {
  flex: 0 0 auto;
  max-width: 500px;
  margin-left: 300px;
  max-height: 300px;
  overflow: hidden;
}

.carousel-image-custom {
  width: 200px;
  height: 300px;
}

.carousel-caption-produk {
  flex: 1;
  padding-left: 20px;
  color: white;
  text-align: left;
}

.text-warning {
  color: #FFC107;
}

.text-white {
  color: #FFFFFF;
}

.container-produk {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #ffffff;
}



.products-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns for desktop */
  gap: 20px; /* Add spacing between the items */
}

.product {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  background-color: #000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  height: 450px; /* Ensure all products have the same height */
  margin-bottom: 20px;
}

.product-image-wrapper {
  width: 100%;
  height: 270px; /* Set a fixed height for all images */
  overflow: hidden;
}

.product img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.product:hover img {
  transform: scale(1.05);
}

.product-details {
  padding: 10px;
  background-color: #000;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.product-title {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}


.product-details h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  margin: 5px 0;
  flex-grow: 1; /* Allow title to grow and push button down */
}

.product-details h3 {
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  color: #C3C3C3;
  font-size: 16px;
  margin: 10px 0;
}

.detail-button {
  background-color: #ffc107;
  border: none;
  padding: 8px 15px;
  color: #000;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px;
  transition: background-color 0.3s;
  margin-top: 30px; /* Add some space above the button */
  align-self: center;
  width: fit-content; /* Adjust button width to content */
}
.detail-button:hover {
  background-color: #ffffff;
  color: #000;
}

.gold-line {
  width: 100px;
  height: 4px;
  background-color: gold;
  margin: 10px auto;
  border-radius: 2px;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination-controls button {
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 20px;
  margin: 0 10px;
  cursor: pointer;
  transition: color 0.3s;
}

.pagination-controls button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.pagination-controls span {
  font-family: 'Oswald', sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #000;
  margin: 0 5px;
  cursor: pointer;
}

.pagination-controls span.active {
  color: #ffc107;
}

.pagination-controls span:hover {
  color: #ffc107;
}

.innovations-section {
  text-align: center;
  padding: 20px 0;
  background-color: #000;
  color: #fff;
}

.innovations-section h2 {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
}


.innovations-section h3 {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 16px;
  color: #fff;
}

.innovations-carousel {
  display: flex;
  justify-content: center;
  gap: 20px;
  overflow: hidden;
}

.swiper-container {
  width: 100%;
}


.innovation-item {
  background-color: #222;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  height: 450px;
  display: flex;
  padding: 0 10px;
  margin-left: 50px;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  position: relative;
  background-size: cover;
  background-position: center center; /* Mengatur posisi gambar tepat di tengah secara vertikal dan horizontal */
  background-repeat: no-repeat;
  transition: transform 0.3s, box-shadow 0.3s;
}



.innovation-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.7) 100%);
  border-radius: 8px;
}

.innovation-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.innovation-content {
  padding: 20px;
  position: relative;
  z-index: 2; /* Ensure it is above the gradient overlay */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  height: 100%; /* Full height to align items inside vertically */
}

.innovation-content h4 {
  margin-top: auto; /* Pushes the title to the bottom */
  font-size: 18px;
  color: #fff;
}

.read-more-button {
 margin-bottom: 25px;
  padding: 5px 30px;
  background-color: #FFCA2E;
  color: #000;
  font-weight: bold;
  border-radius: 20px;
  border: none;
  cursor: pointer;
}


.read-more-button:hover {
  background-color: #fff;
  color: #000;
}

.swiper-button-next, .swiper-button-prev {
  background-color: #000; /* Solid color for visibility */
  opacity: 0.8; /* Slightly transparent */
  border-radius: 50%;
  width: 30px; /* Smaller circle for subtlety */
  height: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-50%);
}

.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 16px; /* Appropriate icon size */
}

.swiper-button-next {
  right: 10px; /* Proper alignment */
}

.swiper-button-prev {
  left: 10px;
}

@media (max-width: 991px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none !important;
  }

}


@media (max-width: 768px) { /* Tablet */
  .innovations-section h3 {
    margin-left: 0px;
    font-size: 8px;
  }

  .innovation-item {
    width: 85%; /* Adjust width to use more space */
    margin-left: 20px; /* Decrease margin */
    height: 350px; /* Adjust height */
  }

  .innovation-content {
    padding: 0px;
  }

  .innovation-content h4 {
    font-size: 8px;
  }

  .read-more-button {
    margin-bottom: 25px;
  padding: 5px 30px;
  background-color: #FFCA2E;
  color: #000;
  font-weight: bold;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  }
}

@media (max-width: 765px) and (min-width: 700px) { /* Mobile */
  .filter-section-produk p {
    font-size: 11px;
        text-align: center;
        margin: 0px 25px;
        margin-bottom: 20px;
}


  .innovations-section h3 {
    margin-left: 0px;
    font-size: 8px;
  }

  .innovations-carousel {
    flex-direction: column;
    gap: 10px;
  }

  .innovation-item {
    width: 90%; /* Adjust width to use more space */
    margin-left: auto;
    margin-right: auto; /* Centering item */
    height: 300px;
  }

  .innovation-content h4 {
    font-size: 8px;
  }

  .read-more-button {
    margin-bottom: 15px;
  padding: 0px 10px;
  font-size: 6px;
  }
}

@media (max-width: 695px) and (min-width: 481px) { /* Mobile */
  .filter-section-produk p {
    font-size: 11px;
        text-align: center;
        margin: 0px 25px;
        margin-bottom: 20px;
}


  .innovations-section h3 {
    margin-left: 0px;
    font-size: 8px;
  }

  .innovations-carousel {
    flex-direction: column;
    gap: 10px;
  }

  .innovation-item {
    width: 90%; /* Adjust width to use more space */
    margin-left: auto;
    margin-right: auto; /* Centering item */
    height: 270px; /* Adjust height */
  }

  .innovation-content h4 {
    font-size: 8px;
  }

  .read-more-button {
    margin-bottom: 15px;
  padding: 0px 10px;
  font-size: 6px;
  }
}

@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns on smaller screens */
  }

  .group-item {
    /* Ensure pointer events are allowed */
    pointer-events: auto;
  }

}

@media (max-width: 480px) {
  .products-grid {
    width: 100%;
    height: 700px;
  }

  .container-produk {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
}

.product-details {
    padding: 0px;
    background-color: #000;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.product {
    position: relative;
    cursor: pointer;
    overflow: hidden;
    background-color: #000;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    height: 225px;
    margin-bottom: 20px;
}

.product-image-wrapper {
    width: 100%;
    height: 100px;
    overflow: hidden;
}

.pagination-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 75px;
}

.product img {
        width: 180px;
        height: 100px;
        -o-object-fit: cover;
        object-fit: cover;
        transition: transform .3s;
    }
.product-details h3 {
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    color: #C3C3C3;
    font-size: 12px;
    margin: 10px 0;
}

.product-details h4 {
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    color: #fff;
    font-size: 11px;
    margin: 10px 0;
}

.detail-button {
    background-color: #ffc107;
    border: none;
    padding: 5px 10px;
    color: #000;
    font-weight: bold;
    cursor: pointer;
    border-radius: 15px;
    margin-top: 4px;
    margin-bottom: 0px;
    transition: background-color 0.3s;
}

.innovation-item {
    width: 85%; /* Adjust width to use more space */
    margin-left: 20px;
    height: 200px;
  }

  .innovations-section h3 {
        margin-left: 0;
        font-size: 7px;
    }

    .container-produk {
      margin-bottom: 3300px;
    }

    .innovation-content h4 {
        font-size: 7px;
    }

    .read-more-button {
        margin-bottom: 25px;
        padding: 1px 10px;
        background-color: #ffca2e;
        color: #000;
        font-weight: 700;
        border-radius: 20px;
        border: none;
        cursor: pointer;
    }

}



/* Mobile Phones */
@media (max-width: 765px) {
 
  .carousel-caption-produk, .filter-section-produk h2, .product-details h4 {
    text-align: center; /* Center text for smaller devices */
  }

  .carousel-image-container {
    margin-left: 0; /* Remove extra margin */
  }
}

.horizontal-group-produk {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow-x: auto;
  width: 100%;
  margin-bottom: 25px;
}

.group-item {
  white-space: nowrap;
  padding: 8px 15px;
  margin: 0 2px;
  font-size: 12px;
  box-sizing: border-box;
}

@media (max-width: 480px) {
  .group-item {
    padding: 5px 10px;
        font-size: 9px;
        white-space: break-spaces;
        margin: 2px 0px;
  }
.horizontal-group-produk {
        display: flex;
        margin-bottom: 24px;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
    }

  .filter-section-produk p {
    font-size: 11px;
        text-align: center;
        margin: 0px 5px;
        margin-bottom: 20px;
}


}

@media (max-width: 991px) {
  .group-item {
    padding: 5px 8px;
        font-size: 9px;
        white-space: break-spaces;
        margin: 2px 5px;
  }


}

@media (max-width: 780px) and (min-width: 700px) {
  .group-item {
    padding: 5px 10px;
        font-size: 9px;
        white-space: break-spaces;
        margin: 2px 5px;
  }
  .horizontal-group-produk {
    justify-content: flex-start;
  }

  .horizontal-group-produk {
    display: flex;
    margin-bottom: 24px;
    flex-wrap: wrap;
    flex-direction: row;
}


}

@media (max-width: 695px) and (min-width: 481px) {
  .group-item {
    padding: 5px 10px;
        font-size: 9px;
        white-space: break-spaces;
        margin: 2px 5px;
  }
  .horizontal-group-produk {
    justify-content: space-between;
  }

  .horizontal-group-produk {
    display: flex;
    margin-bottom: 24px;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
}


}

@media (max-width: 480px) {
  .container-produk {
    width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }

  .innovations-section, .apbc-footer-section {
    position: relative;
    width: 100%;
    left: 0;
    right: 0;
  }

  .footer-content {
    flex-direction: column;
  }

  .footer-column {
    width: 100%;
    margin-bottom: 20px
  }
}


.loading {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: #666;
}
    
</style>
